import React, {useEffect, useState} from 'react';
import { RankingFilters } from '../../components/RankingFilters';
import { RankingTable } from '../../components/RankingTable';
import apiClient from "../../apiClient";
import gradient_light from "../../img/gradient_light.jpg";
import {useTranslation} from "react-i18next";

const RankingList = () => {
  const { t, i18n } = useTranslation();
  const [rankings, setRankings] = useState([]);

  useEffect(() => {
    (async () => {
      // TODO: replace itemID to address
      const { data: rankingListData } = await apiClient.get(
          `social/rankingList`
      );
      console.log(`rankingListData = ${JSON.stringify(rankingListData)}`);
      const {
        rankings
      } = rankingListData;
      setRankings(rankings);
    })();
  }, []);

  return (
    <main>
      <section className='relative py-24'>
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src={gradient_light} alt="gradient" className="h-full w-full" />
        </picture>
        <div className='container'>
          <h1 className='py-16 text-center font-display text-4xl font-medium text-jacarta-700 dark:text-white'>
            {t('ranking.rankings')}
          </h1>
          {/*<RankingFilters />*/}
          <div className='scrollbar-custom overflow-x-auto'>
            <RankingTable
                rankings={rankings}/>
          </div>
        </div>
      </section>
    </main>
  );
};

export { RankingList };
