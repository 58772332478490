import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import apiClient from "../apiClient";


const ExpandableItem = ({title, description, children}) => {
    const [active, setActive] = useState(false);

    return (
        <div
            className="accordion-item dark:border-jacarta-600 border-jacarta-100 mb-5 overflow-hidden rounded-lg border"
            onClick={() => {
                setActive(prevState => !prevState);
            }}
        >
            <h2 className="accordion-header" id="faq-heading-1">
                <button
                    className="accordion-button dark:bg-jacarta-700 font-display text-jacarta-700 collapsed relative flex w-full items-center justify-between bg-white px-4 py-3 text-left dark:text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-1"
                    aria-expanded="false"
                    aria-controls="faq-1"
                >
                    <span>{title}</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="accordion-arrow fill-jacarta-700 h-4 w-4 shrink-0 transition-transform dark:fill-white"
                    >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                            d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                    </svg>
                </button>
            </h2>
            <div
                id="faq-1"
                className={`accordion-collapse ${!active ? 'collapse' : '' }`}
                aria-labelledby="faq-heading-1"
                data-bs-parent="#accordionFAQ">
                <div
                    className="accordion-body dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 border-t bg-white p-4"
                >
                    <p className="dark:text-jacarta-200">
                        <div dangerouslySetInnerHTML={{ __html: children }} />
                    </p>
                </div>
            </div>
        </div>
    )
}


export { ExpandableItem };
