import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import KLAYTN from '../../img/chains/KLAYTN.png';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const CopyLink = ({walletAddress}) => {
  return (
    <Tippy content='Copy'>
      <div
        className='mb-8 inline-flex items-center justify-center rounded-full border border-jacarta-100 bg-white py-1.5 px-4
		dark:border-jacarta-600 dark:bg-jacarta-700'>
        <img src={KLAYTN} width="15" height="15" className='mx-1'/>
        {/*<button
          onClick={() => {
            // writeText(your_state)
            navigator.clipboard.writeText(walletAddress);
          }}
          className='js-copy-clipboard max-w-[10rem] select-none overflow-hidden text-ellipsis whitespace-nowrap dark:text-jacarta-200'
          data-tippy-content='Copy'>
          <span>{walletAddress ?walletAddress : ''}</span>
        </button>*/}
          <CopyToClipboard
              text={walletAddress && walletAddress}
              onCopy={() => alert(`Copied!`)}>
              <span className="js-copy-clipboard max-w-[10rem] select-none overflow-hidden text-ellipsis whitespace-nowrap dark:text-jacarta-200">{walletAddress && walletAddress}</span>
          </CopyToClipboard>
      </div>
    </Tippy>
  );
};

export { CopyLink };


/*
                          <CopyToClipboard
                              text={userdata && userdata.referralCode}
                              onCopy={() => alert(`Copied!`)}>
                              <span className="font-bold text-red text-2xs ml-2">Copy</span>
                          </CopyToClipboard>
 */
