import React from 'react';
import { Link } from 'react-router-dom';
import CORN from '../../../img/chains/CORN.png';

const TableElement = ({
  url,
  alt,
  order,
  title,
  volume,
  day,
  week,
  floorPrice,
  owners,
  items,
  verified,
}) => {
  return (
    <a className='flex transition-shadow hover:shadow-lg' role='row'>
        <div
            className='flex w-[16.6%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
            role='cell'>
            <span className='mr-2 lg:mr-4'>{order}</span>
        </div>

        <div
            className='flex w-[16.6%] items-center whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
            role='cell'>
            <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                {title}
            </span>
        </div>

      <div
        className='flex w-[16.6%] items-center whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
        role='cell'>
        <span className="-ml-1 mr-1">
            <img
                src={CORN}
                width="15"
                height="15"
            />
        </span>
        <span className='text-sm font-medium tracking-tight'>{Number(volume).toLocaleString('en')}</span>
      </div>
      {/*<div
        className='flex w-[12%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
        role='cell'>
        {day >= 0 && <span className='text-green'>{day}%</span>}
        {day < 0 && <span className='text-red'>{day}%</span>}
      </div>
      <div
        className='flex w-[12%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
        role='cell'>
        {week >= 0 && <span className='text-green'>{week}%</span>}
        {week < 0 && <span className='text-red'>{week}%</span>}
      </div>*/}
      <div
        className='flex w-[16.6%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
        role='cell'>
        <span className="-ml-1 mr-1">
            <img
                src={CORN}
                width="15"
                height="15"
            />
        </span>
        <span className='text-sm font-medium tracking-tight'>{Number(floorPrice).toLocaleString('en')}</span>
      </div>
      <div
        className='flex w-[16.6%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
        role='cell'>
        <span>{owners}</span>
      </div>
      <div
        className='flex w-[16.6%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
        role='cell'>
        <span>{items}</span>
      </div>
    </a>
  );
};

export { TableElement };
