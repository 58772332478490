import React from 'react';
import { Link } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useTranslation} from "react-i18next";

const Share = ({linkUrl}) => {
    const { t, i18n } = useTranslation();
    const openInNewTab = url => {
        // alert(url);
        window.open(url, '_blank', 'noopener,noreferrer');
    };

  return (
    <div
      className='dropdown-menu dropdown-menu-end show z-10 hidden min-w-[200px] whitespace-nowrap rounded-xl
			bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800'
      aria-labelledby='collectionShare'
      style={{
        position: 'absolute',
        inset: '0px auto auto 0px',
        margin: '0px',
        top: '-100px',
        left: '-60px',
        transform: 'translateY(100%)',
      }}>
      <a
        onClick={() => {
            // const params = `?&imgname=${}&msg=${}&title=${}`
            openInNewTab(`https://www.facebook.com/sharer/sharer.php?u=${linkUrl}`)
        }}
        className='flex w-full items-center rounded-xl px-5 py-2 text-left font-display text-sm transition-colors
			hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'>
        <svg
          aria-hidden='true'
          focusable='false'
          data-prefix='fab'
          data-icon='facebook'
          className='mr-2 h-4 w-4 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white'
          role='img'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 512 512'>
          <path
            d='M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15
				37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11
				71.69h-57.78V501C413.31 482.38 504 379.78 504 256z'></path>
        </svg>
        <span className='mt-1 inline-block'>{t('common.facebook')}</span>
      </a>
      {/*<Link
        to='#'
        className='flex w-full items-center rounded-xl px-5 py-2 text-left font-display text-sm transition-colors
			hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'>
        <svg
          aria-hidden='true'
          focusable='false'
          data-prefix='fab'
          data-icon='twitter'
          className='mr-2 h-4 w-4 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white'
          role='img'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 512 512'>
          <path
            d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452
				0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568
				130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0
				18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431
				13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3
				105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934
				30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132
				57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'></path>
        </svg>
        <span className='mt-1 inline-block'>Twitter</span>
      </Link>*/}
      <a
        className='flex w-full items-center rounded-xl px-5 py-2 text-left font-display text-sm transition-colors
			hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          width='24'
          height='24'
          className='mr-2 h-4 w-4 fill-jacarta-300 group-hover:fill-accent dark:group-hover:fill-white'>
          <path fill='none' d='M0 0h24v24H0z' />
          <path
            d='M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7
				7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414
				1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z'
          />
        </svg>
          <CopyToClipboard
              text={linkUrl}
              onCopy={() => alert(`Copied!`)}>
              <span className='mt-1 inline-block'>{t('common.copy')}</span>
          </CopyToClipboard>
      </a>
    </div>
  );
};

export { Share };

