import React, { useContext, useEffect, useState } from "react";
import { Search } from "../Search";
import { Logo } from "../Logo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "../Modal";
import { HeaderModal } from "../HeaderModal";
import { MobileMenu } from "../MobileMenu";
import Tippy from "@tippyjs/react";
import { ethers } from "ethers";
//import Web3 from 'web3';
// import classNames from 'classnames';
// import styles from './Header.module.css';

import "tippy.js/dist/tippy.css";
import { useMetaMask } from "metamask-react";
import { useTranslation, Trans } from "react-i18next";
import create from "zustand";

import {
  COLLECTION_CATEGORIES,
  getCategoriesAllIcon,
  getCategoriesIcon,
  LiveChainNetworkParams,
  TestChainNetworkParams,
} from "../../Constant";
import useAuthNonceQuery from "../../hooks/query/useAuthNonceQuery";
import { AddressLink } from "./AddressLink";
import BalanceBox from "./BalanceBox";
import PointBox from "./PointBox";
import { AuthContext } from "../../AuthContext";
import PersistentAppState from "../../PersistentAppState";
import { constants } from "../../blockchain/constants";
import { saleAbi } from "../../blockchain/saleAbi";
import { currencyById } from "../../blockchain/currencies";
import { erc20Abi } from "../../blockchain/erc20Abi";
import { getCornBalance } from "../../blockchain/blockchainUtils";
import { useUserStore } from "../../useUserStore";

//const useUserStore = create(PersistentAppState);
const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const Header = ({
  isLogin,
  setIsLogin,
  setModalIsOpen,
  userdata,
  openMetamask,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log('userdata', userdata);
  const balance = useUserStore((state) => state.balance);
  const setBalance = useUserStore((state) => state.setBalance);
  const reset = useUserStore((state) => state.reset);

  const lngs = {
    en: { nativeName: t("profile_menu.english") },
    kr: { nativeName: t("profile_menu.korean") },
    
  };

  const isIndex = () => {
    return location.pathname === "/index" || location.pathname === "/";
  };

  //  const [web3, setWeb3] = useState();
  useEffect(() => {
    // alert(auth.token);
    // if (typeof window.ethereum !== "undefined") {
    //   // window.ethereum이 있다면
    //   try {
    //     const web = new Web3(window.ethereum); // 새로운 web3 객체를 만든다
    //     setWeb3(web);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }

    (async () => {
      let pcDevice = "win16|win32|win64|mac|macintel";
      // if (navigator.platform) {
      //   if (pcDevice.indexOf(navigator.platform.toLowerCase()) < 0) {
      // checking target network
      const params =
        REACT_APP_ENVIRONMENT === "prod"
          ? LiveChainNetworkParams
          : TestChainNetworkParams;
      const targetChainId = params.chainId; //(REACT_APP_ENVIRONMENT === 'prod') ? LiveChainNetworkParams.chainId : TestChainNetworkParams.chainId;
      console.log(`targetChainId = ${targetChainId}`);
      if (chainId !== targetChainId) {
        try {
          await switchChain(targetChainId);
        } catch (e) {
          console.error(`switchChain error = ${e.code}`);
          if (e.code === 4902 || e.code === -32603) {
            try {
              await addChain(params);
            } catch (addError) {
              console.error(`addChain error = ${JSON.stringify(addError)}`);
            }
          }
        }
      }
      //   }
      // }
      const balance = await getCornBalance(ethereum);
      setBalance(balance);

      // setCornBalance(balance);
      // setStoreCornBalance(balance);
      await updateWallets();
    })();
  }, [isLogin]);

  const [address, setAddress] = useState("");
  const [ethBalance, setEthBalance] = useState("");
  const [cornBalance, setCornBalance] = useState("0");

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const { status, connect, account, chainId, ethereum, switchChain, addChain } =
    useMetaMask();

  const updateWallets = async () => {
    const ethereum = window["ethereum"];

    if (ethereum) {
      const addresses = await ethereum.request({
        method: "eth_requestAccounts",
      });
      if (addresses) {
        const address = addresses[0];
        setAddress(address);
        const balance = await ethereum.request({
          method: "eth_getBalance",
          params: [address, "latest"],
        });
        const formatBalance = ethers.utils.formatEther(balance);
        console.log(formatBalance);
        setEthBalance(formatBalance);
      }
    }
    // if (typeof window.ethereum === "undefined") {
    //   return;
    // }
    // const web3 = new Web3(window.ethereum);
    // if (web3) {
    //   const accounts = await web3.eth.getAccounts();
    //   console.log(`accounts = ${JSON.stringify(accounts)}`);
    //   const address = accounts[0];
    //   setAddress(address);
    // }
    // // 139749392050000000000
    // const balance = await web3.eth.getBalance(address);
    // const formatBalance = ethers.utils.formatEther(balance);
    // console.log(formatBalance);
    // setEthBalance(formatBalance);
  };

  /*  const getCornBalance = async () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const signerAddress = await signer.getAddress();
    const currencyAddress = currencyById(2);
    const erc20Contract = new ethers.Contract(
        currencyAddress,
        erc20Abi,
        signer
    );
    const erc20Balance = await erc20Contract.balanceOf(signerAddress);
    const balanceBn = ethers.BigNumber.from(erc20Balance);
    console.log('getCornBalance', ethers.utils.formatUnits(balanceBn, 18));
    return Number(ethers.utils.formatUnits(balanceBn, 18)).toLocaleString('en');
  }*/

  const isSignedIn = () => {
    return isLogin; // status === 'connected'
  };

  const signOut = () => {
    // alert('')
    localStorage.setItem(`accessToken`, "");
    setIsLogin(false);
    reset();
    alert(`로그아웃`);
    navigate("/", { replace: true });
  };

  return (
    <>
      {mobileMenuIsOpen && (
        <MobileMenu
          isSignedIn={isSignedIn}
          setIsLogin={setIsLogin}
          setMobileMenuIsOpen={setMobileMenuIsOpen}
          setModalIsOpen={setModalIsOpen}
          openMetamask={openMetamask}
          userdata={userdata}
          balance={balance}
        />
      )}
      <header className="js-page-header fixed top-0 z-20 w-full transition-colors">
        <div className="flex items-center px-6 py-6 xl:px-24">
          <Logo />
          {/*<small>{status}</small>*/}
          <Search isIndex={isIndex} />
          <div
            className="js-mobile-menu invisible fixed inset-0 z-10 ml-auto items-center
				bg-white opacity-0 dark:bg-jacarta-800 lg:visible lg:relative lg:inset-auto
					lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent"
          >
            {/* <Navigation /> */}

            <nav className="navbar w-full">
              <ul className="flex flex-col lg:flex-row">
                <li className="group">
                  <div className="js-nav-dropdown group-dropdown relative">
                    <Link
                      to="/collections"
                      className={
                        isIndex()
                          ? "flex items-center justify-between py-3.5 font-display text-base text-main-bg-color lg:px-5"
                          : "flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent lg:px-5"
                      }
                    >
                      {t("header.explore")}
                    </Link>
                    <div
                      className="dropdown-menu left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2"
                      aria-labelledby="profileDropdown"
                    >
                      {COLLECTION_CATEGORIES.map((item, i) => {
                        return (
                          <Link
                            key={i}
                            to={`/collections?category=${item}`}
                            className="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                          >
                            <span className="mr-3 rounded-xl bg-light-base p-[0.375rem]">
                              {i === 0
                                ? getCategoriesAllIcon()
                                : getCategoriesIcon(i, true)}
                            </span>
                            {t(`category.${item}`)}
                          </Link>
                        );
                      })}

                      {/*<Link
                        to="/collections"
                        className="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span className="mr-3 rounded-xl bg-light-base p-[0.375rem]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="h-4 w-4 fill-jacarta-700"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M22 12.999V20a1 1 0 0 1-1 1h-8v-8.001h9zm-11 0V21H3a1 1 0 0 1-1-1v-7.001h9zM11 3v7.999H2V4a1 1 0 0 1 1-1h8zm10 0a1 1 0 0 1 1 1v6.999h-9V3h8z"></path>
                          </svg>
                        </span>
                        <span className="mt-0 font-display text-sm text-jacarta-700 dark:text-white">
                          {t("header.all_nft")}
                        </span>
                      </Link>

                      <Link
                        to="/collections?category=art"
                        className="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span className="mr-3 rounded-xl bg-[#E4FCF4] p-[0.375rem]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="h-4 w-4 fill-[#10B981]"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M12 2c5.522 0 10 3.978 10 8.889a5.558 5.558 0 0 1-5.556 5.555h-1.966c-.922 0-1.667.745-1.667 1.667 0 .422.167.811.422 1.1.267.3.434.689.434 1.122C13.667 21.256 12.9 22 12 22 6.478 22 2 17.522 2 12S6.478 2 12 2zm-1.189 16.111a3.664 3.664 0 0 1 3.667-3.667h1.966A3.558 3.558 0 0 0 20 10.89C20 7.139 16.468 4 12 4a8 8 0 0 0-.676 15.972 3.648 3.648 0 0 1-.513-1.86zM7.5 12a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm9 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM12 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                          </svg>
                        </span>
                        <span className="mt-0 font-display text-sm text-jacarta-700 dark:text-white">
                          {t("header.art")}
                        </span>
                      </Link>

                      <Link
                        to="/collections?category=vieagem"
                        className="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span className="mr-3 rounded-xl bg-[#FDF7EE] p-[0.375rem]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="h-4 w-4 fill-[#FEB240]"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M17.5 2a4.5 4.5 0 0 1 2.951 7.897c.355.967.549 2.013.549 3.103A9 9 0 1 1 3.55 9.897a4.5 4.5 0 1 1 6.791-5.744 9.05 9.05 0 0 1 3.32 0A4.494 4.494 0 0 1 17.5 2zm0 2c-.823 0-1.575.4-2.038 1.052l-.095.144-.718 1.176-1.355-.253a7.05 7.05 0 0 0-2.267-.052l-.316.052-1.356.255-.72-1.176A2.5 2.5 0 1 0 4.73 8.265l.131.123 1.041.904-.475 1.295A7 7 0 1 0 19 13c0-.716-.107-1.416-.314-2.083l-.112-.33-.475-1.295 1.04-.904A2.5 2.5 0 0 0 17.5 4zM10 13a2 2 0 1 0 4 0h2a4 4 0 1 1-8 0h2z"></path>
                          </svg>
                        </span>
                        <span className="mt-0 font-display text-sm text-jacarta-700 dark:text-white">
                          {t("header.vieagem")}
                        </span>
                      </Link>*/}
                    </div>
                  </div>
                </li>
                <li className="group">
                  <div className="js-nav-dropdown group-dropdown relative">
                    <Link
                      to="/activity"
                      className={
                        isIndex()
                          ? "flex items-center justify-between py-3.5 font-display text-base text-main-bg-color lg:px-5"
                          : "flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent lg:px-5"
                      }
                    >
                      {t("header.stats")}
                    </Link>

                    <div
                      className="dropdown-menu left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2"
                      aria-labelledby="profileDropdown"
                    >
                      <Link
                        onClick={() => {
                          // navigate('/user');
                        }}
                        to="/rankings"
                        className="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        ></svg>
                        <span className="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                          {t("header.rankings")}
                        </span>
                      </Link>

                      <Link
                        onClick={() => {
                          // navigate('/activity');
                        }}
                        to="/activity"
                        className="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        ></svg>
                        <span className="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                          {t("header.activity")}
                        </span>
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="group">
                  <Link
                    to="/community"
                    className={
                      isIndex()
                        ? "flex items-center justify-between py-3.5 font-display text-base text-main-bg-color lg:px-5"
                        : "flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    }
                  >
                    {t("header.community")}
                  </Link>
                </li>
                {isSignedIn() && (
                  <li className="group">
                    <Link
                      onClick={(e) => {
                        /*
                                if(!isSignedIn()) {
                                  e.preventDefault();
                                  setModalIsOpen(true);
                                }
                            */
                      }}
                      to="/create-item"
                      className={
                        isIndex()
                          ? "flex items-center justify-between py-3.5 font-display text-base text-main-bg-color lg:px-5"
                          : "flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent lg:px-5"
                      }
                    >
                      {t("header.create")}
                    </Link>
                  </li>
                )}
                {isSignedIn() && (
                  <li className="group">
                    <Link
                      to="/staking"
                      className={
                        isIndex()
                          ? "flex items-center justify-between py-3.5 font-display text-base text-main-bg-color lg:px-5"
                          : "flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent lg:px-5"
                      }
                    >
                      {t("header.staking")}
                    </Link>
                  </li>
                )}
                {isSignedIn() && (
                  <li className="group">
                    <Link
                      to="/myRefferal"
                      className={
                        isIndex()
                          ? "flex items-center justify-between py-3.5 font-display text-base text-main-bg-color lg:px-5"
                          : "flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent lg:px-5"
                      }
                    >
                      {t("header.myrefferal")}
                    </Link>
                  </li>
                )}                
              </ul>
            </nav>

            {/*setModalIsOpen((prev) => !prev)*/}

            <div className="ml-8 hidden lg:flex xl:ml-12">
              {/*              {
                isSignedIn() &&
                <Link
                    onClick={async () => {
                      signOut();
                    }}
                    to='#'
                    className='js-wallet group flex h-10 w-10 items-center justify-center rounded-full border
                          border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent
                          focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15]
                          dark:hover:bg-accent'
                    data-bs-toggle='modal'
                    data-bs-target='#walletModal'
                    aria-label='wallet'>
                  <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      width='24'
                      height='24'
                      className='h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white
                              group-focus:fill-white dark:fill-white'>
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path
                        d='M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1
                              1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z'
                    />
                  </svg>
                </Link>
              }*/}

              {!isSignedIn() ? (
                <Link
                  onClick={async () => {
                    setModalIsOpen(true);
                  }}
                  to="#"
                  className="js-wallet group flex h-10 w-10 items-center justify-center rounded-full border
                          border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent
                          focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15]
                          dark:hover:bg-accent"
                  data-bs-toggle="modal"
                  data-bs-target="#walletModal"
                  aria-label="wallet"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white
                              group-focus:fill-white dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1
                              1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z"
                    />
                  </svg>
                </Link>
              ) : (
                <div
                  className={
                    isSignedIn()
                      ? "js-nav-dropdown group-dropdown relative"
                      : "relative"
                  }
                >
                  <button
                    onClick={() => {
                      // open login modal when the state is not connected
                      if (isSignedIn()) {
                        return;
                      }
                      setModalIsOpen(true);
                    }}
                    className="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border
								border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent
								focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15]
								dark:hover:bg-accent"
                    id="profileDropdown"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    aria-label="profile"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white
									group-focus:fill-white dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12
									13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"
                      />
                    </svg>
                  </button>
                  <div
                    className="dropdown-menu !-right-4 !top-[85%] !left-auto z-10 hidden min-w-[14rem]
								whitespace-nowrap rounded-xl bg-white transition-all will-change-transform
								before:absolute before:-top-3 before:h-3 before:w-full group-dropdown-hover:visible
								group-dropdown-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:grid
								lg:!translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl"
                    aria-labelledby="profileDropdown"
                  >
                    {true ? (
                      <div>
                        <AddressLink
                          address={
                            userdata && userdata.address
                              ? userdata.address
                              : "-"
                          }
                        />
                        {/*ethBalance ? ethBalance :*/}
                        <BalanceBox balance={balance} />
                      </div>
                    ) : null}

                    {/*<PointBox/>*/}

                    <Link
                      onClick={() => {
                        if (isSignedIn()) {
                          navigate("/user");
                        } else {
                          setModalIsOpen(true);
                        }
                      }}
                      to={`/user/${userdata.address}`}
                      className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50
									hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                          d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315
										0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"
                        ></path>
                      </svg>
                      <span className="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                        {t("profile_menu.my_profile")}
                      </span>
                    </Link>

                    <Link
                      onClick={() => {
                        if (isSignedIn()) {
                          navigate("/edit-profile");
                        } else {
                          setModalIsOpen(true);
                        }
                      }}
                      to="/edit-profile"
                      className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50
									hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99
										9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543
										3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8
										18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0
										0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12
										15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                        />
                      </svg>
                      <span className="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                        {t("profile_menu.edit_profile")}
                      </span>
                    </Link>

                    <Link
                      onClick={() => {
                        /*
                                                        if(isSignedIn()) {
                                                          navigate('/create-collection');
                                                        } else {
                                                          setModalIsOpen(true);
                                                        }
                                */
                      }}
                      to="/create-collection"
                      className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M19,2H5A2.005,2.005,0,0,0,3,4V20a2.005,2.005,0,0,0,2,2H19a2.005,2.005,0,0,0,2-2V4A2.005,2.005,0,0,0,19,2Zm0,11.15167V18.848L14,16ZM12,14.846,7,12H17ZM5,4H19V6H5ZM5,8h8v2H5Zm5,8L5,18.84833V13.14386Zm2,1.14978L17,20H7Z"></path>
                      </svg>
                      <span className="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                        {t("profile_menu.create_collection")}
                      </span>
                    </Link>

                    <Link
                      onClick={() => {
                        if (isSignedIn()) {
                          navigate("/referral");
                        } else {
                          setModalIsOpen(true);
                        }
                      }}
                      to="/referral"
                      className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fillRule="evenodd"
                        clipRule="evenodd"
                      >
                        <path d="M22.697 17.991c.487 1.694.954 3.318 1.254 4.369.033.118.049.236.049.35 0 .522-.32 1.005-.83 1.201l-.002.002c-.604.232-1.286-.013-1.599-.578-.694-1.253-1.866-3.304-2.47-4.357l.428.016c1.18 0 2.273-.371 3.17-1.003zm-4.215 6.002h-12.953l-.231-5.686s-1.901 3.195-2.867 4.937c-.313.565-.995.81-1.599.579l-.002-.003c-.51-.196-.83-.678-.83-1.201 0-.114.016-.232.049-.349.635-2.23 2.038-6.952 2.771-9.526.273-.959 1.152-1.619 2.154-1.619h9.582c-.344.717-.537 1.521-.537 2.369 0 2.751 2.027 5.033 4.67 5.437l-.207 5.062zm1.018-14.993c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5-4.5-2.016-4.5-4.5 2.016-4.5 4.5-4.5zm.5 2h-1v2h-2v1h2v2h1v-2h2v-1h-2v-2zm-7.994-11c2.777 0 5.031 2.243 5.031 5.006 0 2.763-2.254 5.006-5.031 5.006-2.777 0-5.031-2.243-5.031-5.006 0-2.763 2.254-5.006 5.031-5.006z"></path>
                      </svg>
                      <span className="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                        {t("profile_menu.referral")}
                      </span>
                    </Link>

                    {userdata &&
                      userdata.username &&
                      userdata.username !== "" &&
                      userdata.email &&
                      userdata.email !== "" && (
                        <a
                          href={"http://p2p.crypcorn.com/"}
                          target="_blank"
                          className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                          rel="noreferrer"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fillRule="evenodd"
                            clipRule="evenodd"
                          >
                            <path d="M5 7c2.761 0 5 2.239 5 5s-2.239 5-5 5c-2.762 0-5-2.239-5-5s2.238-5 5-5zm15-4c0-1.657-1.344-3-3-3-1.657 0-3 1.343-3 3 0 .312.061.606.148.888l-4.209 3.157c.473.471.877 1.009 1.201 1.599l4.197-3.148c.477.317 1.048.504 1.663.504 1.656 0 3-1.343 3-3zm-5.852 17.112c-.087.282-.148.576-.148.888 0 1.657 1.343 3 3 3 1.656 0 3-1.343 3-3s-1.344-3-3-3c-.615 0-1.186.187-1.662.504l-4.197-3.148c-.324.59-.729 1.128-1.201 1.599l4.208 3.157zm6.852-5.05c1.656 0 3-1.343 3-3s-1.344-3-3-3c-1.281 0-2.367.807-2.797 1.938h-6.283c.047.328.08.66.08 1s-.033.672-.08 1h6.244c.395 1.195 1.508 2.062 2.836 2.062z" />
                          </svg>
                          <span className="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                            {t("profile_menu.p2p_purchase")}
                          </span>
                        </a>
                      )}

                    <Link
                      to="/"
                      className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50
									hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99
										9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543
										3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8
										18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0
										0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12
										15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                        />
                      </svg>
                      <span className="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                        <div>
                          {Object.keys(lngs).map((lng) => (
                            <button
                              className={"mr-1"}
                              key={lng}
                              style={{
                                fontWeight:
                                  i18n.resolvedLanguage === lng
                                    ? "bold"
                                    : "normal",
                                color:
                                  i18n.resolvedLanguage === lng
                                    ? "black"
                                    : "grey",
                              }}
                              type="submit"
                              onClick={() => {
                                i18n.changeLanguage(lng);
                              }}
                            >
                              {lngs[lng].nativeName}
                            </button>
                          ))}
                        </div>
                      </span>
                    </Link>

                    {/*isSignedIn()*/}
                    {true && (
                      <a
                        onClick={() => {
                          signOut();
                        }}
                        className="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50
									hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z" />
                        </svg>
                        <span className="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                          {t("profile_menu.sign_out")}
                        </span>
                      </a>
                    )}
                  </div>
                </div>
              )}

              {/* Profile */}
            </div>
          </div>

          {/* <!-- Mobile Menu Actions --> */}
          <div className="ml-auto flex lg:hidden">
            {/* <!-- Profile --> */}
            {/*<Link
                  to='/edit-profile'
                  className='group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100
						bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent
						dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
                  aria-label='profile'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white
							dark:fill-white'>
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path
                      d='M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315
							0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z'
                  />
                </svg>
              </Link>*/}

            {/* <!-- Mobile Menu Toggle --> */}
            <button
              onClick={() => setMobileMenuIsOpen(true)}
              className="js-mobile-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border
						border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent
						focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
              aria-label="open mobile menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white
							dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
          {/*<div>{chainId} {status}</div>*/}
        </div>
      </header>
    </>
  );
};

export { Header };
