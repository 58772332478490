import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import user_avatar from "../../img/user/user_avatar.gif";
import {useMutation} from "react-query";
import apiClient from "../../apiClient";
import {RESOURCE_BASE_URL} from "../../Constant";
// import $ from "jquery";

const SquareFileInput = ({onImageUploaded, filePath}) => {
    const { t, i18n } = useTranslation();

    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const {isLoading, isError, status, mutate} = useMutation(newTodo => {
        return apiClient.post('/upload/nftImage', newTodo, {
        })
    });

    const changeHandler = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setIsFilePicked(true);
        const formData = new FormData();
        formData.append("uploadedImage", file);
        mutate(formData, {
            onSuccess: ({data}) => {
                const {uploadFileId, filePath} = data;
                if(onImageUploaded) {
                    onImageUploaded(uploadFileId, filePath);
                }
                // {"result":"ok","filePath":"xxxxxx/20221219062902292.jpg","uploadFileId":2}
                console.log(`data = ${JSON.stringify(data)}`);
            },
            onFail: (e) => {
                console.error(`/upload/nftImage error = ${e}`);
            }
        });

        var reader = new FileReader();
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function() {

                inputRef.current.src = e.target.result;
                inputRef.current['object-fit'] = 'fit';
                inputRef.current.width = 138;
                inputRef.current.height = 138;
            };
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    return (
        <div className="flex space-x-5 md:w-1/2 md:pl-8">
            <form className="shrink-0">
                <figure className="relative inline-block">
                    <img
                        id={'blah2'}
                        ref={inputRef}
                        src={filePath ? `${RESOURCE_BASE_URL}${filePath}` : user_avatar}
                        alt="collection avatar"
                        className="dark:border-jacarta-600 rounded-xl border-[5px] border-white w-36 h-36"
                    />
{/*                    {filePath ? (<img
                            id={'blah2'}
                            ref={inputRef}
                            src={filePath ? `${RESOURCE_BASE_URL}${filePath}` : user_avatar}
                            alt="collection avatar"
                            className="dark:border-jacarta-600 rounded-xl border-[5px] border-white w-36 h-36"
                        />) :
                        <img src={user_avatar} alt="collection avatar"
                             className="dark:border-jacarta-600 rounded-xl border-[5px] border-white w-36 h-36"/>
                    }*/}
                    <div
                        className="group hover:bg-accent border-jacarta-100 absolute -right-3 -bottom-2 h-8 w-8 overflow-hidden rounded-full border bg-white text-center hover:border-transparent"
                    >
                        <input
                            onChange={changeHandler}
                            type="file" accept="image/*"
                            className="absolute top-0 left-0 w-full cursor-pointer opacity-0"/>

                        <div className="flex h-full items-center justify-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                className="fill-jacarta-400 h-4 w-4 group-hover:fill-white"
                            >
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path
                                    d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
                                />
                            </svg>
                        </div>
                    </div>
                </figure>
            </form>
        </div>
  );
};

export { SquareFileInput };
