import React, {useEffect, useRef, useState} from 'react';
import gradient_light from '../img/gradient_light.jpg';
import {useMutation} from "react-query";
import apiClient from "../apiClient";
import {Modal} from "../components/Modal";
import {ModalBodyTerms} from "../components/ModalBodyTerms";
import {useTranslation} from "react-i18next";

const ContactPage = () => {
    const { t, i18n } = useTranslation();
    const agreeCheckInputRef = useRef(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const {isLoading: isLoadingCreate, isError: isErrorCreate, status: statusCreate, mutate: mutateCreate} = useMutation(data => {
        return apiClient.post('/social/inquiry', data, {
        })
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        if(!agreeCheckInputRef.current.checked) {
            alert("서비스 약관에 동의해주세요.");
            return;
        }

        const {name, email, message} = e.target;
        const nameValue = name.value;
        const emailValue = email.value;
        const messageValue = message.value;

        mutateCreate({name: nameValue, email: emailValue, message: messageValue}, {
            onSuccess: async (response) => {
                e.target.reset();
                alert('Sent!');
            },
            onFail: async (error) => {
                console.log(`Inquiry send onFail = ${JSON.stringify(error)}`);
            }
        })
    }

  return (
      <main className="pt-[5.5rem] lg:pt-24">
          {modalIsOpen && (
              <Modal
                  setModalIsOpen={setModalIsOpen}
                  title=''
                  btnTitle=''
                  childrenBody={
                      <ModalBodyTerms/>}
                  styledWrapper='modal-dialog max-w-2xl'
              />
          )}
          <section
              className="after:bg-jacarta-900/60 relative bg-cover bg-center bg-no-repeat py-32 after:absolute after:inset-0"
              style={{backgroundImage: "url('knowledge_base_banner.jpg')"}}
          >
              <div className="container relative z-10">
                  <h1 className="font-display text-center text-4xl font-medium text-white">{t('contact_us.get_in_touch')}</h1>
              </div>
          </section>

          <section className="dark:bg-jacarta-800 relative py-24">

              <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                  <img src={gradient_light} alt="gradient" className="h-full w-full"/>
              </picture>

              <div className="container">
                  <div className="lg:flex">
                      <div className="mb-12 lg:mb-0 lg:w-2/3 lg:pr-12">
                          <h2 className="font-display text-jacarta-700 mb-4 text-xl dark:text-white">{t('contact_us.contact_us')}</h2>
                          <p className="dark:text-jacarta-300 mb-16 text-lg leading-normal">
                              {t('contact_us.contact_us_desc')}
                          </p>
                          <form id="contact-form" method="post" onSubmit={handleSubmit}>
                              <div className="flex space-x-7">
                                  <div className="mb-6 w-1/2">
                                      <label htmlFor="name"
                                             className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white"
                                      >{t('contact_us.name')}<span className="text-red">*</span></label
                                      >
                                      <input
                                          name="name"
                                          className="contact-form-input dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
                                          id="name"
                                          type="text"
                                          required
                                      />
                                  </div>

                                  <div className="mb-6 w-1/2">
                                      <label htmlFor="email"
                                             className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white"
                                      >{t('contact_us.email')}<span className="text-red">*</span></label
                                      >
                                      <input
                                          name="email"
                                          className="contact-form-input dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
                                          id="email"
                                          type="email"
                                          required
                                      />
                                  </div>
                              </div>

                              <div className="mb-4">
                                  <label htmlFor="message"
                                         className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white"
                                  >{t('contact_us.message')}<span className="text-red">*</span></label
                                  >
                                  <textarea
                                      id="message"
                                      className="contact-form-input dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
                                      required
                                      name="message"
                                      rows="5"
                                  ></textarea>
                              </div>

                              <div className="mb-6 flex items-center space-x-2">
                                  <input
                                      ref={agreeCheckInputRef}
                                      type="checkbox"
                                      id="contact-form-consent-input"
                                      name="agree-to-terms"
                                      className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
                                  />
                                  <label htmlFor="contact-form-consent-input" className="dark:text-jacarta-200 text-sm"
                                  ><a onClick={() => setModalIsOpen(true)} target="_blank" className="text-accent">{t('contact_us.agree_to_terms')}</a></label
                                  >
                              </div>

                              <button
                                  type="submit"
                                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                                  id="contact-form-submit"
                              >
                                  {t('contact_us.submit')}
                              </button>

                              <div
                                  id="contact-form-notice"
                                  className="relative mt-4 hidden rounded-lg border border-transparent p-4"
                              ></div>
                          </form>
                      </div>

                      <div className="lg:w-1/3 lg:pl-5">
                          <h2 className="font-display text-jacarta-700 mb-4 text-xl dark:text-white">{t('contact_us.information')}</h2>
                          <p className="dark:text-jacarta-300 mb-6 text-lg leading-normal">
                              {/*In order to best help, please enter as many details as you can.*/}
                          </p>

                          <div
                              className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2.5xl border bg-white p-10"
                          >
                              <div className="mb-6 flex items-center space-x-5">
                  <span
                      className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base flex h-11 w-11 shrink-0 items-center justify-center rounded-full border"
                  >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="fill-jacarta-400"
                    >
                      <path fill="none" d="M0 0h24v24H0z"/>
                      <path
                          d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                      />
                    </svg>
                  </span>

                                  <div>
                                      <span
                                          className="font-display text-jacarta-700 block text-base dark:text-white">Address</span>
                                      <address className="dark:text-jacarta-300 text-sm not-italic">234, Beotkkot-ro,
                                          Geumcheon-gu, Seoul, Republic of Korea
                                      </address>
                                  </div>
                              </div>
                              <div className="flex items-center space-x-5">
                  <span
                      className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base flex h-11 w-11 shrink-0 items-center justify-center rounded-full border"
                  >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="fill-jacarta-400"
                    >
                      <path fill="none" d="M0 0h24v24H0z"/>
                      <path
                          d="M2.243 6.854L11.49 1.31a1 1 0 0 1 1.029 0l9.238 5.545a.5.5 0 0 1 .243.429V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.283a.5.5 0 0 1 .243-.429zM4 8.133V19h16V8.132l-7.996-4.8L4 8.132zm8.06 5.565l5.296-4.463 1.288 1.53-6.57 5.537-6.71-5.53 1.272-1.544 5.424 4.47z"
                      />
                    </svg>
                  </span>

                                  <div>
                                      <span
                                          className="font-display text-jacarta-700 block text-base dark:text-white">Email</span>
                                      <a
                                          href="mailto:help@pop-corn.shop"
                                          className="hover:text-accent dark:text-jacarta-300 text-sm not-italic"
                                      >help@pop-corn.shop</a
                                      >
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </main>
  );
};

export { ContactPage };
