import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PersistentAppState from "../PersistentAppState";
import create from "zustand";
import apiClient from "../apiClient";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { trimString } from "../JsUtils";
import { useUserStore } from "../useUserStore";
//const useUserStore = create(PersistentAppState);

const ReferralPage = () => {
  const { t, i18n } = useTranslation();
  const userdata = useUserStore((state) => state.userdata);
  const setUserdata = useUserStore((state) => state.setUserdata);
  console.log(`userdata = ${JSON.stringify(userdata)}`);

  const referralInputRef = useRef();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [rewardList, setRewardList] = useState([]);
  const [resultMessage, setResultMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [registrationIsEnabled, setRegistrationIsEnabled] = useState(false);
  const [registrationIsDone, setRegistrationIsDone] = useState(false);
  const [cumulativeReward, setCumulativeReward] = useState(0);

  useEffect(() => {
    (async () => {
      const { inviterUserReferralCode, inviterUserUsername, inviterUserEmail } =
        userdata;
      setRegistrationIsDone(inviterUserReferralCode);

      if (inviterUserReferralCode) {
        referralInputRef.current.value = inviterUserReferralCode;
        setUsername(inviterUserUsername);
        setEmail(inviterUserEmail);
      }

      const { data: rewardListData } = await apiClient.get(`user/rewardList`);
      console.log(`rewardListData = ${JSON.stringify(rewardListData)}`);
      const { rewardList, agg } = rewardListData;

      const { cumulativeReward } = agg;
      setCumulativeReward(cumulativeReward);

      setRewardList(rewardList);
    })();
  }, [userdata]);

  const [input, setInput] = useState("");
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const OnReferralCodeKeyPress = async (e) => {
    // e.preventDefault();
    const referralCode = referralInputRef.current.value; //e.target.value;
    console.log(`referralCode = ${referralCode}`);

    const { data: referralCodeData } = await apiClient.get(
      `user/searchInviter/${referralCode}`
    );
    console.log(`referralCodeData = ${JSON.stringify(referralCodeData)}`);
    const { result } = referralCodeData;
    if (result === "ok") {
      const { inviter } = referralCodeData;
      // console.log(`inviter ${inviter}`);
      setRegistrationIsEnabled(true);

      const { username, email } = inviter;
      setUsername(username);
      setEmail(email);
    } else {
      setUsername("");
      setEmail("");
    }
  };

  const onRegistrationSubmit = async (e) => {
    e.preventDefault();

    const { referralCode } = e.target;
    const referralCodeValue = referralCode.value;
    console.log(`referralCodeValue = ${referralCodeValue}`);

    const { data: updateReferralCodeData } = await apiClient.post(
      `user/updateReferralCode/${referralCodeValue}`
    );
    console.log(
      `updateReferralCodeData = ${JSON.stringify(updateReferralCodeData)}`
    );
    const { result } = updateReferralCodeData;
    if (result === "ok") {
      setResultMessage(`Registration is complete`);

      const { data: userdata } = await apiClient.get("user/profile");
      const { profile } = userdata;
      console.log(`profile! = ${JSON.stringify(profile)}`);
      setUserdata(profile);
      // setRegistrationIsEnabled(true);
      // alert(`Registration succeed`);
    } else if (result === "no_inviter") {
      setErrorMessage(
        `Invalid referral code (no_inviter). Please check and enter again.`
      );
      // setRegistrationIsEnabled(false);
      //            alert(`Referral Code is invalid: no_inviter`);
    } else if (result === "invalid_referral_code") {
      setErrorMessage(
        `Invalid referral code (invalid_referral_code). Please check and enter again.`
      );
      // setRegistrationIsEnabled(false);
      // alert(`Referral Code is invalid: invalid_referral_code`);
    } else if (result === "incomplete_referral_code") {
      setErrorMessage(
        `Referral code requires email and nickname to registered. Please contact to referral owner`
      );
    } else {
      setErrorMessage(
        `Invalid referral code (nok). Please check and enter again.`
      );
      // setRegistrationIsEnabled(false);
      // alert(`Operation failed`);
    }
  };

  return (
    <section className="relative py-24 text-center">
      {/*
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <img src="img/gradient_light.jpg" alt="gradient" className="h-full w-full"/>
          </picture>
*/}
      <div className="container">
        <h1 className="py-16 text-center font-display text-4xl font-medium text-jacarta-700 dark:text-white">
          {t("referral.referral")}
        </h1>

        <div className="mx-auto max-w-[48.125rem]">
          <div className="mb-12">
            <label className="mb-2 block font-display text-jacarta-700 dark:text-white">
              {t("referral.my_referral")}
            </label>
            <p className="mb-3 text-4xl text-green">
              {userdata && userdata.referralCode}
              <CopyToClipboard
                text={userdata && userdata.referralCode}
                onCopy={() => alert(`Copied!`)}
              >
                <span className="ml-2 text-2xs font-bold text-red">Copy</span>
              </CopyToClipboard>
            </p>

            <p className="mb-3 text-sm leading-normal dark:text-jacarta-300">
              {t("referral.copy_referral")}
            </p>
          </div>

          <form onSubmit={onRegistrationSubmit}>
            <div className="mb-6">
              <label
                htmlFor="referral-name"
                className="mb-2 block font-display text-jacarta-700 dark:text-white"
              >
                {t("referral.enter_referral")}
              </label>
              <p className="mb-3 text-sm leading-normal dark:text-jacarta-300">
                {t("referral.enter_referral_2")}
              </p>

              <input
                ref={referralInputRef}
                readOnly={registrationIsDone}
                onChange={OnReferralCodeKeyPress}
                // onChange={handleInputChange}
                // value={input}
                name="referralCode"
                type="text"
                style={{ align: "center" }}
                id="referral-name"
                className="read-only:bg-gray-100 w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                placeholder={t("referral.enter_referral")}
              />
              <p className="mt-3 mb-3 text-2xs text-green">
                {/*Registration is complete.*/}
                {resultMessage}
              </p>
              <p className="mt-3 mb-3 text-md text-blue">
                {t("referral.nickname")}: {username}, {t("referral.email")}:{" "}
                {email}
              </p>

              <p className="mt-3 mb-3 text-2xs text-red">
                {/*Invalid referral code. Please check and enter again.*/}
                {errorMessage}
              </p>
            </div>

            <button
              disabled={!registrationIsEnabled}
              className="cursor-default rounded-full bg-accent py-3 px-8 text-center font-semibold text-white transition-all disabled:bg-[#B9A0FF]"
            >
              {t("referral.registration")}
            </button>
          </form>

          <div className="scrollbar-custom mt-12 overflow-x-auto text-center">
            <label className="mb-2 block font-display text-jacarta-700 dark:text-white">
              {" "}
              {t("referral.reward_history")}
            </label>
            <label className="text-lg text-green">
              {t("referral.cumulative_reward")}{" "}
              <span className="font-bold">
                {cumulativeReward &&
                  Number(cumulativeReward).toLocaleString("en")}{" "}
                CORN
              </span>
            </label>

            <div
              role="table"
              className="mt-3 w-full min-w-[736px] border border-jacarta-100 bg-white text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white lg:rounded-2lg"
            >
              <div
                className="flex rounded-t-2lg bg-jacarta-50 dark:bg-jacarta-600"
                role="row"
              >
                <div className="w-[28%] py-3 px-4" role="columnheader">
                  <span className="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">
                    {t("referral.transaction_date")}
                  </span>
                </div>
                <div className="w-[18%] py-3 px-4" role="columnheader">
                  <span className="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">
                    {t("referral.nickname")}
                  </span>
                </div>
                <div className="w-[18%] py-3 px-4" role="columnheader">
                  <span className="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">
                    {t("referral.transaction")}
                  </span>
                </div>
                <div className="w-[18%] py-3 px-4" role="columnheader">
                  <span className="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">
                    {t("referral.reward")}
                  </span>
                </div>
                <div className="w-[18%] py-3 px-4" role="columnheader">
                  <span className="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">
                    {t("referral.payment_date")}
                  </span>
                </div>
              </div>
            </div>

            {rewardList &&
              rewardList.length > 0 &&
              rewardList.map((item) => (
                <div
                  className="flex transition-shadow hover:shadow-lg"
                  role="row"
                >
                  <div
                    className="w-[28%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                    role="cell"
                  >
                    <span className="mr-2 lg:mr-4">
                      {moment(item.timestamp).format("YYYY-MM-DD HH:mm:ss")}
                    </span>
                  </div>
                  <div
                    className="w-[18%] items-center whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                    role="cell"
                  >
                    <span className="text-sm font-medium tracking-tight">
                      {item.username}
                    </span>
                  </div>

                  <a
                    href={`https://${
                      process.env.REACT_APP_ENVIRONMENT === "prod"
                        ? ""
                        : "baobab."
                    }scope.klaytn.com/tx/${item.hash}`}
                    target="_blank"
                    className="w-[18%] border-t border-jacarta-100 py-4 px-4 text-center dark:border-jacarta-600"
                    role="cell"
                    rel="noreferrer"
                  >
                    <span className="text-sm font-medium tracking-tight text-accent">
                      {trimString(item.hash, 5)}
                    </span>
                  </a>
                  <div
                    className="w-[18%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                    role="cell"
                  >
                    <span className="text-sm font-medium tracking-tight">
                      {item && Number(item.rewardedPrice).toLocaleString("en")}
                    </span>{" "}
                    <span className="text-sm font-medium tracking-tight">
                      CORN
                    </span>
                  </div>
                  <div
                    className="w-[18%] border-t border-jacarta-100 py-4 px-4 text-center dark:border-jacarta-600"
                    role="cell"
                  >
                    <span>
                      {item.rewardedAt
                        ? moment(item.rewardedAt).format("YYYY-MM-DD")
                        : "미지급"}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export { ReferralPage };
