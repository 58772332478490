import { Link } from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";

/**
 clickName: '취소'
 collectionAssetId:
 collectionAssetImageUrl:
 collectionAssetName: NJBadge(WHITE) #NJW0405
 stackingDt: 2023년 3월 6일
 cumRewardPoint: 1,000
 */
function StakingItem({
  clickName,
  collectionAssetId,
  collectionAssetImageUrl,
  collectionAssetName,
  stackingDt,
  cumRewardPoint,
  onClick,
}) {
  const { t, i18n } = useTranslation();

  return (
    <article>
      <Link to={`/item/${collectionAssetId}`}>
        <div className="block items-center rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
          <figure>
            <div>
              <img
                src={collectionAssetImageUrl}
                alt="item 1"
                width="230"
                height="230"
                className="w-full rounded-[0.625rem]"
                loading="lazy"
              />
            </div>
          </figure>
          <div className="mt-2 mb-0 items-center">
            <p className="w-full text-center font-display text-base text-sm text-jacarta-500">
              {collectionAssetName}
            </p>
          </div>
          {stackingDt && (
            <div className="mb-0 items-center">
              <p className="w-full text-center font-display text-base text-sm text-jacarta-500">{`${t("staking.stake_date")} : ${stackingDt}`}</p>
            </div>
          )}
          {cumRewardPoint && (
            <div className="mb-2 items-center">
              <p className="w-full text-center font-display text-base text-sm text-jacarta-500">{`${t("staking.cum_reward")} : ${cumRewardPoint} CORN`}</p>
            </div>
          )}
          <span
            className="flex place-content-center whitespace-nowrap rounded-md border  border-jacarta-100 py-1 px-2 dark:border-jacarta-600"
            onClick={onClick}
          >
            <span className="w-full  text-center text-sm text-green">
              {clickName}
            </span>
          </span>
        </div>
      </Link>
    </article>
  );
}

export { StakingItem };
