import React, {useEffect, useState} from 'react';
import gradient_light from '../img/gradient_light.jpg';
import {useTranslation} from "react-i18next";
import apiClient from "../apiClient";
import {ExpandableItem} from "./ExpandableItem";


/*const faqData = [
    {title: 'NFT란 무엇인가?', description : 'NFT란 Non-Fungible Token 의 약자로 대체 불가능한 토큰을 뜻합니다. 비트코인과 이더리움 같은 여러개의 발행량이 존재하며 서로 교환이 가능한것은 FT fungible Token이라 하며 그 반대가 되는 Token으로 고유한 인식 값이 부여되어 다른 어떠한 것과 가치가 동일하지 않는 토큰을 NFT라고 합니다.'},
    {title: '팝콘 NFT 마켓 플레이스란 무엇인가요?', description : 'VIP 고객들에게 비아젬 NFT를 판매하는 사이트로 소장 가치가 높은 NFT를 누구보다 앞서 소유 할 수 있는 기회를 제공하는 곳입니다.'},
    {title: 'NFT Collection이란 무엇인가요?', description : 'NFT의 구분 단위로 NFT를 발행하는 코드 기준이 됩니다. 가수의 앨범을 예로 들 수가 있습니다. <br></br>다른 용어로는 에디션이라고도 할 수 있습니다.'},
    {title: 'NFT ITEM이란 무엇인가요?', description : '하나의 Collection아래 유사하거나 같은 아이템이 존재 할 수 있는데 이것은 앨범에 수록된 곡과 같은 의미를 지니는 단위 입니다.'},
    {title: 'NFT Art란 무엇인가요?', description : 'NFT 민팅을 통해서 예술 작품을 디지털화한 상품들을 의미 합니다.'},
    {title: '비아젬이란 무엇인가요?', description : '스타의 DNA를 수집하여 최첨단 기술을 이용하여 보석과 믹싱하여 오직 팬들만을 위한 소장가치를 극대화한 쥬얼리를 만드는 곳입니다'},
    {title: 'NFT 결제 수단은 무엇이 있나요?', description : '최초 구매시에 결제 수단은 Crypcorn를 통해서만 구매가 가능하며 crypcorn는 해외 거래소나 P2P사이트를 통해서 개인간의 거래를 통해서 구매가 가능 합니다. <br></br>MEXC 거래소 : <a href="https://www.mexc.com/ko-KR/exchange/CORN_USDT" target="_blank">https://www.mexc.com/ko-KR/exchange/CORN_USDT</a><br></br>LBANK 거래소 : <a href="https://www.lbank.info/exchange/corn/usdt/" target="_blank">https://www.lbank.info/exchange/corn/usdt/</a><br></br>DigiFinex 거래소 : <a href="https://www.digifinex.com/en-ww/trade/USDT/CORN" target="_blank">https://www.digifinex.com/en-ww/trade/USDT/CORN</a>'},
    {title: 'NFT 구매 후 어디에 저장 되나요?', description : '개별 Klaytn 지갑에 저장되며 explorer 사이트에서 개별 지갑 주소를 통해서 소유한 NFT내용을 확인 하실 수 있습니다.'},
    {title: 'NFT 구매를 하면 어떤 이점이나 혜택이 있나요?', description : 'NFT를 소유하신 유저는 소정의 Daily Reward를 받게 됩니다.'},
];*/

const FaqPage = () => {
    const { t, i18n } = useTranslation();
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        (async () => {
            const {data: faqData} = await apiClient.get(`social/faqs`, { params: {
                    language : i18n.resolvedLanguage
                }});
            console.log(`faqData = ${JSON.stringify(faqData)}`);
            const {faqs, result} = faqData;
            if(result === 'ok') {
                setFaqs(faqs);
            }
        })();
    }, []);

  return (
      <main className="pt-[5.5rem] lg:pt-24">
          <section className="relative py-24">
{/*              <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                  <img src="img/gradient_light.jpg" alt="gradient" className="h-full"/>
              </picture>*/}
              <div className="container">
                  <div className="pt-20 pb-24">
                      <h2 className="font-display text-jacarta-700 mb-10 text-center text-xl font-medium dark:text-white">
                          {t('faq.freq_asked')}
                      </h2>
                      <p className="dark:text-jacarta-300 mx-auto mb-10 max-w-md text-center text-lg">
                          {t('faq.freq_asked_about')}
                          <br/>
                          <a href='/contact' className="text-accent">{t('faq.contact_support')}</a>
                      </p>

                      <div className="accordion mx-auto max-w-[35rem]" id="accordionFAQ">
                          {
                              (faqs && faqs.length  > 0) &&
                              faqs.map((item, index) =>
                                  <ExpandableItem key={index}
                                      title={item.title}
                                  >
                                      {item.contents}
                                  </ExpandableItem>
                              )
                          }
                      </div>
                  </div>

              </div>
          </section>
      </main>
  );
};

export { FaqPage };
