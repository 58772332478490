import React from 'react';
import {useTranslation} from "react-i18next";

const ItemTabs = ({ setActiveTab }) => {
    const { t, i18n } = useTranslation();
  return (
    <ul className='nav nav-tabs flex items-center' role='tablist'>
      <li className='nav-item' role='presentation' onClick={() => setActiveTab('details')}>
        <button
          className='nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700
					dark:hover:text-white'
          id='details-tab'
          data-bs-toggle='tab'
          data-bs-target='#details'
          type='button'
          role='tab'
          aria-controls='details'
          aria-selected='false'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className='mr-1 h-5 w-5 fill-current'>
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2V4H5v16h14zM7 6h4v4H7V6zm0
						6h10v2H7v-2zm0 4h10v2H7v-2zm6-9h4v2h-4V7z' />
          </svg>
          <span className='font-display text-base font-medium'>{t('common.details')}</span>
        </button>
      </li>

      {/* Activity */}
      <li className='nav-item' role='presentation' onClick={() => setActiveTab('activity')}>
        <button
          className='nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700
					dark:hover:text-white'
          id='activity-tab'
          data-bs-toggle='tab'
          data-bs-target='#activity'
          type='button'
          role='tab'
          aria-controls='activity'
          aria-selected='false'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className='mr-1 h-5 w-5 fill-current'>
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M11.95 7.95l-1.414 1.414L8 6.828 8 20H6V6.828L3.465 9.364 2.05 7.95 7 3l4.95 4.95zm10 8.1L17 21l-4.95-4.95
						1.414-1.414 2.537 2.536L16 4h2v13.172l2.536-2.536 1.414 1.414z' />
          </svg>
          <span className='font-display text-base font-medium'>{t('common.activity')}</span>
        </button>
      </li>

      {/* Price History */}
      <li className='nav-item' role='presentation' onClick={() => setActiveTab('priceHistory')}>
        <button
          className='nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700
					dark:hover:text-white'
          id='price-history-tab'
          data-bs-toggle='tab'
          data-bs-target='#price-history'
          type='button'
          role='tab'
          aria-controls='price-history'
          aria-selected='false'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className='mr-1 h-5 w-5 fill-current'>
            <path fill='none' d='M0 0H24V24H0z' />
            <path d='M5 3v16h16v2H3V3h2zm15.293 3.293l1.414 1.414L16 13.414l-3-2.999-4.293 4.292-1.414-1.414L13 7.586l3 2.999 4.293-4.292z' />
          </svg>
          <span className='font-display text-base font-medium'>{t('common.price_history')}</span>
        </button>
      </li>
    </ul>
  );
};

export { ItemTabs };
