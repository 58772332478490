import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from "react";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'kr',
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    common: {
                        items: 'Items',
                        items_ordinal: 'Items',
                        items_total: 'Items',
                        created_by: 'Created by',
                        owners: 'Owners',
                        floor_price: 'Floor Price',
                        volume_traded: 'Volume Traded',
                        owned: 'Owned',
                        activity: 'Activity',
                        sort_by: 'Sort by',
                        recently_added: 'Recently Added',
                        price_low_to_high: 'Price : Low to High',
                        price_high_to_low: 'Price : High to Low',
                        creator: 'Creator',
                        royalties: 'royalties',
                        owned_by: 'Owned by',
                        buy: 'Buy',
                        buy_direct: 'Direct Buy',
                        buy_escrow: 'Escrow Buy',
                        details: 'Details',
                        contract_address: 'Contract Address',
                        token_id: 'Token ID',
                        token_standard: 'Token Standard',
                        blockchain: 'Blockchain',
                        event: 'Event',
                        price: 'Price',
                        from: 'From',
                        to: 'to',
                        date: 'Date',
                        price_history : 'Price History',
                        last_7_days: 'Last 7 Days',
                        last_14_days: 'Last 14 Days',
                        last_30_days: 'Last 30 Days',
                        last_all: 'All Time',
                        day_avg_price_90: '90 Day Avg. Price',
                        day_volume_90: '90 Day Volume',
                        joined: 'Joined',
                        subtotal: 'Subtotal',
                        item: 'Item',
                        facebook: 'Facebook',
                        copy: 'Copy',
                        sale_in_progress: 'Sale in progress',
                        buy_in_progress: 'Buy in progress',
                        sell_now: 'Sell Now',
                        modify_now: 'Modify Now',
                        cancel_now: 'Cancel Now',
                        cancel: 'Cancel'
                    },
                    phrase: {
                        check_agree_1: `By checking this box, I agree to Popcornnft's`,
                        check_agree_2: `Terms of Service`,
                        check_escrow_agree: 'Option :  Escrow (If you own the product at the time of sale and need to receive it offline)'
                    },
                    header: {
                        explore: 'Explore',
                        stats: 'Stats',
                        community: 'Community',
                        create: 'Create',
                        search: 'Search',
                        rankings: 'Rankings',
                        activity: 'Activity',
                        all_nft: 'All NFTs',
                        art: 'art',
                        vieagem: 'VIEAGEM',
                        myrefferal: 'MyRefferal',
                        
                    },
                    profile_menu: {
                        balance: 'Balance',
                        my_profile: 'My Profile',
                        edit_profile: 'Edit Profile',
                        create_collection: 'Create A Collection',
                        referral: 'Referral',
                        p2p_purchase: 'Purchase CORN(P2P)',
                        english: 'English',
                        korean: 'Korean',
                        sign_out: 'SignOut',
                    },
                    referral: {
                        referral: 'Referral',
                        my_referral: 'My referral code',
                        copy_referral: 'Check and copy your referral code.',
                        enter_referral: 'Enter referral code',
                        enter_referral_2: 'Enter your referral code, verify your referral, and register. ',
                        registration: 'Registration',
                        reward_history: 'Reward History',
                        cumulative_reward: 'Total cumulative reward',
                        transaction_date: 'Transaction date ',
                        nickname: 'Nickname',
                        transaction: 'Transaction',
                        reward: 'Reward',
                        payment_date: 'Payment date',
                        email: 'E-mail',
                    },
                    profile_edit: {
                        username : 'Username',
                        bio : 'Bio',
                        email : 'Email address',
                        links : 'Links',
                        wallet_address : 'Wallet Address',
                        update_profile : 'Update Profile',
                    },
                    main: {
                        hot_item: 'Hot Item',//`Special Edition\nBy LEE EUNA`,
                        featured_collections: 'Featured collections',
                        create_and_sell_your_ntfs: 'Create and sell your NFTs',
                        setup_your_wallet: '1. Set up your wallet',
                        setup_your_wallet_desc: `Once you've set up your wallet of choice, connect it to METAMASK clicking the "WALLET" icon the top right corner.`,
                        create_your_collection: '2. Create Your Collection',
                        create_your_collection_desc: 'Click Create and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.',
                        add_your_nfts: '3. Add Your NFTs',
                        add_your_nfts_desc: 'Upload your work (image, video), add a title and description, and customize your NFTs with properties, stats.',
                        list_then_for_sale: '4. List Them For Sale',
                        list_then_for_sale_desc: 'Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs!',
                        join_mailing_list: 'Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and tips and tricks for navigating PopcornNFT',
                        subscribe: 'Subscribe',
                    },
                    footer: {
                        biological_elemental: 'Biological elemental jewelry NFT of someone I love',
                        marketplace: 'Marketplace',
                        all_nfts: 'All NFTs',
                        company: 'Company',
                        contact_us: 'Contact Us',
                        faq: 'FAQ',
                        my_account: 'My Account',
                        authors: 'Authors',
                        create_collection: 'Create Collection',
                        author_profile: 'Author Profile',
                        create_item: 'Create Item',
                    },
                    explore: {
                        explore_collections: 'Explore Collections',
                        sorting_recent: 'Recent',
                        sorting_volume: 'Volume',
                    },
                    category: {
                        all: 'all',
                        art: 'art',
                        photograph: 'photograph',
                        music: 'music',
                        video: 'video',
                        sports: 'sports',
                        cyber_world: 'cyber_world',
                        celebrity: 'celebrity',
                    },
                    community: {
                        community: 'Community',
                        community_desc: 'Only users who have been verified as holders can participate in the\n' +
                            '          community.',

                    },
                    ranking: {
                        rankings: 'Rankings',
                        rank: 'Rank',
                        collection: 'Collection',
                        volume: 'Volume',
                        floor_price: 'Floor Price',
                        owners: 'Owners',
                    },

                    listreferral: {
                        referral_title :'My Referral List',
                        regno:'Register No',
                        regdate: 'Register Date',
                        username: 'User Name',
                        email: 'User Email',
                        address: 'Wallet Address',
                        referralname: 'Referral Name',
                        inviteecount: 'Invitee Count',
                    },

                    activity: {
                        activity: 'Activity',
                        list_by: 'Listed by',
                        minted_by: 'Minted by',
                        transferred_from: 'Transferred from',
                    },
                    create_nft: {
                        create: 'Create',
                        image_video: 'Image, Video',
                        image_video_desc: 'Drag or choose your file to upload',
                        name: 'Name',
                        name_hint: 'Item Name',
                        goods: 'Goods',
                        goods_desc: 'Choose if the NFT contains a goods.',
                        goods_included: 'Goods included',
                        goods_not_included: 'Goods not included',
                        external_link: 'External link',
                        external_link_desc: `We will include a link to this URL on this item's detail page, so that users can click to learn more about it. You are welcome to link to your own webpage with more details.`,
                        description: 'Description',
                        description_desc: `The description will be included on the item's detail page underneath its image. Markdown syntax is supported.`,
                        description_hint: 'Provide a detailed description of your item.',
                        collection: 'Collection',
                        collection_desc: 'This is the collection where your item will appear.',
                        collection_hint: 'Select collection',
                        supply: 'Supply',
                        supply_desc: 'The number of items that can be minted. No gas cost to you!',
                        blockchain: 'Blockchain',
                    },
                    contact_us: {
                        get_in_touch: 'Get in touch',
                        contact_us: 'Contact Us',
                        contact_us_desc: `Have a question? Need help? Don't hesitate, drop us a line`,
                        name: 'Name',
                        email: 'Email',
                        message: 'Message',
                        agree_to_terms: 'I agree to the Terms of Service',
                        submit: 'Submit',
                        information: 'Information'
                    },
                    faq: {
                        freq_asked : 'Frequently asked questions',
                        freq_asked_about : 'Frequently Asked Questions about PopcornNFT',
                        contact_support: 'Contact Support',
                    },
                    create_collection: {
                        create_collection: 'Create a Collection',
                        logo_image: 'Logo image',
                        drag: "Drag or choose your file to upload",
                        recommend_image: "We recommend an image of at least 300x300. Gifs work too. Max 5mb.",
                        banner: "Banner image",
                        drag_choose: "Drag or choose your file to upload",
                        banner_desc: `This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1920*300 "recommended.`,
                        name: "Name",
                        collection_name: "Collection name",
                        url: "URL",
                        url_desc: `Customize your URL on PopcornNFT. Must only contain lowercase letters, numbers, and "hyphens.`,
                        description: 'Description',
                        royalty: `Collection owners can collect a fee when a user re-sells an item they created.`,
                        description_desc: `Provide a detailed description of your item.`,
                        creator_fee: "Creator fees",
                        creator_fee_desc: `Collection owners can collect a fee when a user re-sells an item they created. Contact the collection owner to change the fee percentage or the payout address. Set a percentage of up to 10% total.`,
                        category: "Category",
                        category_desc: "Please select a category.",
                        select_category: "Select Category",
                        links: "Links",
                        blockchain: "Blockchain",
                        payment_token: "Payment tokens",
                        create: "Create",
                    },
                    userprofile_navigation: {
                        owned: 'Owned',
                        collections: 'Collections',
                        activity: 'Activity',
                    },
                    staking:  {
                        staking_title: "POPCORN Staking",
                        staking_subtitle: "If you apply for staking with the item you have, you will receive a reward!",
                        staked_items: "Item being staking",
                        if_stake_reward_given: "If you apply for staking with the item you have, you will receive a reward!",
                        stake_date: "Application date",
                        cum_reward: "Rewards",
                        request_for_reward: "Claim reward",
                        stakable_items: "Items that can be applied for staking",
                        staking_conditions: `Among the items that can be staked, only items that have not been registered for sale can be applied for. If the item is not displayed even though it is an item that can be staked, please check the sales registration status.`,
                        staking_participate: `Staking application`,
                        qna1: {
                            title: "What is POPCORN Staking?",
                            description:
                                "If you apply for staking every day and apply for a reward, you can receive an amount corresponding to the daily reward rate as a reward.\n",
                        },
                        qna2: {
                            title: "What are the conditions for participation?",
                            description: "You can participate by purchasing items that can be staked.",
                        },
                        qna3: {
                            title: "What is the standard and timing of reward payment?",
                            description:
                                "If you apply for a daily reward in your wallet after participating, 12% of your purchase amount will be paid out daily.",
                        },
                        qna4: {
                            title: "How do I apply for a reward?",
                            description:
                                `Click Claim Rewards and you will be directed to your staking wallet.
Click Apply in the list to play the video, and you'll be paid automatically when you're done watching.
You can apply for a reward once a day from 00:00 every day`,
                        },
                        qna5: {
                            title: "What are the rewards?",
                            description:
                                `It is paid in CORN POINT, and can be swapped with MEVerse-based CORN later.`,
                        },
                    }
                },
            },
            kr: {
                translation: {
                    common: {
                        items: '개수',
                        items_ordinal: '개',
                        items_total: '총개수',
                        created_by: '창작자',
                        owners: '소유자',
                        floor_price: '최저가',
                        volume_traded: '총 거래금',
                        owned: '소유',
                        activity: '활동',
                        sort_by: '정렬 기준',
                        recently_added: '최근 추가',
                        price_low_to_high: '가격: 낮음순으로',
                        price_high_to_low: '가격: 높음순으로',
                        creator: '창작자',
                        royalties: '로열티',
                        owned_by: '소유자',
                        buy: '구매',
                        buy_direct: '직접 구매',
                        buy_escrow: '에스크로 구매',
                        details: '세부',
                        contract_address: '컨트렉트 주소',
                        token_id: '토큰 ID',
                        token_standard: '토큰 표준',
                        blockchain: '블록체인',
                        event: '이벤트',
                        price: '가격',
                        from: '송신자',
                        to: '수신자',
                        date: '날짜',
                        price_history : '가격 내역',
                        last_7_days: '7일 전',
                        last_14_days: '14일 전',
                        last_30_days: '30일 전',
                        last_all: '총기간',
                        day_avg_price_90: '90일 평균 가격',
                        day_volume_90: '90일 거래량',
                        joined: '가입일 ',
                        subtotal: '합계',
                        item: '아이템',
                        facebook: '페이스북',
                        copy: '복사',
                        sale_in_progress: '에스크로 거래중',
                        buy_in_progress: '에스크로 거래중',
                        sell_now: '판매하기',
                        modify_now: '판매가격 변경',
                        cancel_now: '판매 취소',
                        cancel: '취소'
                    },
                    phrase: {
                        check_agree_1: `체크를 확인하고 우측 팝콘 약관에 동의합니다.`,
                        check_agree_2: `약관`,
                        check_escrow_agree: '선택 :  에스크로 판매 (판매 시 상품을 소유하고 있어, 오프라인 수령이 필요한 경우)'
                    },
                    header: {
                        explore: '탐색',
                        stats: '기록',
                        community: '커뮤니티',
                        create: 'NFT생성',
                        search: '검색',
                        rankings: '랭킹',
                        activity: '액티비티',
                        all_nft: 'All NFTs',
                        art: 'art',
                        vieagem: 'VIEAGEM',
                        staking: '스테이킹',
                        myrefferal:'나의 추천인'
                    },
                    profile_menu: {
                        balance: '잔액',
                        my_profile: '내 정보',
                        edit_profile: '내 정보 수정',
                        create_collection: '컬렉션 만들기',
                        referral: '리퍼럴',
                        p2p_purchase: 'CORN P2P 구매',
                        english: '영어',
                        korean: '한국어',
                        sign_out: '로그아웃',
                    },
                    referral: {
                        referral: '추천',
                        my_referral: '내 추천 코드',
                        copy_referral: '추천인 코드를 확인하고 복사하세요.',
                        enter_referral: '추천인 코드 입력',
                        enter_referral_2: '추천인 코드를 입력하고 추천인을 확인 후 등록하세요..',
                        registration: '등록',
                        reward_history: '보상 내역',
                        cumulative_reward: '총 누적 보상',
                        transaction_date: '거래날짜',
                        nickname: '닉네임',
                        transaction: '거래',
                        reward: '보상',
                        payment_date: '결제일',
                        email: '이메일',
                    },
                    profile_edit: {
                        username : '사용자 이름',
                        bio : '소개',
                        email : '이메일',
                        links : '링크',
                        wallet_address : '지갑주소',
                        update_profile : '프로필 저장',
                    },
                    main: {
                        hot_item: '핫아이템',//'Special Edition By 이은아',
                        featured_collections: '추천 콜렉션',
                        create_and_sell_your_ntfs: 'NFT 생성 및 판매',
                        setup_your_wallet: '1. 지갑 설정',
                        setup_your_wallet_desc: `선택한 지갑을 설정했으면 오른쪽 상단 모서리에 있는 "WALLET" 아이콘을 클릭하여 METAMASK에 연결합니다.`,
                        create_your_collection: '2. 컬렉션 만들기',
                        create_your_collection_desc: '만들기를 클릭하고 컬렉션을 설정합니다. 소셜 링크, 설명, 프로필 및 배너 이미지를 추가하고 2차 판매 수수료를 설정하세요.',
                        add_your_nfts: '3. NFT 추가하기',
                        add_your_nfts_desc: '작업(이미지, 비디오)을 업로드하고 제목과 설명을 추가하고 속성, 통계로 NFT를 사용자 정의하십시오.',
                        list_then_for_sale: '4. NFT 판매하기',
                        list_then_for_sale_desc: '경매, 고정 가격 목록 및 가격 하락 목록 중에서 선택하십시오. NFT 판매 방법을 선택하세요!',
                        join_mailing_list: '메일링 리스트에 가입하여 최신 기능 릴리스, NFT 드롭, PopcornNFT 탐색을 위한 팁과 요령을 받아보세요.',
                        subscribe: '구독',
                    },
                    footer: {
                        biological_elemental: '내가 사랑하는 이의 생체 원소 보석 NFT',
                        marketplace: '시장',
                        all_nfts: '모든 NFT',
                        company: '회사',
                        contact_us: '문의하기',
                        faq: '자주하는 질문',
                        my_account: '내계정',
                        authors: '내 프로필',
                        create_collection: '컬렉션 만들기',
                        author_profile: '프로필 편집',
                        create_item: '아이템 만들기',
                    },
                    explore: {
                        explore_collections: '컬렉션 탐색',
                        sorting_recent: '최근순',
                        sorting_volume: '보륨순',
                    },
                    category: {
                        all: '전체',
                        art: '미술',
                        photograph: '사진',
                        music: '음악',
                        video: '영상',
                        sports: '스포츠',
                        cyber_world: '사이버 월드',
                        celebrity: '유명인',
                    },
                    community: {
                        community: '커뮤니티',
                        community_desc: '홀더로 인증된 유저만, 커뮤니티에 참여할 수 있습니다.',
                    },
                    ranking: {
                        rankings: '순위',
                        rank: '순위',
                        collection: '콜렉션',
                        volume: '볼륨',
                        floor_price: '바닥가',
                        owners: '소유자',
                    },

                    listreferral: {
                        referral_title :'하위 리스트',
                        regno:'번호',
                        regdate: '등록일',
                        username: '유저네임',
                        email: '유저 이메일',
                        address: '유저 지갑',
                        referralname: '추천인 이름',
                        inviteecount: '산하 인원수',
                    },

                    activity: {
                        activity: '활동',
                        list_by: '상장',
                        minted_by: '발행',
                        transferred_from: '전송',
                    },
                    create_nft: {
                        create: '생성하기',
                        image_video: '이미지, 비디오',
                        image_video_desc: '업로드할 파일을 드래그하거나 선택하세요.',
                        name: '이름',
                        name_hint: '아이템 이름',
                        goods: '상품',
                        goods_desc: 'NFT에 상품이 포함되어 있는지 선택합니다.',
                        goods_included: '상품 포함',
                        goods_not_included: '상품이 없음',
                        external_link: '외부 링크',
                        external_link_desc: `사용자가 클릭하여 자세히 알아볼 수 있도록 이 항목의 세부 정보 페이지에 이 URL에 대한 링크를 포함할 것입니다. 자세한 내용이 포함된 자신의 웹페이지를 링크할 수 있습니다.`,
                        description: '설명',
                        description_desc: `설명은 해당 이미지 아래 항목의 세부 정보 페이지에 포함됩니다. Markdown 구문이 지원됩니다.`,
                        description_hint: '항목에 대한 자세한 설명을 제공하십시오.',
                        collection: '컬렉션',
                        collection_desc: '항목이 표시될 컬렉션입니다.',
                        collection_hint: '컬렉션을 선택하세요',
                        supply: '수량',
                        supply_desc: '생성할 수 있는 항목의 수입니다. 당신에게 가스 비용이 없습니다!',
                        blockchain: '블록체인',
                    },
                    contact_us: {
                        get_in_touch: '연락하기',
                        contact_us: '문의하기',
                        contact_us_desc: `문의가 있습니까? 도움이 필요합니까? 주저하지 말고 전화를 주십시오.`,
                        name: '이름',
                        email: 'Email',
                        message: '문의내용',
                        agree_to_terms: '서비스 약관에 동의합니다.',
                        submit: '제출',
                        information: '정보'
                    },
                    faq: {
                        freq_asked : '자주 묻는 질문',
                        freq_asked_about : 'PopcornNFT에 대해 자주 묻는 질문',
                        contact_support: '문의하기',
                    },
                    create_collection: {
                        create_collection: '컬렉션 만들기',
                        logo_image: '로고 이미지',
                        drag: "업로드할 파일을 드래그하거나 선택하세요.",
                        recommend_image: "300x300 이상의 이미지를 권장합니다. GIF도 작동합니다. 최대 5MB",
                        banner: "배너 이미지",
                        drag_choose: "업로드할 파일을 드래그하거나 선택하세요.",
                        banner_desc: `이 이미지는 컬렉션 페이지 상단에 나타납니다. 기기에 따라 크기가 변경되므로 이 배너 이미지에 너무 많은 텍스트를 포함하지 마세요. 1920*300 권장.`,
                        name: "이름",
                        collection_name: "컬렉션 이름",
                        url: "URL",
                        url_desc: `PopcornNFT에서 URL을 사용자 정의하십시오. 소문자, 숫자, 하이픈만 포함해야 합니다.`,
                        description: '설명',
                        royalty: `컬렉션 소유자는 사용자가 자신이 만든 아이템을 재판매할 때 수수료를 징수할 수 있습니다.`,
                        description_desc: `항목에 대한 자세한 설명을 제공하십시오.`,
                        creator_fee: "크리에이터 수수료",
                        creator_fee_desc: `컬렉션 소유자는 사용자가 자신이 만든 아이템을 재판매할 때 수수료를 징수할 수 있습니다. 수수료 비율 또는 지불 주소를 변경하려면 컬렉션 소유자에게 문의하십시오. 총 10%까지 백분율을 설정합니다.`,
                        category: "카테고리",
                        category_desc: "카테고리를 선택하세요.",
                        select_category: "카테고리를 선택",
                        links: "링크",
                        blockchain: "블록체인",
                        payment_token: "결제 토큰",
                        create: "생성하기",
                    },
                    userprofile_navigation: {
                        owned: '소유',
                        collections: '콜렉션',
                        activity: '활동',
                    },
                    staking:  {
                        staking_title: "POPCORN 스테이킹",
                        staking_subtitle: "보유 하고 있는 아이템으로 스테이킹을 신청하면 리워드 지급!",
                        staked_items: "스테이킹 중인 아이템",
                        if_stake_reward_given: "보유 하고 있는 아이템으로 스테이킹을 신청하면 리워드 지급!",
                        stake_date: "신청일",
                        cum_reward: "누적 리워드",
                        request_for_reward: "리워드 신청하기",
                        stakable_items: "스테이킹 가능 아이템",
                        staking_conditions: `스테이킹이 가능 한 아이템 중, 판매 등록이 안된 아이템만
              신청이가능합니다. 스테이킹이 가능한 아이템임에도 불구하고 표시가
              안된다면, 판매 등록 상태를 확인 바랍니다.`,
                        staking_participate: `스테이킹 참여`,
                        qna1: {
                            title: "POPCORN 스테이킹은 무엇인가요?",
                            description:
                                "스테이킹을 신청하고 매일 리워드를 신청을 하면, 일 보상률에 해당하는 수량을 리워드로 받을 수 있습니다.",
                        },
                        qna2: {
                            title: "참여의 조건은 어떻게 되나요?",
                            description: "스테이킹이 가능한 아이템을 구매를 하면, 참여할 수 있습니다.",
                        },
                        qna3: {
                            title: "리워드 지급 기준 및 시점은 어떻게 되나요?",
                            description:
                                "참여 후 월렛에서 매일 리워드 신청을 하면, 구매가의 년 12%를 일별 지급드립니다.",
                        },
                        qna4: {
                            title: "리워드 신청 방법은 어떻게되나요?",
                            description:
                                "리워드 신청하기를 누르면, 스테이킹 지갑으로 이동됩니다.<br>해당 리스트에서 신청하기는 누르면, 영상이 플레이가 되고, 시청이 완료되면 자동으로 지급됩니다.<br>매일 00시 기준으로 하루에 한번 리워드 신청이 가능합니다.",
                        },
                        qna5: {
                            title: "리워드는 무엇인가요?",
                            description:
                                "CORN POINT로 지급되며, 추후 미버스 기반의 CORN으로 스왑이 가능합니다.",
                        },
                    }
                },
            },
        },
    });

export default i18n;
