import React, { useEffect, useState } from "react";
import gradient_light from "../../img/gradient_light.jpg";
import item_single_large from "../../img/products/item_single_large.jpg";
import item_single_full from "../../img/products/item_single_full.jpg";

import { Link, useParams } from "react-router-dom";
import avatar_1 from "../../img/avatars/avatar_1.jpg";
import avatar_7 from "../../img/avatars/avatar_7.jpg";
import a1a from "../../img/chains/a1a.png";
import { ItemTabs } from "../../components/ItemTabs";
import { Offers } from "../../components/ItemTabs/Offers";
import { Properties } from "../../components/ItemTabs/Properties/Properties";
import { Details } from "../../components/ItemTabs/Details";
import { Activity } from "../../components/ItemTabs/Activity";
import { PriceHistory } from "../../components/ItemTabs/PriceHistory";
import { SmSwiper } from "../../components/SmSwiper";
import item_1 from "../../img/products/item_1.jpg";
import item_2 from "../../img/products/item_2.jpg";
import item_6 from "../../img/products/item_6.jpg";
import item_4 from "../../img/products/item_4.jpg";
import item_3 from "../../img/products/item_3.gif";
import item_5 from "../../img/products/item_5.jpg";
import { Modal } from "../../components/Modal";
import { ModalBodyBuy } from "../../components/ModalBodyBuy";
import { ItemDropMenu } from "../../components/ItemDropMenu";
import { proxy } from "valtio";
import AppState from "../../AppState";
import { GridNFT } from "../../components/GridNFT";
import apiClient from "../../apiClient";
import { SmItemSwiper } from "../../components/SmItemSwiper";
import { RESOURCE_BASE_URL } from "../../Constant";
import { ModalBodySell } from "../../components/ModalBodySell";
import { Share } from "../../components/UserMenu/Share";
import Tippy from "@tippyjs/react";
import { BuyModal } from "../../components/Modal/BuyModal";
import { EscrowBuyModal } from "../../components/Modal/EscrowBuyModal";
import { trimString } from "../../JsUtils";
import Loading from "react-fullscreen-loading";
import create from "zustand";
// import MetaTags from 'react-meta-tags';
import PersistentAppState from "../../PersistentAppState";
import { SellModal } from "../../components/Modal/SellModal";
import { Helmet } from "react-helmet-async";
import { ModifySellModal } from "../../components/Modal/ModifySellModal";
import { CancelSellModal } from "../../components/Modal/CancelSellModal";
import PriceLabel from "../../components/PriceLabel/PriceLabel";
import { useMetaMask } from "metamask-react";

import { useUserStore } from "../../useUserStore";
import { useTranslation } from "react-i18next";

function SalesOptions({saleStatus, saleType, isActualOwner, item
, guardMetamaskConnection
, setSellModalIsOpen
, setModifySellModalIsOpen
, setCancelSellModalIsOpen
, setBuyModalIsOpen
}) {
  const { t, i18n } = useTranslation();
  if(saleType === 'ESCROW') {
    // BUYING OR SELLING
    if(saleStatus === 'CANCELED' || !saleStatus) {
        if(isActualOwner) {
            return (
                <div className="pt-4 sm:flex sm:flex-wrap">
                    <a
                        onClick={() => {
                            if (guardMetamaskConnection()) {
                                return;
                            }
                            setSellModalIsOpen(true);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#placeBidModal"
                        className="shadow-green-volume hover:bg-green-dark inline-block w-full rounded-full bg-green py-3 px-8 text-center font-semibold text-white transition-all"
                    >
                        {t('common.sell_now')}
                    </a>
                </div>
            )
        } else {
            return (<div className="pt-4 sm:flex sm:flex-wrap">
                <a
                    onClick={() => {
                        if (guardMetamaskConnection()) {
                            return;
                        }
                        setBuyModalIsOpen(true);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#buyNowModal"
                    className="inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                    {t('common.buy_direct')}
                </a></div>)
        }
    } else if(saleStatus === 'WAIT_ESCROW' || saleStatus === 'ON_SALE') {
      return (
          <div className="pt-4 sm:flex sm:flex-wrap">
            <a
                data-bs-toggle="modal"
                className="inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
            >
              {
                (isActualOwner) ? t('common.buy_in_progress') : t('common.sale_in_progress')
              }
            </a>
          </div>
      )
    } else {
      //COMPLETE (NOT IN SALE)
      return <div/>
    }
  } else {
    if(saleStatus === 'ON_SALE') {
      if(isActualOwner) {
        // MODIFY OR CANCEL SALE
        return (
            <>
              <div className="pt-4 sm:flex sm:flex-wrap">
                  <a
                      onClick={() => {
                        if (guardMetamaskConnection()) {
                          return;
                        }
                        setModifySellModalIsOpen(true);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#placeBidModal"
                      className="shadow-green-volume hover:bg-green-dark inline-block w-full rounded-full bg-green py-3 px-8 text-center font-semibold text-white transition-all"
                  >
                    {t('common.modify_now')}
                  </a>
              </div>
              <div className="pt-4 sm:flex sm:flex-wrap">
                  <a
                      onClick={() => {
                        if (guardMetamaskConnection()) {
                          return;
                        }
                        setCancelSellModalIsOpen(true);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#placeBidModal"
                      className="shadow-green-volume hover:bg-green-dark inline-block w-full rounded-full bg-green py-3 px-8 text-center font-semibold text-white transition-all"
                  >
                    {t('common.cancel_now')}
                  </a>
              </div>
            </>
        )
      }
      else {
        // ABLE TO BUY
        // setBuyModalIsOpen
        return <div className="pt-4 sm:flex sm:flex-wrap">
          <a
              onClick={() => {
                if (guardMetamaskConnection()) {
                  return;
                }
                setBuyModalIsOpen(true);
              }}
              data-bs-toggle="modal"
              data-bs-target="#buyNowModal"
              className="inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
          >
            {t('common.buy_direct')}
          </a>
        </div>
      }
    } else if(saleStatus === 'CANCELED' || saleStatus === 'COMPLETED' || !saleStatus) {
        if(isActualOwner) {
            return (<div className="pt-4 sm:flex sm:flex-wrap">
                <a
                    onClick={() => {
                        if (guardMetamaskConnection()) {
                            return;
                        }
                        setSellModalIsOpen(true);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#placeBidModal"
                    className="shadow-green-volume hover:bg-green-dark inline-block w-full rounded-full bg-green py-3 px-8 text-center font-semibold text-white transition-all"
                >
                    {t('common.sell_now')}
                </a>
            </div>)
        } else {
            return ((<div className="pt-4 sm:flex sm:flex-wrap">
                <a
                    onClick={() => {
                        if (guardMetamaskConnection()) {
                            return;
                        }
                        setBuyModalIsOpen(true);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#buyNowModal"
                    className="inline-block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                    {t('common.buy_direct')}
                </a></div>))
        }
    } else {
      return <div/>
    }
  }
}

const ItemDetail = ({ onUpdateBalance, setModalIsOpen }) => {
  const { t, i18n } = useTranslation();
  const state = proxy(AppState);
  const balance = useUserStore((state) => state.balance);
  const userdata = useUserStore((state) => state.userdata);
  const {
    status,
    connect,
    account,
    chainId,
    ethereum,
    switchChain,
    addChain,
    AddEthereumChainParameter,
  } = useMetaMask();

  const { itemId } = useParams();
  const [activeTab, setActiveTab] = useState("details");
  const [buyModalIsOpen, setBuyModalIsOpen] = useState(false);
  const [escrowBuyModalIsOpen, setEscrowBuyModalIsOpen] = useState(false);
  const [sellModalIsOpen, setSellModalIsOpen] = useState(false);
  const [modifySellModalIsOpen, setModifySellModalIsOpen] = useState(false);
  const [cancelSellModalIsOpen, setCancelSellModalIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [favoriteIsMark, setFavoriteIsMark] = useState(false);
  const [favoriteCounting, setFavoriteCounting] = useState(0);
  const [shareIsOpen, setShareIsOpen] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [isForceRefresh, setForceRefresh] = useState(false);

  const [item, setItem] = useState(null);
  const [moreCollectionAssets, setMoreCollectionAssets] = useState([]);
  const [activities, setActivities] = useState([]);

  function share() {
    setShareIsOpen((prev) => !prev);
    setMenuIsOpen(false);
  }

  const refreshPage = () => {
    if (onUpdateBalance) {
      onUpdateBalance();
    }
    window.location.reload();
  };

  async function doFavorites(flag, entityType, entityId) {
    const path = flag ? `social/createFavorites` : `social/destroyFavorites`;
    const { data: createFavoritesData } = await apiClient.post(path, {
      entityType,
      entityId,
    });
    console.log(`doFavorites = ${JSON.stringify(createFavoritesData)}`);
  }

  const guardMetamaskConnection = () => {
    if (status === "unavailable" || status === "notConnected") {
      setModalIsOpen(true);
      return true;
    }
    return false;
  };

  useEffect(() => {
    (async () => {
      // TODO: replace itemID to address

      const { data: itemData } = await apiClient.get(
        `blockchain/collectionAsset/${itemId}`
      );
      console.log(`itemData = ${JSON.stringify(itemData)}`);
      const {
        collectionAsset: _i,
        moreCollectionAssets: _m,
        activities,
      } = itemData;
      setItem(_i);
      setMoreCollectionAssets(_m);
      setActivities(activities);

      setFavoriteIsMark(_i.isFavoriteYn === "Y");
      setFavoriteCounting(_i.favoriteCount);

      setPageLoading(false);

      console.log(`item = ${JSON.stringify(_i)}`);
      // console.log(`userdata.address = ${userdata.address}`);
    })();
  }, [isForceRefresh]);

  return (
    <div className="wrapper">
      <Helmet>
        <title>{item && item.name}</title>
        <meta name="description" content={item && item.description} />
        <meta property="og:title" content={item && item.name} />
        <meta property="og:image" content={item && item.imageUrl} />
      </Helmet>

      <main className="mt-24">
        {buyModalIsOpen && (
          <BuyModal
            setModalIsOpen={setBuyModalIsOpen}
            item={item}
            styledWrapper="modal-dialog max-w-2xl"
            setPageLoading={setPageLoading}
            setForceRefresh={setForceRefresh}
            refreshPage={refreshPage}
            balance={balance}
          />
        )}
        {escrowBuyModalIsOpen && (
          <EscrowBuyModal
            setModalIsOpen={setEscrowBuyModalIsOpen}
            item={item}
            styledWrapper="modal-dialog max-w-2xl"
            setPageLoading={setPageLoading}
            setForceRefresh={setForceRefresh}
            refreshPage={refreshPage}
            balance={balance}
          />
        )}
        {sellModalIsOpen && (
          <SellModal
            setModalIsOpen={setSellModalIsOpen}
            item={item}
            styledWrapper="modal-dialog max-w-2xl"
            setPageLoading={setPageLoading}
            setForceRefresh={setForceRefresh}
            refreshPage={refreshPage}
            balance={balance}
          />
        )}
        {modifySellModalIsOpen && (
          <ModifySellModal
            setModalIsOpen={setModifySellModalIsOpen}
            item={item}
            styledWrapper="modal-dialog max-w-2xl"
            setPageLoading={setPageLoading}
            setForceRefresh={setForceRefresh}
            refreshPage={refreshPage}
            balance={balance}
          />
        )}
        {cancelSellModalIsOpen && (
          <CancelSellModal
            setModalIsOpen={setCancelSellModalIsOpen}
            item={item}
            styledWrapper="modal-dialog max-w-2xl"
            setPageLoading={setPageLoading}
            setForceRefresh={setForceRefresh}
            refreshPage={refreshPage}
            balance={balance}
          />
        )}

        <section className="relative pt-12 pb-24 lg:py-24">
          {/*{status}*/}
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img src={gradient_light} alt="gradient" className="h-full" />
          </picture>
          <div className="container">
            <div className="md:flex md:flex-wrap">
              <figure className="mb-8 md:w-2/5 md:flex-shrink-0 md:flex-grow-0 md:basis-auto lg:w-1/2">
                {/*item_single_large*/}
                <img
                  src={item && item.imageUrl}
                  alt="item"
                  className="cursor-pointer rounded-2.5xl"
                  data-bs-toggle="modal"
                  data-bs-target="#imageModal"
                />

                {/*Modal*/}
                <div
                  className="modal fade"
                  id="imageModal"
                  tabIndex="-1"
                  aria-labelledby="buyNowModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog !my-0 flex h-full items-center justify-center p-4">
                    {/*item_single_full*/}
                    <img src={item && item.imageUrl} alt="item" />
                  </div>

                  <button
                    type="button"
                    className="btn-close absolute top-6 right-6"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-6 w-6 fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                    </svg>
                  </button>
                </div>
              </figure>

              {/*Details*/}
              <div className="md:w-3/5 md:basis-auto md:pl-8 lg:w-1/2 lg:pl-[3.75rem]">
                <div className="mb-3 flex">
                  {/*Collection*/}
                  <div className="flex items-center">
                    <a
                      href={item && `/collection/${item.collectionId}`}
                      className="mr-2 text-sm font-bold text-accent"
                    >
                      {item && item.collectionTitle}
                    </a>
                  </div>

                  {/*Likes / Actions*/}

                  <div className="ml-auto flex space-x-2">
                    <div className="flex items-center space-x-1 rounded-xl border border-jacarta-100 bg-white py-2 px-4 dark:border-jacarta-600 dark:bg-jacarta-700">
                      <span
                        onClick={async () => {
                          const newFlag = !favoriteIsMark;
                          await doFavorites(
                            newFlag,
                            "collectionAssets",
                            item.id
                          );
                          setFavoriteIsMark((prevState) => !prevState);

                          const delta = newFlag ? 1 : -1;
                          setFavoriteCounting(
                            (prevState) => parseInt(prevState) + delta
                          );
                        }}
                        className="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4  before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                        data-tippy-content="Favorite"
                      >
                        {favoriteIsMark ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            fill="#EF4444"
                            className="h-4 w-4"
                          >
                            <path fill="none" d="M0 0H24V24H0z" />
                            <path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="h-4 w-4 fill-jacarta-500 hover:fill-red dark:fill-jacarta-200 dark:hover:fill-red"
                          >
                            <path fill="none" d="M0 0H24V24H0z"></path>
                            <path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z"></path>
                          </svg>
                        )}
                      </span>
                      <span className="text-sm dark:text-jacarta-200">
                        {favoriteCounting}
                      </span>
                    </div>

                    {/*Actions*/}
                    <Tippy content="Favorite">
                      <div
                        className="dropdown relative rounded-xl border border-jacarta-100 bg-white hover:bg-jacarta-100
                dark:border-jacarta-600 dark:bg-jacarta-700 dark:hover:bg-jacarta-600"
                      >
                        <Link
                          onClick={() => share()}
                          to="#"
                          className="dropdown-toggle  inline-flex h-10 w-10 items-center justify-center text-sm"
                          role="button"
                          id="collectionShare"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-tippy-content="Share"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="h-4 w-4 fill-jacarta-500 dark:fill-jacarta-200"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M13.576 17.271l-5.11-2.787a3.5 3.5 0 1 1 0-4.968l5.11-2.787a3.5 3.5 0 1 1 .958 1.755l-5.11
                            2.787a3.514 3.514 0 0 1 0 1.458l5.11 2.787a3.5 3.5 0 1 1-.958 1.755z"
                            />
                          </svg>
                        </Link>
                        {shareIsOpen && (
                          <Share
                            linkUrl={
                              window && window.location && window.location.href
                            }
                          />
                        )}
                      </div>
                    </Tippy>
                  </div>
                </div>

                <h1 className="mb-4 font-display text-4xl font-semibold text-jacarta-700 dark:text-white">
                  {item && item.name}
                </h1>

                <p className="mb-10 dark:text-jacarta-300">
                  {item && item.description}
                </p>

                {/*Creator / Owner*/}
                <div className="mb-8 flex flex-wrap">
                  <div className="mr-8 mb-4 flex">
                    <figure className="mr-4 shrink-0">
                      <a
                        href={item && `/user/${item.creatorAddress}`}
                        className="relative block"
                      >
                        <img
                          src={
                            item && item.creatorImageUrl
                              ? RESOURCE_BASE_URL + item.creatorImageUrl
                              : avatar_1
                          }
                          alt="avatar 7"
                          className="h-12 w-12 rounded-2lg"
                          loading="lazy"
                        />
                      </a>
                    </figure>
                    <div className="flex flex-col justify-center">
                      <span className="block text-sm text-jacarta-400 dark:text-white">
                        {t("common.creator")}{" "}
                        <strong>0% {t("common.royalties")}</strong>
                      </span>
                      <a
                        href={item && `/user/${item.creatorAddress}`}
                        className="block text-accent"
                      >
                        <span className="text-sm font-bold">
                          {item &&
                            `@${
                              item.creatorUsername
                                ? item.creatorUsername
                                : trimString(item.creatorAddress, 5)
                            }`}
                        </span>
                      </a>
                    </div>
                  </div>

                  <div className="mb-4 flex">
                    <figure className="mr-4 shrink-0">
                      <a
                        href={
                          item &&
                          `/user/${
                            item.userAddress
                              ? item.userAddress
                              : item.actualOwnerAccountAddress
                          }`
                        }
                        className="relative block"
                      >
                        <img
                          src={
                            item && item.userImageUrl
                              ? RESOURCE_BASE_URL + item.userImageUrl
                              : avatar_1
                          }
                          alt="avatar 1"
                          className="h-12 w-12 rounded-2lg"
                          loading="lazy"
                        />
                      </a>
                    </figure>
                    <div className="flex flex-col justify-center">
                      <span className="block text-sm text-jacarta-400 dark:text-white">
                        {t("common.owned_by")}
                      </span>
                      <a
                        href={
                          item &&
                          `/user/${
                            item.userAddress
                              ? item.userAddress
                              : item.actualOwnerAccountAddress
                          }`
                        }
                        className="block text-accent"
                      >
                        <span className="text-sm font-bold">
                          {item &&
                            `@${
                              item.username
                                ? item.username
                                : trimString(
                                    item.userAddress
                                      ? item.userAddress
                                      : item.actualOwnerAccountAddress,
                                    5
                                  )
                            }`}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>

                {/*Bid*/}
                {item && (
                    <div
                        className="rounded-2lg border border-jacarta-100 bg-white p-8 dark:border-jacarta-600 dark:bg-jacarta-700">
                      {item.price && (
                          <div className="mb-8 text-center">
                            {/*Highest bid*/}
                            <div className="mt-3 text-center">
                              <div className="text-center">
                            <span className="mr-1 text-4xl font-medium leading-tight tracking-tight text-green">
                              {item && Number(item.price).toLocaleString("en")}
                            </span>
                                <span className="text-4xl font-medium leading-tight tracking-tight text-green">
                              CORN
                            </span>
                                <PriceLabel
                                    tokenPrice={item.price}
                                    currency={"usd"}
                                />
                              </div>
                            </div>
                            {/*Countdown*/}
                          </div>
                      )}

                      <SalesOptions
                        saleStatus={item && item.saleStatus}
                        saleType={item &&item.saleType}
                        isActualOwner={item && item.actualOwnerAccountAddress && item.actualOwnerAccountAddress === userdata.address}
                        item={item}
                        guardMetamaskConnection={guardMetamaskConnection}
                        setSellModalIsOpen={setSellModalIsOpen}
                        setModifySellModalIsOpen={setModifySellModalIsOpen}
                        setCancelSellModalIsOpen={setCancelSellModalIsOpen}
                        setBuyModalIsOpen={setBuyModalIsOpen}
                      />
                    </div>
                )}
              </div>
            </div>

            <div className="scrollbar-custom mt-14 overflow-x-auto rounded-lg">
              <div className="min-w-fit">
                <ItemTabs setActiveTab={setActiveTab} />
                <div className="tab-content">
                  {/*{activeTab === 'offers' && <Offers />}*/}
                  {/*{activeTab === 'properties' && <Properties />}*/}
                  {activeTab === "details" && (
                    <Details
                      contractAddress={item && item.nftAddress}
                      tokenId={item && item.tokenId}
                    />
                  )}
                  {activeTab === "activity" && (
                    <Activity activities={activities} />
                  )}
                  {activeTab === "priceHistory" && (
                    <PriceHistory itemId={item && item.id} />
                  )}
                </div>
              </div>
            </div>

            {item && item.detailImagePath && (
              <img
                src={item && RESOURCE_BASE_URL + item.detailImagePath}
                className="w-full"
                alt="item"
              />
            )}
          </div>
        </section>

        <section className="bg-light-base py-24 dark:bg-jacarta-800">
          <div className="container">
            <h2 className="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white">
              More from this items
            </h2>
            <SmItemSwiper data={moreCollectionAssets} />
          </div>
        </section>

        {isPageLoading ? (
          <Loading loading background="#DFDFDF80" loaderColor="#9E7CFF" />
        ) : null}
      </main>
    </div>
  );
};

export { ItemDetail };
