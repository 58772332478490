import React from 'react';
import {useTranslation} from "react-i18next";

const TableTitle = () => {
    const { t, i18n } = useTranslation();
    return (
    <div className='flex rounded-t-2lg bg-jacarta-50 dark:bg-jacarta-600' role='row'>
        <div className='w-[16.6%] py-3 px-4' role='columnheader'>
        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
          {t('ranking.rank')}
        </span>
        </div>
      <div className='w-[16.6%] py-3 px-4' role='columnheader'>
        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
          {t('ranking.collection')}
        </span>
      </div>
      <div className='w-[16.6%] py-3 px-4' role='columnheader'>
        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
          {t('ranking.volume')}
        </span>
      </div>
      <div className='w-[16.6%] py-3 px-4' role='columnheader'>
        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
          {t('ranking.floor_price')}
        </span>
      </div>
      <div className='w-[16.6%] py-3 px-4' role='columnheader'>
        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
          {t('ranking.owners')}
        </span>
      </div>
      <div className='w-[16.6%] py-3 px-4' role='columnheader'>
        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
          {t('common.items')}
        </span>
      </div>
    </div>
  );
};

export {TableTitle};
