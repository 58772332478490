import React from 'react';
import { TableElement } from './TableElement/TableElement';
import avatar_1 from '../../img/avatars/avatar_1.jpg';
import avatar_2 from '../../img/avatars/avatar_2.jpg';
import avatar_3 from '../../img/avatars/avatar_3.jpg';
import avatar_4 from '../../img/avatars/avatar_4.jpg';
import avatar_5 from '../../img/avatars/avatar_5.jpg';
import avatar_6 from '../../img/avatars/avatar_6.jpg';
import avatar_7 from '../../img/avatars/avatar_7.jpg';
import { TableTitle } from './TableTitle';

const RankingTable = ({rankings}) => {
  return (
    <div
      role='table'
      className='w-full min-w-[736px] border border-jacarta-100 bg-white text-sm dark:border-jacarta-600
			dark:bg-jacarta-700 dark:text-white lg:rounded-2lg'>
      <TableTitle />

      {rankings.map((tableElement, index) => {
        return (
          <TableElement
            key={index+1}
            url={tableElement.url}
            alt={tableElement.alt}
            order={index+1}
            title={tableElement.collectionTitle}
            volume={tableElement.volumeTraded}
            // day={tableElement.day}
            // week={tableElement.week}
            floorPrice={tableElement.floorPrice}
            owners={tableElement.actualOwnerCount}
            items={tableElement.collectionAssetsCount}
            // verified={true}
          />
        );
      })}
    </div>
  );
};

export { RankingTable };
