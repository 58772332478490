import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import $ from "jquery";
import {useMutation} from "react-query";
import apiClient from "../../apiClient";

const FileInput = ({onImageUploaded}) => {
    const { t, i18n } = useTranslation();

    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const {isLoading, isError, status, mutate} = useMutation(newTodo => {
        return apiClient.post('/upload/nftImage', newTodo, {
            // headers: { Authorization: `Bearer ${accessToken}` },
        })
    });

    const changeHandler = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setIsFilePicked(true);
        const formData = new FormData();
        formData.append("uploadedImage", file);
        mutate(formData, {
            onSuccess: ({data}) => {
                const {uploadFileId, filePath} = data;
                if(onImageUploaded) {
                    onImageUploaded(uploadFileId, filePath);
                }
                // {"result":"ok","filePath":"xxxxxx/20221219062902292.jpg","uploadFileId":2}
                // console.log(`data = ${JSON.stringify(data)}`);
            },
            onFail: (e) => {
                alert('v1/upload/nftImage failed')
            }
        });

        var reader = new FileReader();
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function() {
                // access image size here
                //alert(this.width);
                inputRef.current.src = e.target.result;
                inputRef.current['object-fit'] = 'fit';

                // $('#blah')
                //     .attr('src', e.target.result)
                //     .attr('object-fit', 'fit');
                // .width(200)
                // .height(200);
                //$('#imgresizepreview, #profilepicturepreview').attr('src', this.src);
            };
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    return (
        <div
            className='group relative flex max-w-md flex-col items-center justify-center rounded-lg border-2 border-dashed
                border-jacarta-100 bg-white py-20 px-5 text-center dark:border-jacarta-600 dark:bg-jacarta-700'>
            {
                !isFilePicked && (<div className='relative z-10 cursor-pointer'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width='24'
                        height='24'
                        className='mb-4 inline-block fill-jacarta-500 dark:fill-white'>
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path
                            d='M16 13l6.964 4.062-2.973.85 2.125 3.681-1.732 1-2.125-3.68-2.223 2.15L16 13zm-2-7h2v2h5a1 1 0 0 1 1
                      1v4h-2v-3H10v10h4v2H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4
                      0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z'
                        />
                    </svg>
                    <p className='mx-auto max-w-xs text-xs dark:text-jacarta-300' id="file_info">
                        JPG, PNG, GIF, SVG, MP4, WEBM. Max size: 100 MB
                    </p>
                    {/*{isFilePicked ? (
                      <div>
                        <p className='mx-auto max-w-xs text-xs dark:text-jacarta-300' id="file_info">Filename: {selectedFile.name}</p>
                        <p className='mx-auto max-w-xs text-xs dark:text-jacarta-300' id="file_info">Filetype: {selectedFile.type}</p>
                        <p className='mx-auto max-w-xs text-xs dark:text-jacarta-300' id="file_info">Size in bytes: {selectedFile.size}</p>
                        <p className='mx-auto max-w-xs text-xs dark:text-jacarta-300' id="file_info">
                          lastModifiedDate:{' '}
                          {selectedFile.lastModifiedDate.toLocaleDateString()}
                        </p>
                      </div>
                    ) : (
                      <p></p>
                    )}*/}
                </div>)
            }
            <input
                onChange={changeHandler}
                type='file'
                accept='image/*,video/*,audio/*,webgl/*,.glb,.gltf'
                id='file-upload'
                name='file'
                className='absolute inset-0 z-20 cursor-pointer opacity-0'
                required
            />
            {/* aspect-[4/3] */}
            <div
                className='inset-4 cursor-pointer rounded bg-jacarta-50
                  dark:bg-jacarta-600' >
                <img ref={inputRef} id="blah" src="#" alt="" />
            </div>
        </div>
  );
};

export { FileInput };
