let trimString = function (string, length) {
    if(!string) {
        return '';
    }
    return string.length > length ?
        string.substring(0, length) + '...' :
        string;
};

export {trimString};
