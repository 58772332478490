import React, { useEffect, useState } from "react";
import banner from "../../img/user/banner.jpg";
import user_avatar from "../../img/user/user_avatar.gif";
import gradient_light from "../../img/gradient_light.jpg";
import { PageTitle } from "../../components/PageTitle";
import { GridNFT } from "../../components/GridNFT";
import item_5 from "../../img/products/item_5.jpg";
import item_4 from "../../img/products/item_4.jpg";
import item_7 from "../../img/products/item_7.jpg";
import item_6 from "../../img/products/item_6.jpg";
import item_8 from "../../img/products/item_8.jpg";
import item_9 from "../../img/products/item_9.jpg";
import item_10 from "../../img/products/item_10.jpg";
import item_11 from "../../img/products/item_11.gif";
import { UserNavigation } from "../../components/UserNavigation";
import { CopyLink } from "../../components/CopyLink";
import { Activity } from "../../components/Activity";
import { UserFilters } from "../../components/UserFilters";
import { UserMenu } from "../../components/UserMenu";
import { proxy, useSnapshot } from "valtio";
import AppState from "../../AppState";
import { Modal } from "../../components/Modal";
import { HeaderModal } from "../../components/HeaderModal";
import apiClient from "../../apiClient";
import { useParams } from "react-router-dom";
import create from "zustand";
import { persist } from "zustand/middleware";
import PersistentAppState from "../../PersistentAppState";
import { RESOURCE_BASE_URL } from "../../Constant";
import moment from "moment";
import "moment/locale/ko";
import { UserProfileNavigation } from "../../components/UserProfileNavigation";
import { CollectionElement } from "../../components/CollectionElement";
import { useUserStore } from "../../useUserStore";
import { useTranslation } from "react-i18next";

const UserProfile = () => {
  const { t, i18n } = useTranslation();
  const { address } = useParams();
  const userdata = useUserStore((state) => state.userdata);
  const state = proxy(AppState);
  const snap = useSnapshot(state);
  const [profile, setProfile] = useState({});
  const [collectionAssets, setCollectionAssets] = useState([]);
  const [collections, setCollections] = useState([]);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    (async () => {
      // const {address} = userdata;
      const { data: userdata } = await apiClient.get(`user/profile/${address}`);
      console.log(`userdata = ${JSON.stringify(userdata)}`);
      const { profile, collectionAssets, collections, activities } = userdata;
      // alert(profile.favoriteCount);
      // console.log(`profile = ${profile.isFavoriteYn}`);
      //{"address":"0xc340586456a7d66f2c83e0fdf6eb30262492e896","username":"121ghj","profileFilePath":"0xc340586456a7d66f2c83e0fdf6eb30262492e896/202212221425332533.jpg","bio":"hj","email":"ghj","links":{"twitter":"","instagram":"","homepage":""}}
      setProfile({ ...profile });
      setCollectionAssets(collectionAssets);
      setCollections(collections);
      setActivities(activities);
    })();
  }, []);

  const [activeWindow, setActiveWindow] = useState("owned");
  return (
    <main className="pt-[5.5rem] lg:pt-24">
      <div className="relative">
        {/*bannerImageURI*/}
        <img
          src={
            profile && profile.bannerImageURI
              ? RESOURCE_BASE_URL + profile.bannerImageURI
              : banner
          }
          className="cover-full-width h-[18.75rem] object-cover"
          alt="banner"
        />
      </div>
      <section className="relative bg-light-base pb-12 pt-28 dark:bg-jacarta-800">
        <div className="absolute left-1/2 top-0 z-10 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
          <figure className="relative">
            {profile && profile.profileFilePath ? (
              <img
                src={profile && RESOURCE_BASE_URL + profile.profileFilePath}
                alt="collection avatar"
                className="h-36 w-36 rounded-xl border-[5px] border-white dark:border-jacarta-600"
                width="160"
                height="160"
              />
            ) : (
              <img
                src={user_avatar}
                alt="collection avatar"
                className="h-36 w-36 rounded-xl border-[5px] border-white dark:border-jacarta-600"
              />
            )}
          </figure>
        </div>
        <div className="container">
          <div className="text-center">
            <PageTitle title={profile && profile.username} />
            <CopyLink walletAddress={address} />
            <p className="mx-auto mb-2 max-w-xl text-lg dark:text-jacarta-300">
              {profile && profile.bio}
            </p>
            <span className="text-jacarta-400">
              {" "}
              {profile &&
                t("common.joined") +
                  " " +
                  moment(profile.createdAt)
                    .lang(i18n.resolvedLanguage === "kr" ? "ko" : "en")
                    .format("MMMM YYYY")}
            </span>
          </div>
        </div>
        <UserMenu
          entityType={"users"}
          entityId={profile && profile.userId}
          initialFlag={profile && profile.isFavoriteYn === "Y"}
          linkUrl={window.location.href}
          favoriteCount={profile && profile.favoriteCount}
        />
      </section>

      {
        <section className="relative py-24 pt-20">
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img
              src={gradient_light}
              alt="gradient"
              className="h-full w-full"
            />
          </picture>

          <div className="container">
            <UserProfileNavigation setActiveWindow={setActiveWindow} />

            {activeWindow === "collections" && <UserFilters />}
            {activeWindow === "collections" && (
              <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
                {collections &&
                  collections.map((item, index) => (
                    <CollectionElement item={item} key={index} />
                  ))}
              </div>
            )}
            {activeWindow === "owned" && <UserFilters />}
            {activeWindow === "owned" && (
              <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
                {collectionAssets &&
                  collectionAssets.map((item, index) => (
                    <GridNFT
                      key={index}
                      item={item}
                      itemName={item.name}
                      price={item.price}
                      imageUrl={item.imageUrl}
                      id={item.id}
                      hash={item.hash}
                      favoriteFlag={item.isFavoriteYn === "Y"}
                      favoriteCount={item.favoriteCount}
                      saleStatus={item.saleStatus}
                    />
                  ))}
              </div>
            )}
            {activeWindow === "activity" && (
              <Activity activities={activities} />
            )}
          </div>
        </section>
      }
    </main>
  );
};

export { UserProfile };
