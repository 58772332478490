import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import collection_ava_1 from '../../../img/avatars/collection_ava_1.png';
import collection_ava_2 from '../../../img/avatars/collection_ava_2.jpg';
import collection_ava_3 from '../../../img/avatars/collection_ava_3.png';
import collection_ava_4 from '../../../img/avatars/collection_ava_4.jpg';
import collection_ava_5 from '../../../img/avatars/collection_ava_5.png';
import collection_ava_6 from '../../../img/avatars/collection_ava_6.jpg';
import collection_ava_7 from '../../../img/avatars/collection_ava_7.png';
import {useTranslation} from "react-i18next";

const CreateCollectionSortingMenu = ({onClick, setCollectionSortingIsOpen}) => {
    const { t, i18n } = useTranslation();

    const checkedView = () => {
        return (<svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-accent h-4 w-4"
        >
            <path fill="none" d="M0 0h24v24H0z"/>
            <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"/>
        </svg>)
    }

    const [sorting, setSorting] = useState('');
    const [rerender, setRerender] = useState(false);

    const handleVolumeClick = event => {
        event.stopPropagation();
        setSorting('');
        setSorting('Volume');

        onClick('Volume')
        setCollectionSortingIsOpen(false);
    };

    const handleRecentClick = event => {
        setSorting('Recent');

        event.stopPropagation();
        onClick('Recent')
        setCollectionSortingIsOpen(false);
        setRerender(prevState => !prevState);
    };

    useEffect(() => {
        // console.log(`sorting = '${sorting}`);
    }, [sorting, rerender]);

  return (
      <div
          className="dropdown-menu dark:bg-jacarta-800 z-10 w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl absolute"
          aria-labelledby="categoriesSort"
      >
          <button
              onClick={handleRecentClick}
              className="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
          >
              {t('explore.sorting_recent')}
              {(sorting === 'Recent') ? checkedView() : null}

          </button>
          <button
              onClick={handleVolumeClick}
              className="dropdown-item font-display dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
          >
              {t('explore.sorting_volume')}
              {(sorting === 'Volume') ? checkedView() : null}
          </button>
      </div>
  );
};

export { CreateCollectionSortingMenu };
