import React from 'react';
import { SmSwiper } from '../SmSwiper';

const FeaturedCollection = ({data}) => {
  return (
    <section className=''>
      <div className='container'>
        <SmSwiper data={data} />
      </div>
    </section>
  );
};

export { FeaturedCollection };
