import React from 'react';
import {useTranslation} from "react-i18next";

const TableTitle = () => {
    const { t, i18n } = useTranslation();
    return (
    <div className='flex rounded-t-2lg bg-jacarta-50 dark:bg-jacarta-600' role='row'>
      <div className='w-[20%]  referral_td py-3 px-4' role='columnheader'>
        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
          {t('listreferral.regno')}
        </span>     
      </div>
      <div className='w-[20%] referral_td py-3 px-4' role='columnheader'>
        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
          {t('listreferral.regdate')}
        </span>
      </div>      
      <div className='w-[20%] referral_td py-3 px-4' role='columnheader'>
        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
          {t('listreferral.username')}
        </span>
      </div>
 

      <div className='w-[20%] referral_td py-3 px-4' role='columnheader'>
        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
          {t('listreferral.referralname')}
        </span>
      </div>
      <div className='w-[20%] referral_td py-3 px-4' role='columnheader'>
        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
          {t('listreferral.inviteecount')}
        </span>
      </div>
    </div>
  );
};
export {TableTitle};
