import React from 'react';
import gradient_light from '../../img/gradient_light.jpg';
import step_01 from '../../img/chains/step_01.svg';
import step_02 from '../../img/chains/step_02.svg';
import step_03 from '../../img/chains/step_03.svg';
import step_04 from '../../img/chains/step_04.svg';
import {useTranslation} from "react-i18next";


const CreateAndSell = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className='dark:bg-jacarta-900 relative py-16'>
      <picture className='pointer-events-none absolute inset-0 -z-10 dark:hidden'>
        {/*<img src={gradient_light} alt='gradient' className='h-full' />*/}
      </picture>
      <div className='container'>
        <h2 className='mb-16 text-center font-display text-3xl text-jacarta-700 dark:text-white'>
          {t('main.create_and_sell_your_ntfs')}
        </h2>
        <div className='grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-4'>
          <div className='text-center'>
            <div className="mb-6 inline-flex">
              <img src={step_01} width="70" height="70"/>
            </div>
            <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
              {t('main.setup_your_wallet')}
            </h3>
            <p className='dark:text-jacarta-300'>
              {t('main.setup_your_wallet_desc')}
            </p>
          </div>
          <div className='text-center'>
            <div className="mb-6 inline-flex">
              <img src={step_02} width="70" height="70"/>
            </div>
            <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
              {t('main.create_your_collection')}
            </h3>
            <p className='dark:text-jacarta-300'>
              {t('main.create_your_collection_desc')}
            </p>
          </div>
          <div className='text-center'>
            <div className="mb-6 inline-flex">
              <img src={step_03} width="70" height="70"/>
            </div>
            <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
              {t('main.add_your_nfts')}
            </h3>
            <p className='dark:text-jacarta-300'>
              {t('main.add_your_nfts_desc')}
            </p>
          </div>
          <div className='text-center'>
            <div className="mb-6 inline-flex">
              <img src={step_04} width="70" height="70"/>
            </div>
            <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
              {t('main.list_then_for_sale')}
            </h3>
            <p className='dark:text-jacarta-300'>
              {t('main.list_then_for_sale_desc')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export { CreateAndSell };
