const REACT_APP_CONTRACT_SALE = `${process.env.REACT_APP_CONTRACT_SALE}`;
const REACT_APP_CONTRACT_CREATOR = `${process.env.REACT_APP_CONTRACT_CREATOR}`;
const REACT_APP_CONTRACT_NFT_STAKING = `${process.env.REACT_APP_CONTRACT_NFT_STAKING}`;
const REACT_APP_METADATA_URL = `${process.env.REACT_APP_METADATA_URL}`;
const REACT_APP_ADMIN_ADDRESS = `${process.env.REACT_APP_ADMIN_ADDRESS}`;
console.log(`process.env.REACT_APP_CONTRACT_SALE = ${REACT_APP_CONTRACT_SALE}`);

export const constants = {
  SALE: REACT_APP_CONTRACT_SALE, //  "0x635774529bf1E0ae871780C4fAb42D76D558c45F"
  CREATOR: REACT_APP_CONTRACT_CREATOR,
  NFT_STAKING: REACT_APP_CONTRACT_NFT_STAKING,
  ADMIN_ADDRESS: REACT_APP_ADMIN_ADDRESS,
  METADATA_URL: REACT_APP_METADATA_URL,
};
