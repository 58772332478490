import React from "react";
import { Modal } from "./Modal";
import { ModalBodyBuy } from "../ModalBodyBuy";
import { ethers } from "ethers";
import { saleAbi } from "../../blockchain/saleAbi";
import { erc20Abi } from "../../blockchain/erc20Abi";
import { cornAbi } from "../../blockchain/cornAbi";
import { constants } from "../../blockchain/constants";
import { currencyById } from "../../blockchain/currencies";
import apiClient from "../../apiClient";
import {useTranslation} from "react-i18next";
const BuyModal = ({
  setModalIsOpen,
  item,
  styledWrapper,
  setPageLoading,
  setForceRefresh,
  refreshPage,
  balance,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      setModalIsOpen={setModalIsOpen}
      title={t('common.buy')}
      btnTitle="Buy now"
      childrenBody={<ModalBodyBuy item={item} balance={balance} />}
      styledWrapper={styledWrapper}
      onClick={async function () {
        try {
          const checkbox = document.getElementById("buyNowTerms");
          if (checkbox && !checkbox["checked"]) {
            alert("서비스 약관에 동의해주세요.");
            return;
          }
          // we must not render on sale
          if (item.saleStatus !== "ON_SALE") {
            alert("판매중이 아닙니다.");
            return;
          }

          console.log("item");

          const ethereum = window["ethereum"];
          if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const signerAddress = await signer.getAddress();

            const priceBn = ethers.utils.parseEther(item.price);
            const currencyAddress = currencyById(item.currencyId);
            const erc20Contract = new ethers.Contract(
              currencyAddress,
              erc20Abi,
              signer
            );

            const saleContract = new ethers.Contract(
              constants.SALE,
              saleAbi,
              signer
            );
            if (signerAddress.toLocaleLowerCase() === item.userAddress) {
              alert("본인의 NFT입니다.");
              return;
            }

            if (item.currencyId === 1) {
              const userBalance = await ethereum.request({
                method: "eth_getBalance",
                params: [signerAddress, "latest"],
              });
              const balanceBn = ethers.BigNumber.from(userBalance);
              if (balanceBn.lt(priceBn)) {
                alert("잔액이 부족합니다.");
                return;
              }

              setPageLoading(true);
              const tx = await saleContract.buy(
                item.saleInfoId,
                1,
                signerAddress,
                "0x",
                { value: priceBn }
              );
              const receipt = await tx.wait();
              console.log(receipt);
		    console.log("currencyAddress : "+currencyAddress);
            } else if (
              currencyAddress !== "0x6a6184283af9f62da739f8b309c5fca61e2f1400" && currencyAddress !== "0xb856b12d92013452bfa0ed815e8b9a71184bcfea"
            ) {
              const erc20Balance = await erc20Contract.balanceOf(signerAddress);
              const balanceBn = ethers.BigNumber.from(erc20Balance);
              console.log("test2", balanceBn.toString(), priceBn.toString());
              if (balanceBn.lt(priceBn)) {
                alert("insufficient balance");
                return;
              }

              setPageLoading(true);
              // we need to check allowance of user
              const allowance = await erc20Contract.allowance(
                signerAddress,
                constants.SALE
              );
              const allowanceBn = ethers.BigNumber.from(allowance);
              if (allowanceBn.lt(priceBn)) {
                // approve, we need to adjust MaxUint256 to something less, minus
                const tx = await erc20Contract.increaseAllowance(
                  constants.SALE,
                  ethers.BigNumber.from(ethers.constants.MaxUint256).sub(
                    allowanceBn
                  )
                );
                const receipt = await tx.wait();
                console.log(receipt);
                const event = receipt.events?.find(
                  (e) => e.event === "Approval"
                );
                console.log(event);
              }
              // buy
              const tx = await saleContract.buy(
                item.saleInfoId,
                1,
                signerAddress,
                "0x"
              );
              const receipt = await tx.wait();
              console.log(receipt);
            } else {
              const cornContract = new ethers.Contract(
                currencyAddress,
                cornAbi,
                signer
              );
              const cornBalance = await cornContract.balanceOf(signerAddress);
              const balanceBn = ethers.BigNumber.from(cornBalance);
              console.log("test1", balanceBn.toString(), priceBn.toString());
              if (balanceBn.lt(priceBn)) {
                alert("insufficient balance");
                return;
              }

              setPageLoading(true);
              // we need to check allowance of user
              const allowance = await cornContract.allowance(
                signerAddress,
                constants.SALE
              );
              console.log(
                "allowance",
                allowance.toString(),
                priceBn.toString()
              );
              const allowanceBn = ethers.BigNumber.from(allowance);
              if (allowanceBn.lt(priceBn)) {
                // approve, we need to adjust MaxUint256 to something less, minus
                const tx = await cornContract.increaseApproval(
                  constants.SALE,
                  ethers.BigNumber.from(ethers.constants.MaxUint256).sub(
                    allowanceBn
                  )
                );
                const receipt = await tx.wait();
                console.log(receipt);
                const event = receipt.events?.find(
                  (e) => e.event === "Approval"
                );
                console.log(event);
              }
              // buy
              const tx = await saleContract.buy(
                item.saleInfoId,
                1,
                signerAddress,
                "0x"
              );
              const receipt = await tx.wait();
              console.log(receipt);
            }

            const response = await apiClient.post("blockchain/updateNft");
            if (response.data.result === "ok") {
              setTimeout(() => {
                refreshPage();
                setForceRefresh((prev) => !prev);
                setPageLoading(false);
                setModalIsOpen(false);
                alert("구매에 성공했습니다.");
              }, 10000);
              // we need to refresh buy list
            }
          }
        } catch (e) {
          alert(`팔고싶다.mobile env debugging, error = ${e}`);
        }
      }}
      onClickClose={function () {}}
    />
  );
};

export { BuyModal };
