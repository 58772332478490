import React, { useMemo, useCallback, memo } from "react";
import { useMutation } from "react-query";
//import styles from './quillEditor.module.css';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import apiClient from "../../apiClient";

const QuillEditor = memo(({ quillRef, htmlContent, setHtmlContent }) => {
  const accessToken = localStorage.getItem(`accessToken`);

  const { mutate: mutatePostImage } = useMutation((data) => {
    return apiClient.post("/community/postImage", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  });

  const imageHandler = useCallback(() => {
    const formData = new FormData();

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("name", "image");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      formData.append("uploadedImage", file);

      mutatePostImage(formData, {
        onSuccess: ({ data }) => {
          const { resfilePath } = data;

          const url = resfilePath; //res.payload.url;  //
          const quill = quillRef.current.getEditor();

          const range = quill.getSelection()?.index;

          if (typeof range !== "number") return;

          quill.setSelection(range, 1);

          quill.clipboard.dangerouslyPasteHTML(
            range,
            `<img src=${url} alt="image" />`
          );
        },
      });
    };
  }, [quillRef]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: ["small", false, "large", "huge"] }, { color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] },
          ],
          ["image"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [imageHandler]
  );
  return (
    <>
      <ReactQuill
        ref={quillRef}
        value={htmlContent}
        onChange={setHtmlContent}
        modules={modules}
        theme="snow"
        //className={styles.quillEditor}
      />
    </>
  );
});

export default QuillEditor;
