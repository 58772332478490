import React from "react";

const CommunityModal = (props) => {
  const modal = props.modal;
  const setModal = props.modalState;

  return (
    <div className={modal.state ? "openModal community-modal" : ""}>
      {modal.state ? (
        <section>
          <header>
            {modal.header}
            <button
              className="close"
              onClick={() => {
                setModal({
                  state: false,
                });
              }}
            >
              &times;
            </button>
          </header>
          <main>{modal.contents}</main>
          <footer>
            {modal.modaltype.gubun === "alert" ? (
              <button
                onClick={() => {
                  modal.modaltype.btnOkEvent
                    ? modal.modaltype.btnOkEvent()
                    : setModal({
                        state: false,
                      });
                }}
              >
                {modal.modaltype.btnoktxt}
              </button>
            ) : (
              <>
                <button
                  onClick={() => {
                    modal.modaltype.btnOkEvent
                      ? modal.modaltype.btnOkEvent()
                      : setModal({
                          state: false,
                        });
                  }}
                >
                  {modal.modaltype.btnoktxt}
                </button>
                <button
                  onClick={() => {
                    setModal({
                      state: false,
                    });
                  }}
                  className="ml-2"
                >
                  {modal.modaltype.btncanceltxt}
                </button>
              </>
            )}

            {/*confirm ? (
              <button className="ml-2" onClick={confirm}>
                삭제
              </button>
            ) : (
              ""
            )} */}
          </footer>
        </section>
      ) : null}
    </div>
  );
};

export { CommunityModal };
