import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer";
import Layout from "./components/Layout";
import { QueryClient, QueryClientProvider } from "react-query";
import { useMetaMask } from "metamask-react";
import React, { useContext, useEffect, useState } from "react";
import { HeaderModal } from "./components/HeaderModal";
import { Modal } from "./components/Modal";
import { LiveChainNetworkParams, TestChainNetworkParams } from "./Constant";
//import Web3 from "web3";
import { Helmet } from "react-helmet-async";
import { ethers } from "ethers";
import { proxy } from "valtio";
import AppState from "./AppState";
import apiClient from "./apiClient";
import useScript from "./hooks/useScript";
import { AuthContext, AuthProvider } from "./AuthContext";

import create from "zustand";
import PersistentAppState from "./PersistentAppState";
import { AddEthereumChainParameter } from "metamask-react/lib/metamask-context";
import { erc20Abi } from "./blockchain/erc20Abi";
import { constants } from "./blockchain/constants";
import { saleAbi } from "./blockchain/saleAbi";
import { currencyById } from "./blockchain/currencies";
import { getCornBalance } from "./blockchain/blockchainUtils";
import { useUserStore } from "./useUserStore";
//const useUserStore = create(PersistentAppState);

const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //other query settings
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  if (typeof window.ethereum !== "undefined") {
    console.log("MetaMask is installed!");
  }

  const userdata = useUserStore((state) => state.userdata);
  const setUserdata = useUserStore((state) => state.setUserdata);
  const setBalance = useUserStore((state) => state.setBalance);
  // console.log(`userdata = ${JSON.stringify(userdata)}`);

  const state = proxy(AppState);
  const {
    status,
    connect,
    account,
    chainId,
    ethereum,
    switchChain,
    addChain,
    AddEthereumChainParameter,
  } = useMetaMask();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    // switchChain("0x1");
    // connect();
    const accessToken = localStorage.getItem(`accessToken`);
    setIsLogin(accessToken && accessToken !== "");
  }, []);

  const checkInstallApp = () => {
    function clearTimers() {
      clearInterval(check);
      clearTimeout(timer);
    }

    function isHideWeb() {
      if (document.webkitHidden || document.hidden) {
        clearTimers();
      }
    }
    const check = setInterval(isHideWeb, 200);

    const timer = setTimeout(function () {
      redirectStore();
    }, 500);
  };

  const redirectStore = () => {
    const ua = navigator.userAgent.toLowerCase();

    if (window.confirm("스토어로 이동하시겠습니까?")) {
      window.location.href =
        ua.indexOf("android") > -1
          ? `https://play.google.com/store/apps/details?id=io.metamask`
          : `https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202`;
    }
  };

  function openMetaMaskUrl(url) {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_self";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  //https://metamask.github.io/metamask-deeplinks/
  const exeDeepLink = () => {
    let url = process.env.REACT_APP_DEPP_LINK_META;
    // window.location.href = url;
    openMetaMaskUrl(url);
  };

  const openMetamask = async () => {
    // alert(navigator.platform);
    try {
      await ethereum.enable();
    } catch (e) {
      let pcDevice = "win16|win32|win64|mac|macintel";
      if (navigator.platform) {
        // 모바일 환경일 경우
        if (pcDevice.indexOf(navigator.platform.toLowerCase()) < 0) {
          exeDeepLink();
          checkInstallApp();
        } else {
          window.open(`https://metamask.io/download/`, "_blank");
        }
      }
      return;
    }
    try {
      // checking target network
      const params =
        REACT_APP_ENVIRONMENT === "prod"
          ? LiveChainNetworkParams
          : TestChainNetworkParams;
      const targetChainId = params.chainId; //(REACT_APP_ENVIRONMENT === 'prod') ? LiveChainNetworkParams.chainId : TestChainNetworkParams.chainId;
      console.log(`targetChainId = ${targetChainId}`);
      if (chainId !== targetChainId) {
        try {
          await switchChain(targetChainId);
        } catch (e) {
          console.error(`switchChain error = ${JSON.stringify(e)}`);
          if (e.code === 4902 || e.code === -32603) {
            try {
              await addChain(params);
              /*await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: '0x61',
                    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
                  },
                ],
              });*/
            } catch (addError) {
              console.error(`addChain error = ${JSON.stringify(addError)}`);
            }
          }
        }
        // await addChain(TestChainNetworkParams);
      }
      const addresses = await connect(); // connect() return address array
      console.log(`connectData! = ${JSON.stringify(addresses)}`); // return addresses: ["0xe625e70564d5a6bc53172a8a1de20b6a809135cf"]

      // checking address
      if (addresses.length <= 0) {
        alert(`[ERROR]Wallet has no address!`);
        return;
      }

      // get nonce from app server
      const address = addresses[0];
      //      setAddress(address);
      const { data: nonceData } = await apiClient.post("/auth/nonce", {
        address: address,
      });
      console.log(`data = ${JSON.stringify(nonceData)}`);
      const { nonce } = nonceData;

      // personal_sign
      const signed_message = await ethereum.request({
        method: "personal_sign",
        params: [nonce, address, "noncePassword"],
      });
      console.log(`signed_message = ${JSON.stringify(signed_message)}`);

      // login with signed message
      // const address = addresses[0];
      // const signed_message = ``;
      const { data: loginData } = await apiClient.post("auth/login", {
        address,
        signed_message,
      }); // /auth/login
      const { accessToken } = loginData;

      console.log(`accessToken1 = ${accessToken}`);
      localStorage.setItem(`accessToken`, accessToken);
      setIsLogin(true);
      // const balance = await getWalletBalance();
      // const cornBalance = await getCornBalance();

      const { data: userdata } = await apiClient.get("user/profile");
      // console.log(`userdata = ${JSON.stringify(userdata)}`);
      const { profile } = userdata;
      console.log(`profile! = ${JSON.stringify(profile)}`);
      setUserdata(profile);
    } catch (e) {
      console.error(`openMetamask error = ${JSON.stringify(e)}`);
    } finally {
      setModalIsOpen(false);
    }
  };

  /*  const getCornBalance = async () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const signerAddress = await signer.getAddress();
    const saleContract = new ethers.Contract(
        constants.SALE,
        saleAbi,
        signer
    );
    const currencyAddress = currencyById(2);
    const erc20Contract = new ethers.Contract(
        currencyAddress,
        erc20Abi,
        signer
    );
    const erc20Balance = await erc20Contract.balanceOf(signerAddress);
    const balanceBn = ethers.BigNumber.from(erc20Balance);
    // console.log('getCornBalance', balanceBn.toString());
    console.log('getCornBalance', ethers.utils.formatUnits(balanceBn, 18));
    return ethers.utils.formatUnits(balanceBn, 18);
  }*/

  const getWalletBalance = async () => {
    // await getUserData();

    const ethereum = window["ethereum"];
    if (ethereum) {
      const addresses = await ethereum.request({
        method: "eth_requestAccounts",
      });
      if (addresses) {
        state.address = addresses[0];
        const balance = await ethereum.request({
          method: "eth_getBalance",
          params: [state.address, "latest"],
        });
        const formatBalance = ethers.utils.formatEther(balance);
        state.formatBalance = formatBalance;
        console.log(formatBalance);
        return formatBalance;
      }
    }

    // const web3 = new Web3(window.ethereum);
    // if (web3.eth) {
    //   const accounts = await web3.eth.getAccounts();
    //   console.log(`accounts = ${JSON.stringify(accounts)}`);
    //   const address = accounts[0];
    //   if (address) {
    //     state.address = address;
    //   }
    //   const balance = await web3.eth.getBalance(address);
    //   const formatBalance = ethers.utils.formatEther(balance);
    //   state.formatBalance = formatBalance;
    //   console.log(formatBalance);
    //   return formatBalance;
    // }
    return 0;
  };

  const getUserData = async () => {
    console.log(`getUserData`);
    const { data } = await apiClient("/user/profile");
    console.log(`getUserData ${JSON.stringify(data)}`);
    if (data && data.result === "ok") {
      // alert(JSON.stringify(data.profile))
      state.profile = data.profile;
    }
  };

  const onUpdateBalance = async () => {
    console.log(`onUpdateBalance!!`);
    const balance = await getCornBalance(ethereum);
    setBalance(balance);
  };

  return (
    <>
      <Helmet>
        <title>Popcorn | NFT platform</title>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <div>
          <Header
            isLogin={isLogin}
            setIsLogin={setIsLogin}
            setModalIsOpen={setModalIsOpen}
            userdata={userdata}
            openMetamask={openMetamask}
            onUpdateBalance={onUpdateBalance}
          />
          <Layout
            onUpdateBalance={onUpdateBalance}
            setModalIsOpen={setModalIsOpen}
          />
          <Footer isLogin={isLogin} userdata={userdata} />
        </div>

        {modalIsOpen && (
          <Modal
            onClick={async () => {
              await openMetamask();
              setModalIsOpen(false);
            }}
            styledWrapper="modal-dialog max-w-lg"
            setModalIsOpen={setModalIsOpen}
            title="Connect your wallet"
            btnTitle="Connect Metamask"
            childrenBody={<HeaderModal />}
          />
        )}
      </QueryClientProvider>
    </>
  );
};

export default App;
