import React, {useEffect} from 'react';
import {Modal} from "../components/Modal";
import {ModalBodyTerms} from "../components/ModalBodyTerms";

const TermsPage = () => {
  return (
      <main className="pt-[5.5rem] lg:pt-24">
          <section className="dark:bg-jacarta-800 relative py-16 md:py-24">
{/*
              <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                  <img src="img/gradient_light.jpg" alt="gradient" className="h-full w-full"/>
              </picture>
*/}
              <div className="container">
                  <h1 className="font-display text-jacarta-700 text-center text-4xl font-medium dark:text-white">
                      Terms Of Service
                  </h1>
                  <div className="article-content mx-auto max-w-[48.125rem]">
                      {/*<h2 className="text-base">NFT Terms of Use</h2>*/}

                          <p>Article 1 [Purpose)</p>
                          <p>These terms and conditions govern the rights and interests of the company and members in the transaction of NFTs (as defined in Article 2 of these Terms and Conditions) within the Popcorn NFT Marketplace by Eggpay members (hereinafter referred to as “members”) who have agreed to the EggPay Terms of Use. It is intended to stipulate obligations and other necessary matters.</p>
                            <br></br>
                          <p>Article 2 (Definition)</p>
                          <p>1. "NFT" is a non-fungible token, (i) issued with metadata information about "digital works" such as images, videos, music, and books, and (ii) It refers to a blockchain-based digital asset to which NFT owners are granted specific rights (hereinafter referred to as "NFT owners' rights") regarding the above "digital works".</p>
                          <p>2. "Digital Works" means digital works such as digital images, videos, music, and books linked to NFT.</p>
                          <p>3. "NFT Owner's Rights" means the rights notified at the time the NFT is first sold in Popcorn NFT. "NFT owner's right" means the right to use the "digital work" linked to the NFT within a certain limit, but also the acquisition of ownership of the "digital work" itself, intellectual property rights including copyrights, and other rights does not mean The contents of "NFT owner's rights" may differ for each NFT.</p>
                          <p>4. "Digital Works Online Exhibition Center" refers to online screens such as websites and apps where NFT-related "digital works" can be viewed and viewed, as well as servers and databases necessary for their implementation.</p>
                          <p>5. "Popcorn NFT Marketplace" means an online place where NFTs can be traded, and servers and databases necessary for its implementation. Unless otherwise specified, the term "NFT Marketplace" in these Terms and Conditions refers to Popcorn NFT Market, a service provided by the company.</p>
                          <p>6. "NFT issuer" means a person who issues and distributes NFTs for the first time after legally securing all rights necessary for the following activities (hereinafter referred to as "NFT IP"). In general, the creator of a digital work associated with an NFT is the "NFT issuer".</p>
                              <p>&nbsp; &nbsp;1) Production of "digital works" [(i) In the case of acquiring intellectual property rights for the "digital works" itself, (ii) intellectual property rights necessary for the production of "digital works" through a consignment contract for the production of digital works with the copyright holder of the original work Or, including the case of obtaining a license related thereto]</p>
                              <p>&nbsp; &nbsp;2) Issuing and distributing NFTs linked to "digital works" directly or by using solutions and/or transaction brokerage services provided by others</p>
                              <p>&nbsp; &nbsp;3) Guarantee of "NFT Owner's Rights" to those who own the issued and distributed NFTs</p>
                          <p>7. "External withdrawal" refers to the act of withdrawing virtual assets from a virtual asset wallet operated by the company to a virtual asset wallet address operated by a virtual asset operator other than the company or to an external personal wallet address.</p>
<br></br>
                          <p>Article 3 (Rights of NFT Owners)</p>
                          <p>1. Holders of NFTs are granted only the right to use digital works linked to NFTs in accordance with the contents of "Rights of NFT Owners". NFT does not mean intellectual property rights, including copyright and trademark rights, or specific rights such as portrait rights and merchandising rights per se, and possessing NFTs acquires rights such as possession of digital works linked to NFTs or copyrights related to digital works. doesn't mean to.</p>
                          <p>2. "Rights of NFT owners" can only be exercised while legally holding NFTs, and "rights of NFT owners" are recognized by the holder of the NFT at the time the rights are exercised. If the NFT is transferred to another person, the member who transferred the NFT can no longer exercise the "NFT Owner's Rights". "Rights of NFT owners" may not be guaranteed to those who have received NFTs in a way that is not officially supported by the "Company" and/or in violation of related laws.</p>
<br></br>
                          <p>Article 4 (Notes on NFT)</p>
                          <p>1. The company does not guarantee the authenticity or completeness of rights of "digital works" linked to NFTs. However, if the damage is caused intentionally or negligently by the company, the company is liable within the scope set by the law.</p>
                          <p>2. If taxes and dues are incurred due to the transfer of ownership of the NFT, the person who transferred the ownership of the NFT must bear it.</p>
                          <p>3. The company may use digital works linked to NFTs for the purpose of promoting NFT transaction brokerage within the Popcorn NFT market.</p>
                          <p>4. Whether or not to open the "Digital Works Online Exhibition Center" may vary for each NFT, and the Company does not guarantee the opening of the "Digital Works Online Exhibition Center" for each NFT.</p>
                          <p>5. The scope of services provided in the "NFT Marketplace" (including, but not limited to, whether or not to support transactions, the period of transaction support, whether to support deposits and external withdrawals) may be different for each NFT, and the company You reserve the right to make your own judgment as to scope.</p>
                          <p>6. For all NFTs distributed and/or traded in the "NFT Marketplace," the issuer of each NFT, the date and time of the transaction, the member's Egg Pay nickname, transaction amount, current sale status, and sales amount are all displayed on the service screen. Or some may be disclosed.</p>
<br></br>
                          <p>Article 5 (Prohibited Acts and Termination)</p>
                          <p>1. Members cannot engage in any of the following acts in relation to NFTs distributed in Popcorn NFT and digital works related to them.</p>
                              <p>&nbsp; &nbsp;1) The act of modifying, distorting and posting digital works</p>
                              <p>&nbsp; &nbsp;2) Acts of using digital works for commercial purposes, such as advertising products or services of oneself or a third party</p>
                              <p>&nbsp; &nbsp;3) The act of issuing a separate NFT linked to a digital work</p>
                              <p>&nbsp; &nbsp;4) An act of registering and acquiring separate intellectual property rights by abusing digital works available under the "Rights of NFT Owners"</p>
                              <p>&nbsp; &nbsp;5) An act of abnormally fluctuating the NFT price through repeated trading with a specific member</p>
                          <p>2. If a member violates the preceding paragraph, the company may restrict the use of the service and request correction at the same time. The Popcorn NFT service use contract may be terminated if correction is not made within a reasonable period of time despite the request for correction, or if the same violation is repeatedly committed twice or more.</p>
                          <p>3. Members cannot withdraw from the service while holding NFTs. Members can withdraw from the service after disposing of or withdrawing their NFTs. However, the member may withdraw from the service after requesting the company to store the NFT, in which case the company will safely store the NFT requested by the member for up to 10 years.</p>
<br></br>
                          <p>Article 6 (NFT transaction method and role of the company)</p>
                          <p>1. The primary NFT transaction is carried out through fixed-price sales and other methods determined and guided by the company.</p>
                          <p>2. Members must fully understand each sales method described at the bottom of the first transaction screen and participate in the transaction.</p>
                          <p>3. Bid cancellation may be restricted to prevent false bids for the purpose of price manipulation.</p>
                          <p>4. The company is an intermediary in NFT transactions and is not a party to NFT transactions. The responsibility for NFT transactions is borne by the seller, and the company does not represent or guarantee the seller. However, if damage is caused to a member due to the intention or negligence of the company, the company compensates for the damage.</p>
<br></br>
                          <p>Article 7 (Objection related to digital works)</p>
                          <p>1. Those who claim that their copyrights and other rights are infringed by NFTs distributed through the "NFT Marketplace" provided by the "Company" and "digital works" linked to the NFTs (hereinafter referred to as "right claimants") You can request the "company" to stop using and distributing the NFT by explaining the facts.</p>
                          <p>2. If the "Company" is requested to suspend the use and distribution of NFTs pursuant to Paragraph 1, it immediately suspends the exercise of "NFT owner's rights" for the NFTs, and the claimant, members who hold NFTs, and NFT issuers should be notified of that fact.</p>
                          <p>3. If the NFT issuer notified pursuant to Paragraph 2 requests the resumption of the use and distribution of the NFT while explaining that it has a legitimate right, the "company" holds the claimant and the NFT with the request for resumption and the expected date of resumption. We will notify the member who is doing so without delay and resume the "Rights of NFT Owners" on the scheduled date. However, this is not the case if the claimant has notified the company of the fact that the claimant filed a lawsuit against the NFT issuer's infringement before the scheduled resumption date.</p>
                          <p>4. If a third party claims infringement of rights related to a specific NFT and related digital works and/or "NFT IP", the "Company" provides services such as brokerage of the transaction of the NFT as set forth in this Article and notify the claimant and "NFT issuer" of the fact without delay. The "company" will endeavor to resolve the related dispute as soon as possible by arranging consultations between the claimant and the "NFT issuer" to resolve the issue. The Company will resume the intermediary of NFT transactions that have been suspended after the relevant dispute is settled. Even if the dispute between the NFT issuer and the claimant is not resolved, and the member holding the NFT suffers damage, the company is not responsible for it. However, if the damage is caused intentionally or negligently by the company, the company is liable within the scope set by the law.</p>
<br></br>
                          Article 8 (Governing Law and Dispute Resolution Method)<br></br>
                          The laws of the Republic of Korea apply in the event of a dispute related to these terms and conditions.
                          <br></br>
                          <br></br>

                          [Appendix]<br></br>
                          These Terms and Conditions apply from January 1, 2023.<br></br>
                          The previous terms and conditions can be found below.

                  </div>
              </div>
          </section>
      </main>
  );
};

export { TermsPage };
