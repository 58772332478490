import React, { useState } from "react";
import apiClient from "../../apiClient";

import { useNavigate } from "react-router-dom";
import { CommunityModal } from "../CommunityModal";

const CommunityList = (props) => {
  const navigate = useNavigate();
  const imageUrl = props.imageUrl;
  const [isModal, setModal] = useState({
    state: false,
    header: "",
    contents: "",
    modaltype: "",
  });

  const oepnLoginCheck = () => {
    localStorage.getItem("accessToken") === ""
      ? setModal({
          state: true,
          header: "Community",
          contents: "로그인후 이용 가능합니다.",
          modaltype: {
            gubun: "alert",
            btnoktxt: "확인",
          },
        })
      : openPost();
  };

  async function openPost() {
    const { data } = await apiClient.get(
      `/community/CheckCollection/${props.data.id}`
    );
    if (data && data.result === "ok" && data.check) {
      navigate("/CommunityBoard/" + props.data.id);
      return;
    } else {
      setModal({
        state: true,
        header: "Community",
        contents: "홀더만 입장 가능합니다.",
        modaltype: {
          gubun: "alert",
          btnoktxt: "확인",
        },
      });
    }
  }

  return (
    <article>
      <div className="overflow-hidden rounded-2.5xl transition-shadow hover:shadow-lg">
        <div
          className="pointer"
          onClick={() => {
            oepnLoginCheck();
          }}
        >
          <figure className="group overflow-hidden">
            <img
              src={imageUrl + props.data.thumbnail}
              alt={imageUrl + props.data.thumbnail}
              className="h-full w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105"
            />
          </figure>

          <div className="rounded-b-[1.25rem] border border-t-0 border-jacarta-100 bg-white p-[10%] dark:border-jacarta-600 dark:bg-jacarta-700">
            <div className="mb-3 flex flex-wrap items-center space-x-1 text-xs">
              <span className="inline-flex flex-wrap items-center space-x-1 text-accent">
                {props.data.collectionTitle}
              </span>
            </div>

            <h2 className="dark:hover:text-accent; mb-4 font-display text-lg text-jacarta-700 hover:text-accent dark:text-white">
              {props.data.title}
            </h2>
            <p className="mb-8 dark:text-jacarta-200">
              {props.data.description}
            </p>

            <div className="flex flex-wrap items-center space-x-2 text-sm text-jacarta-400">
              <span>
                <time>Holder: {props.data.actualOwnerCount}</time>
              </span>
            </div>
          </div>
        </div>
      </div>

      <CommunityModal modal={isModal} modalState={setModal} />
    </article>
  );
};

export { CommunityList };
