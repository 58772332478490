import React, { useEffect, useState } from "react";
import gradient from "../../img/gradient.jpg";
import gradient_dark from "../../img/gradient_dark.jpg";
import { TopCreatorMain } from "../../components/TopCreatorMain";
import { CreateAndSell } from "../../components/CreateAndSellMain";
import { Partners } from "../../components/Partners";
import { FeaturedCollection } from "../../components/FeaturedCollection/FeaturedCollection";
import { BgSwiper } from "../../components/BgSwiper";
import { Link } from "react-router-dom";
import DemoState from "../../stores/DemoState";
import { proxy, useSnapshot } from "valtio";
import { useMutation, useQuery } from "react-query";
import apiClient from "../../apiClient";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore, {
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
} from "swiper";

import pixel from "../../img/pixel.jpg";
import hero_slide_1_thumb from "../../img/hero/hero_slide_1_thumb.jpg";
import hero_slide_2_thumb from "../../img/hero/hero_slide_2_thumb.jpg";
import hero_slide_3_thumb from "../../img/hero/hero_slide_3_thumb.jpg";
import hero_slide_4_thumb from "../../img/hero/hero_slide_4_thumb.jpg";

import hero_slide_1 from "../../img/hero/hero_slide_1.jpg";
import hero_slide_2 from "../../img/hero/hero_slide_2.jpg";
import hero_slide_3 from "../../img/hero/hero_slide_3.jpg";
import hero_slide_4 from "../../img/hero/hero_slide_4.jpg";

import popcorn_banner1 from "../../img/hero/popcorn_banner1.jpg";
import popcorn_banner2 from "../../img/hero/popcorn_banner2.jpg";
import popcorn_banner3 from "../../img/hero/popcorn_banner3.jpg";
import popcorn_banner4 from "../../img/hero/popcorn_banner4.jpg";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const MainPage = () => {
  const { t, i18n } = useTranslation();
  const isPc = useMediaQuery({
    query: "(min-width:1024px)",
  });

  SwiperCore.use([Navigation, Pagination, Autoplay]);
  const state = proxy(DemoState);
  const snap = useSnapshot(state);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  //useScript('http://localhost:4000/js/index.js');//'https://use.typekit.net/foobar.js');

  const [hotItems, setHotItems] = useState([]);
  const [featuredCollections, setFeaturedCollections] = useState([]);
  const [banners, setBanners] = useState([]);
  const [rolling, setRolling] = useState(false);

  const {
    isLoading: isLoadingCreate,
    isError: isErrorCreate,
    status: statusCreate,
    mutate: mutateCreate,
  } = useMutation((data) => {
    return apiClient.post("/social/mailingAddress", data, {});
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email } = e.target;
    const emailValue = email.value;
    if (!emailValue || emailValue === "") {
      alert("Email is empty.");
      return;
    }
    mutateCreate(
      { email: emailValue },
      {
        onSuccess: async (response) => {
          e.target.reset();
          alert("Subscribed!");
        },
        onFail: async (error) => {
          console.log(`mutateCreate onFail = ${JSON.stringify(error)}`);
        },
      }
    );
  };

  useEffect(() => {
    (async () => {
      const { data: dashboard } = await apiClient.get(`social/dashboard`);
      const { hotItems: _h, featuredCollections: _f, banners } = dashboard;
      console.log(`dashboard = ${JSON.stringify(banners)}`);
      setHotItems(_h);
      setFeaturedCollections(_f);
      setBanners(banners);
      if (banners && banners.length > 1) {
        setRolling(true);
      }
    })();
  }, []);

  return (
    <main>
      {/*<section className="relative h-screen">*/}
      <section className="relative">
        <Swiper
          // autoHeight={true}
          loop={rolling}
          spaceBetween={10}
          initialSlide={1}
          // centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          // navigation={true}
          // thumbs={{ swiper: thumbsSwiper }}
          modules={[Pagination, Autoplay]}
          className="mySwiper2 "
        >
          {
            // [popcorn_banner1, popcorn_banner2, popcorn_banner3, popcorn_banner4]
            banners &&
              banners.map((item, index) => (
                <SwiperSlide key={index}>
                  <img className="w-full object-cover" src={item.imageURL} />
                </SwiperSlide>
              ))
          }
        </Swiper>
      </section>

      <div className="container py-16">
        <h2 className="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white">
          <span
            className="mr-1 inline-block h-6 w-6 bg-contain bg-center text-xl"
            style={{
              backgroundImage:
                "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f525.png)",
            }}
          ></span>
          <span style={{ whiteSpace: "pre-line", textAlign: "center" }}>
            {isPc
              ? t("main.hot_item").replace(/(?:\r\n|\r|\n)/g, " ")
              : t("main.hot_item")}
          </span>
        </h2>
        <TopCreatorMain data={hotItems} />
      </div>
      <div className="container py-16">
        <h2 className="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white">
          <span
            className="mr-1 inline-block h-6 w-6 bg-contain bg-center text-xl"
            style={{
              backgroundImage:
                "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f4a5.png",
            }}
          ></span>
          {t("main.featured_collections")}
        </h2>
        <FeaturedCollection data={featuredCollections && featuredCollections} />
      </div>

      <CreateAndSell />

      <section className="relative py-16 dark:bg-jacarta-900">
        <p className="mx-auto mt-20 max-w-2xl text-center text-lg text-jacarta-700 dark:text-white">
          {t("main.join_mailing_list")}
        </p>
        <div className="mx-auto mt-7 max-w-md text-center">
          <form action="api" onSubmit={handleSubmit} className="relative">
            <input
              type="email"
              placeholder="Email address"
              name="email"
              className="w-full rounded-full border border-jacarta-100 py-3 px-4 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder-white"
            />
            <button className="absolute top-2 right-2 rounded-full bg-accent px-6 py-2 font-display text-sm text-white hover:bg-accent-dark">
              {t("main.subscribe")}
            </button>
          </form>
        </div>
      </section>
      <Partners />
    </main>
  );
};

export { MainPage };
