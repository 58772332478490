import React from 'react';
import { Link } from 'react-router-dom';
import CORN from '../../../img/chains/CORN.png';
import moment from "moment";
const TableElement = ({
  id,
  createdat,
  username,
  email,
  address,
  inviteecount,
  inviter,
}) => {
  return (     
    <a className='flex transition-shadow hover:shadow-lg' role='row'>
        <div className=' w-[20%] referral_td border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600' role='cell'>
          <span className='mr-2 lg:mr-4'>{id}</span>
        </div>

        <div className=' w-[20%] referral_td  border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600' role='cell'>
          <span className='mr-2 lg:mr-4'>{moment(createdat).format('YY-MM-DD')}</span>
        </div>

        <div className=' w-[20%] referral_td border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600' role='cell'>
          <span className='mr-2 lg:mr-4'>{username}</span>
        </div>

        <div className=' w-[20%] referral_td border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600' role='cell'>
          <span className='mr-2 lg:mr-4'>{inviter}</span>
        </div>
        <div className=' w-[20%] referral_td border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600' role='cell'>
          <span className='mr-2 lg:mr-4'>{inviteecount}</span>
        </div>        
    </a>
  );
};

export { TableElement };
