import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MetaMaskProvider } from "metamask-react";
import "./i18n";
import { ScrollTop } from "./components/ScrollTop";
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MetaMaskProvider>
      <BrowserRouter>
          <HelmetProvider>
              <ScrollTop />
              <App />
          </HelmetProvider>
      </BrowserRouter>
    </MetaMaskProvider>
  </React.StrictMode>
);
