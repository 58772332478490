import React from 'react';
import { Link } from 'react-router-dom';
import partner_logo_1 from '../../img/partners/partner_logo_0.png';
import partner_logo_2 from '../../img/partners/partner_logo_1.png';
import partner_logo_3 from '../../img/partners/partner_logo_2.png';
import partner_logo_4 from '../../img/partners/partner_logo_6.png';
import partner_logo_5 from '../../img/partners/partner_logo_3.png';
import partner_logo_6 from '../../img/partners/partner_logo_4.png';
import partner_logo_7 from '../../img/partners/partner_logo_7.png';
import partner_logo_8 from '../../img/partners/partner_logo_8.png';
const Partners = () => {
  return (
      // bg-light-base
    <div className='dark:bg-jacarta-800'>
      <div className='container'>
        <div className='grid grid-cols-2 py-8 sm:grid-cols-6'>
          <Link to='#'>
            <img src={partner_logo_1} alt='partner 1' />
          </Link>
          <Link to='#'>
            <img src={partner_logo_2} alt='partner 2' />
          </Link>
          <Link to='#'>
            <img src={partner_logo_3} alt='partner 3' />
          </Link>
          <Link to='#'>
            <img src={partner_logo_4} alt='partner 4' />
          </Link>
          <Link to='#'>
            <img src={partner_logo_5} alt='partner 5' />
          </Link>
          <Link to='#'>
            <img src={partner_logo_6} alt='partner 6' />
          </Link>
          <Link to='#'>
            <img src={partner_logo_7} alt='partner 7' />
          </Link>
          <Link to='#'>
            <img src={partner_logo_8} alt='partner 8' />
          </Link>                    
        </div>
      </div>
    </div>
  );
};

export { Partners };
