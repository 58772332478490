import React from "react";
import { Route, Routes } from "react-router-dom";
import { ItemDetail } from "../../pages/ItemDetailPage";
import { MainPage } from "../../pages/MainPage";
import { NFTItemUpload } from "../../pages/NFTItemUploadPage";
import { RankingList } from "../../pages/RankingListPage";
import { UserProfileEdit } from "../../pages/UserProfileEditPage";
import { UserProfile } from "../../pages/UserProfilePage";
import { UsersActive } from "../../pages/UsersActivityPage";
import { CollectionListPage } from "../../pages/NFTListPage";
import { CommunityPage } from "../../pages/CommunityPage";
import { CommunityBoard } from "../../pages/CommunityBoard";
import { CommunityPost } from "../../pages/CommunityPost";
import { CommunityPostWrite } from "../../pages/CommunityPostWrite";
import { ReferralPage } from "../../referral";
import { PrivacyPage } from "../../privacy";
import { TermsPage } from "../../terms";
import { CollectionPage } from "../../collection";
import { ContactPage } from "../../contact";
import { FaqPage } from "../../faq";
import { CollectionCreatePage } from "../../pages/CollectionCreatePage";
import {SearchPage} from "../../pages/SearchPage";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper";
import {TopCreatorMain} from "../TopCreatorMain";
import {FeaturedCollection} from "../FeaturedCollection";
import {CreateAndSell} from "../CreateAndSellMain";
import {Partners} from "../Partners";
import {StakingMain} from "../../pages/StakingPage";
import {ReferralList} from "../../pages/ReferralListPage";
 
const Page404 = () => <h1>Four:oh:four</h1>;

const Layout = ({onUpdateBalance, setModalIsOpen}) => {
  return (
    <Routes>
      <Route path="/index" element={<MainPage />} />
      <Route path="/item/:itemId" element={<ItemDetail onUpdateBalance={onUpdateBalance} setModalIsOpen={setModalIsOpen}/>} />
      <Route path="/community" element={<CommunityPage />} />
      <Route path="/CommunityBoard/:communityId" element={<CommunityBoard />} />
      <Route
        path="/CommunityPost/:communityId/:postId"
        element={<CommunityPost />}
      />
      <Route path="/CommunityPostWrite" element={<CommunityPostWrite />} />
      <Route path="/rankings" element={<RankingList />} />
      <Route path="/activity" element={<UsersActive />} />
      <Route path="/user/:address" element={<UserProfile />} />
      <Route path="/edit-profile" element={<UserProfileEdit />} />
      <Route path="/create-collection" element={<CollectionCreatePage />} />
      <Route path="/create-item" element={<NFTItemUpload />} />
      <Route path="/collections" element={<CollectionListPage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/collection/:collectionId" element={<CollectionPage />} />
      <Route path="/referral" element={<ReferralPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/staking" element={<StakingMain />} />
      <Route path="/myRefferal" element={<ReferralList />} />
      <Route path="/" element={<MainPage />} />
      {/*<Route path="*" element={Page404}/>*/}
    </Routes>
  );
};
export default Layout;
