import React, {useEffect, useRef, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {Doughnut, Line} from "react-chartjs-2";
import Chart from 'chart.js/auto';
import apiClient from "../../../apiClient";
import {useTranslation} from "react-i18next";

const PriceHistory = ({itemId}) => {
  const { t, i18n } = useTranslation();
  const chartRef = useRef();

  const [priceHistories, setPriceHistories] = useState([]);
  const [summaryPrice, setSummaryPrice] = useState(null);
  const [summaryVolume, setSummaryVolume] = useState(0);

  const [labels, setLabels] = useState([]);
  const [yValues, setYValues] = useState([]);
  const [period, setPeriod] = useState(30);
  const [selectValue, setSelectValue] = useState(90);

  const handleSelect = (e) => {
    const value = e.target.value;
    console.log(`value = ${value}`);
    setSelectValue(value)
    setPeriod(value);
  };

  useEffect(() => {
    (async () => {
      const { data: itemData } = await apiClient.get(
          `blockchain/collectionAsset/${itemId}/priceHistory/${period}`
      );
      console.log(`priceHistories = ${JSON.stringify(itemData)}`);
      const {
        priceHistories,
        summary
      } = itemData;
      setPriceHistories(priceHistories);

      setLabels(['', ...priceHistories.map(x => x.date), '']);
      setYValues([null, ...priceHistories.map(x => x.price), null]);

      const {price90, volume90} = summary;
      setSummaryPrice(price90);
      setSummaryVolume(volume90);
    })();
    return () => {
    }
  }, [period])

  return (
    <div
      // className='tab-pane fade'
      id='price-history'
      role='tabpanel'
      aria-labelledby='price-history-tab'>
      <div
        className='rounded-t-2lg rounded-b-2lg rounded-tl-none border border-jacarta-100
			bg-white p-6 dark:border-jacarta-600 dark:bg-jacarta-700'>
        <div className='mb-10 flex flex-wrap items-center'>
          <select
              value={selectValue}
              onChange={handleSelect}
            className='mr-8 min-w-[12rem] rounded-lg border-jacarta-100 py-3.5 text-sm dark:border-jacarta-600
					dark:bg-jacarta-700 dark:text-white'>
            <option value='7'>{t('common.last_7_days')}</option>
            <option value='14'>{t('common.last_14_days')}</option>
            <option value='30'>{t('common.last_30_days')}</option>
            <option value='3000'>{t('common.all_time')}</option>
          </select>

          <div className='py-2'>
            <span className='mr-4 inline-block align-middle'>
              <span className='block text-sm font-bold dark:text-white'>{t('common.day_avg_price_90')}:</span>
              <span className='block text-sm font-bold text-green'>Ξ {summaryPrice && summaryPrice}</span>
            </span>

            <span className='inline-block align-middle'>
              <span className='block text-sm font-bold dark:text-white'>{t('common.day_volume_90')}:</span>
              <span className='block text-sm font-bold text-green'>Ξ {summaryVolume && summaryVolume}</span>
            </span>
          </div>
        </div>

{/*
        <div className='chart-container relative h-80 w-full'>
          <canvas id='activityChart' ref={chartRef} className={'w-100'}
            ></canvas>
        </div>
*/}

        <Line data={{
          labels: labels,
          datasets: [{
            data: yValues,
            label: t('common.price'),
            borderColor: "#3e95cd",
            backgroundColor: "#7bb6dd",
            fill: false,
          }
          ]
        }} />
      </div>
    </div>
  );
};

export { PriceHistory };
