import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import apiClient from "../../apiClient";
import gradient_light from "../../img/gradient_light.jpg";
import QuillEditor from "../../components/QuillEditor/QuillEditor";
import { CommunityModal } from "../../components/CommunityModal";

const CommunityPostWrite = ({ user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const [title, setTitle] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const quillRef = useRef();
  const [isModal, setModal] = useState({
    state: false,
    header: "",
    contents: "",
    modaltype: "",
  });

  const communityid = location.state.communityId;
  const postid = location.state.postId;

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const setReturnPage = () => {
    postid
      ? navigate(`/CommunityPost/${communityid}/${postid}`)
      : navigate(`/CommunityBoard/${communityid}`);
  };

  const editorFocus = () => {
    setModal({
      state: false,
    });
    quillRef.current.getEditor().focus();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title.trim() === "") {
      inputRef.current.focus();
      return;
    }
    const description = quillRef.current.getEditor().getText();

    if (description.trim() === "") {
      setModal({
        state: true,
        header: "Community",
        contents: "내용을 입력하세요.",
        modaltype: {
          gubun: "alert",
          btnoktxt: "확인",
          btnOkEvent: editorFocus,
        },
      });
      return;
    }

    const param = {
      communityId: parseInt(communityid),
      title: title,
      contents: htmlContent,
    };

    if (postid) {
      param.id = parseInt(postid);
    }
    setPost(param);
  };

  const setPost = async (param) => {
    const { data } = await apiClient.post("/community/updateBoardPost/", param);
    if (
      data.statusCode === 401 ||
      data.result === "no_user" ||
      data.result === "error"
    ) {
      setModal({
        state: true,
        header: "Community",
        contents: "처리도중 오류가 발생했습니다. 사이트 관리자에게 문의하세요.",
        modaltype: {
          gubun: "alert",
          btnoktxt: "확인",
        },
      });
      return;
    }

    if (data && data.result === "ok") {
      sessionStorage.setItem("isCommunityEdit", "true");
      navigate(`/CommunityPost/${communityid}/${data.communityPostInfo.id}`);
    }
  };

  useEffect(() => {
    if (!postid) {
      return;
    }

    async function communityPostInfo() {
      try {
        const { data } = await apiClient.post("/community/post/", {
          communityId: parseInt(communityid),
          id: parseInt(postid),
        });
        if (data && data.result === "ok") {
          setTitle(data.communityPostInfo.title);
          setHtmlContent(data.communityPostInfo.contents);
        }
      } catch (e) {}
    }

    communityPostInfo();
  }, [postid]);

  return (
    <main className="pt-[5.5rem] lg:pt-24">
      <section className="relative py-16 md:py-24">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src={gradient_light} alt="gradient" className="h-full w-full" />
        </picture>
        <div className="container">
          <div className="mx-auto max-w-[48.125rem]">
            <div className="mb-xs-40 mb-80">
              <h4 className="mb-10 font-display text-lg text-jacarta-700">
                Write
              </h4>

              <div className="mb-6">
                <label
                  for="name"
                  className="mb-2 block font-display text-sm text-jacarta-700 dark:text-white"
                >
                  Title<span className="text-red">*</span>
                </label>
                <input
                  name="name"
                  className="contact-form-input w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                  id="name"
                  type="text"
                  onChange={(e) => {
                    onChangeTitle(e);
                  }}
                  value={title}
                  ref={inputRef}
                />
              </div>
              <form className="shrink-0">
                <div className="mb-4 mt-4">
                  <div>
                    <label
                      for="name"
                      className="mb-2 block font-display text-sm text-jacarta-700 dark:text-white"
                    >
                      Contents<span className="text-red">*</span>
                    </label>
                  </div>
                  <QuillEditor
                    quillRef={quillRef}
                    htmlContent={htmlContent}
                    setHtmlContent={setHtmlContent}
                  />

                  <button
                    onClick={() => {
                      setReturnPage();
                    }}
                    type="submit"
                    className="mt-5 rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                    id="contact-form-submit"
                  >
                    Cencel
                  </button>
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="float-right mt-5 rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                    id="contact-form-submit"
                  >
                    Post
                  </button>
                </div>
              </form>
            </div>
          </div>
          <CommunityModal modal={isModal} modalState={setModal} />
        </div>
      </section>
    </main>
  );
};

export { CommunityPostWrite };
