import React, { useEffect, useState } from "react";
import gradient_light from "../../img/gradient_light.jpg";
// import a1a from '../../img/chains/a1a.png';
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Modal } from "../../components/Modal";
import { AddProperties } from "../../components/CreateModal/AddProperties";
import { AddLevels } from "../../components/CreateModal/AddLevels";
import { CreateBlockchainMenu } from "../../components/CreateMenuLists/CreateBlockchainMenu";
import { CreateCollectionMenu } from "../../components/CreateMenuLists/CreateCollectionMenu/CreateCollectionMenu";
import { useMutation } from "react-query";
import { useMetaMask } from "metamask-react";
import { ethers } from "ethers";
import Loading from "react-fullscreen-loading";
import { saleAbi } from "../../blockchain/saleAbi";
import { creatorErc721Abi } from "../../blockchain/creatorErc721Abi";
import { currencies } from "../../blockchain/currencies";
import { useNavigate } from "react-router-dom";
import apiClient from "../../apiClient";
import CORN from "../../img/chains/CORN.png";
import { FileInput } from "../../components/FileInput";
import PersistentAppState from "../../PersistentAppState";
import create from "zustand";
import { useUserStore } from "../../useUserStore";
import { useTranslation } from "react-i18next";
import {RESOURCE_BASE_URL} from "../../Constant";

const IS_USING_BLOCKCHAIN_METHOD = true;
const SALE_CONTRACT_ADDRESS = "0x29a37764f3f67a81a876e79d1c411e2cfcff3335";

//const useUserStore = create(PersistentAppState);
const NFTItemUpload = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    status: metaMaskStatus,
    connect,
    account,
    chainId,
    ethereum,
    switchChain,
  } = useMetaMask();
  const userdata = useUserStore((state) => state.userdata);

  const [properties, setProperties] = useState(false);
  const [levels, setLevels] = useState(false);
  const [stats, setStats] = useState(false);
  const [blockchainIsOpen, setBlockchainIsOpen] = useState(false);
  const [collectionIsOpen, setCollectionIsOpen] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [mintCurrency, setMintCurrency] = useState("CORN");
  const [collectionName, setCollectionName] = useState(null);
  const [collectionId, setCollectionId] = useState(-1);
  const [selectedOption, setSelectedOption] = useState("");
  const [nftUrlUploadId, setNftUrlUploadId] = useState(null);
  const [goodUrlUploadId, setGoodUrlUploadId] = useState(null);
  const [goodUrl, setGoodUrl] = useState(null);
  const [collectionItems, setCollectionItems] = useState([]);

  const onValueChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    (async () => {
      const { data: collectionsData } = await apiClient.get(
        `blockchain/myCollectionList`
      );
      console.log(`collectionsData = ${JSON.stringify(collectionsData)}`);
      const { collections } = collectionsData;
      setCollectionItems(collections);
    })();
  }, []);

  // blockchain method
  const {
    isLoading: isLoadingNftInfo,
    isError: isErrorNftInfo,
    status: statusNftInfo,
    mutate: mutateNftInfo,
  } = useMutation((data) => {
    return apiClient.post("/upload/nftInfo", data);
  });

  // without blockchain
  const {
    isLoading: isLoadingCreate,
    isError: isErrorCreate,
    status: statusCreate,
    mutate: mutateCreateCollectionAsset,
  } = useMutation((data) => {
    return apiClient.post("/blockchain/createCollectionAsset", data);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { itemName, description, externalLink, supply } = e.target;

    if (!nftUrlUploadId) {
      alert(`Nft image is required`);
      return;
    }
    if (!itemName.value) {
      alert(`NFT name is required`);
      return;
    }
    if (!collectionId || collectionId === -1) {
      alert(`Collection select is required`);
      return;
    }

    if (IS_USING_BLOCKCHAIN_METHOD) {
      setPageLoading(true);
      const nftInfoData = {
        uploadFileId: nftUrlUploadId,
        title: itemName.value,
        description: description.value,
        externalUrl: externalLink.value,
        isPhysicallyBacked: selectedOption === "GOODS",
        detailImagePath: goodUrl,
      };
      //console.log(`mutateNftInfo ${JSON.stringify(metaData)}`);
      mutateNftInfo(nftInfoData, {
        onSuccess: async (response) => {
          console.log(response);
          try {
            if (ethereum) {
              // const price = "1000000000000000000";
              // const royaltyFraction = 25; // royalty
              const currency = "KLAY";

              const provider = new ethers.providers.Web3Provider(ethereum); // ethers.providers.ExternalProvider
              const signer = provider.getSigner();
              // const saleContract = new ethers.Contract(
              //   SALE_CONTRACT_ADDRESS,
              //   saleAbi,
              //   signer
              // );
              // const tx = await saleContract.mintAndSale(
              //   numerator,
              //   1000,
              //   price,
              //   currencies[currency]
              // );
              const collection = collectionItems.find(
                  (item) => item.id === collectionId
              );
              console.log(collection);
              const creatorErc721Contract = new ethers.Contract(
                  collection.nftAddress,
                  creatorErc721Abi,
                  signer
              );
              const tx = await creatorErc721Contract.mintByOwner();
              const receipt = await tx.wait();
              console.debug(tx, receipt);

              const { data: mintResponse } = await apiClient.post(
                  "upload/nftMinted",
                  {
                    uploadCollectionAssetId: response.data.uploadCollectionAssetId,
                    hash: receipt.transactionHash,
                  }
              );
              alert("민트에 성공했습니다.");
              console.log(`nftMintedResponse = ${JSON.stringify(mintResponse)}`);
              const { collectionAssetId } = mintResponse;
              navigate(`/item/${collectionAssetId}`);
            }
          } catch (e) {
            alert(`에러가 발생하셨습니다. ${e}`)
          } finally {
            setPageLoading(false);
          }
        },
        onFail: (e) => {
          setPageLoading(false);
          console.error(`mutateNftInfo error = ${e}`);
        },
      });
    } else {
      const nftCreateData = {
        collectionId: collectionId,
        nftUploadFileId: nftUrlUploadId,
        detailUploadFileId: goodUrlUploadId,
        name: itemName.value,
        description: description.value,
        externalUrl: externalLink.value,
      };
      mutateCreateCollectionAsset(nftCreateData, {
        onSuccess: async (response) => {
          console.log(`nftCreateResponse = ${JSON.stringify(response)}`);
          const { data } = response;
          const { collectionAssetURI } = data;
          navigate(`/item/${collectionAssetURI}`);
        },
        onFail: async (e) => {
          console.log(`mutateCreate error = ${JSON.stringify(e)}`);
        },
      });
    }
  };

  return (
    <main>
      <section className="relative py-24">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src={gradient_light} alt="gradient" className="h-full w-full" />
        </picture>
        <form action="api" onSubmit={handleSubmit}>
          <div className="container">
            <h1 className="py-16 text-center font-display text-4xl font-medium text-jacarta-700 dark:text-white">
              {t("create_nft.create")}
            </h1>

            <div className="mx-auto max-w-[48.125rem]">
              <div className="mb-6">
                <label className="mb-2 block font-display text-jacarta-700 dark:text-white">
                  {t("create_nft.image_video")}
                  <span className="text-red">*</span>
                </label>
                <p className="mb-3 text-2xs dark:text-jacarta-300">
                  {t("create_nft.image_video_desc")}
                </p>
                {properties && (
                  <Modal
                    styledWrapper="modal-dialog max-w-2xl"
                    setModalIsOpen={setProperties}
                    title="Add properties"
                    btnTitle="Save"
                    childrenBody={<AddProperties />}
                  />
                )}
                {levels && (
                  <Modal
                    styledWrapper="modal-dialog max-w-2xl"
                    setModalIsOpen={setLevels}
                    title="Add levels"
                    btnTitle="Save"
                    childrenBody={<AddLevels />}
                  />
                )}
                {stats && (
                  <Modal
                    styledWrapper="modal-dialog max-w-2xl"
                    setModalIsOpen={setStats}
                    title="Add stats"
                    btnTitle="Save"
                    childrenBody={<AddLevels />}
                  />
                )}

                <FileInput
                  onImageUploaded={(uploadFileId, filePath) => {
                    console.log(`uploadFileId = ${uploadFileId}`);
                    setNftUrlUploadId(uploadFileId);
                  }}
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="item-name"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_nft.name")}
                  <span className="text-red">*</span>
                </label>
                <input
                  type="text"
                  id="item-name"
                  name="itemName"
                  className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent
                  dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                  placeholder={t("create_nft.name_hint")}
                  required
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="item-name"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_nft.goods")}
                  <span className="text-red">*</span>
                </label>
                <p className="mb-3 text-2xs dark:text-jacarta-300">
                  {t("create_nft.goods_desc")}
                </p>
                <div className="cs-large_radio_group mb-4">
                  <div className="cs-large_radio">
                    <input
                      type="radio"
                      value="GOODS"
                      checked={selectedOption === "GOODS"}
                      onChange={onValueChange}
                    />
                    <div className="cs-large_radio_in">
                      <h5>{t("create_nft.goods_included")}</h5>
                    </div>
                  </div>
                  <div className="cs-large_radio">
                    <input
                      type="radio"
                      value="NOTGOODS"
                      checked={selectedOption === "NOTGOODS"}
                      onChange={onValueChange}
                    />
                    <div className="cs-large_radio_in">
                      <h5>{t("create_nft.goods_not_included")}</h5>
                    </div>
                  </div>
                </div>

                {selectedOption === "GOODS" && (
                  <div className="mb-6">
                    <label className="mb-2 block font-display text-green dark:text-white">
                      Register detailed image<span className="text-red">*</span>
                    </label>
                    <p className="mb-3 text-2xs dark:text-jacarta-300">
                      Drag or choose your file to upload
                    </p>

                    <FileInput
                      onImageUploaded={(uploadFileId, filePath) => {
                        console.log(`uploadFileId = ${uploadFileId}`);
                        setGoodUrl(filePath);
                        // setGoodUrlUploadId(uploadFileId); // using url instead
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="mb-6">
                <label
                  htmlFor="item-external-link"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_nft.external_link")}
                </label>
                <p className="mb-3 text-2xs dark:text-jacarta-300">
                  {t("create_nft.external_link_desc")}
                </p>
                <input
                  name="externalLink"
                  type="url"
                  id="item-external-link"
                  className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent
                  dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                  placeholder="https://yoursite.io/item/123"
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="item-description"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_nft.description")}
                </label>
                <p className="mb-3 text-2xs dark:text-jacarta-300">
                  {t("create_nft.description_desc")}
                </p>
                <textarea
                  name="description"
                  id="item-description"
                  className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent
                  dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                  rows="4"
                  // required
                  placeholder={t("create_nft.description_hint")}
                ></textarea>
              </div>
              <div className="relative">
                <div>
                  <label className="mb-2 block font-display text-jacarta-700 dark:text-white">
                    {t("create_nft.collection")}
                  </label>
                  <div className="mb-3 flex items-center space-x-2">
                    <p className="text-2xs dark:text-jacarta-300">
                      {t("create_nft.collection_desc")}
                    </p>
                  </div>
                </div>

                <div
                  className="dropdown my-1 cursor-pointer"
                  onClick={() => setCollectionIsOpen((prev) => !prev)}
                >
                  <div
                    className="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300"
                    role="button"
                    id="item-collection"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="">
                      {collectionName
                        ? collectionName
                        : t("create_nft.collection_hint")}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-4 w-4 fill-jacarta-500 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                    </svg>
                  </div>
                  {collectionIsOpen && (
                    <CreateCollectionMenu
                      collectionItems={collectionItems}
                      onClick={(id, collectionName) => {
                        setCollectionId(id);
                        setCollectionName(collectionName);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="mt-6 mb-6">
                <label
                  htmlFor="item-supply"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_nft.supply")}
                </label>

                <div className="mb-3 flex items-center space-x-2">
                  <p className="text-2xs dark:text-jacarta-300">
                    {t("create_nft.supply_desc")}
                  </p>
                </div>

                <input
                  readonly
                  type="text"
                  id="item-supply"
                  name="supply"
                  className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                  placeholder="1"
                  value={"1"}
                />
              </div>

              <div className="mt-6 mb-6">
                <label
                  htmlFor="item-supply"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_nft.blockchain")}
                </label>

                <div
                  className="dropdown relative mb-4 cursor-pointer"
                  onClick={() => setBlockchainIsOpen((prev) => !prev)}
                >
                  <div
                    className="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white
                    py-3.5 px-3 text-base dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white"
                    role="button"
                    id="item-blockchain"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="flex items-center">
                      <img
                        src={CORN}
                        alt="CORN"
                        className="mr-2 h-5 w-5 rounded-full"
                      />
                      CORN
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-4 w-4 fill-jacarta-500 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                    </svg>
                  </div>
                  {blockchainIsOpen && (
                    <CreateBlockchainMenu
                      onClick={(id) => {
                        setMintCurrency(id);
                      }}
                    />
                  )}
                </div>
              </div>
              <button className="cursor-default rounded-full bg-accent-lighter py-3 px-8 text-center font-semibold text-white transition-all">
                Create
              </button>
            </div>
          </div>
        </form>
      </section>
      {isPageLoading ? (
        <Loading loading background="#22000000" loaderColor="#3498db" />
      ) : null}
    </main>
  );
};

export { NFTItemUpload };
