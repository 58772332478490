import React, { useEffect, useState } from "react";
import apiClient from "../../apiClient";
import gradient_light from "../../img/gradient_light.jpg";
import { CommunityList } from "../../components/CommunityList";
import Loading from "react-fullscreen-loading";
import create from "zustand";

import PersistentAppState from "../../PersistentAppState";
import { useTranslation } from "react-i18next";
import { useUserStore } from "../../useUserStore";
//const useUserStore = create(PersistentAppState);

const CommunityPage = () => {
  const { t, i18n } = useTranslation();
  const userdata = useUserStore((state) => state.userdata);
  const setUserdata = useUserStore((state) => state.setUserdata);
  const [ImageUrl, setImageUrl] = useState([]);
  const [isCommunityList, setCommunityList] = useState([]);
  const [isPageLoading, setPageLoading] = useState(false);
  const [Page, setPage] = useState(1);
  const [PageEndFlag, setPageEndFlag] = useState(false);

  async function communityList() {
    setPageLoading(true);
    let { data } = await apiClient.get(`/community/${Page}`);
    if (data && data.result === "ok") {
      setImageUrl(data.image_url);
      setCommunityList([...isCommunityList, ...data.communityList.items]);
      setPageEndFlag(Page >= data.endPage ? true : false);
    }
    setPageLoading(false);
  }

  useEffect(() => {
    communityList();
  }, [Page]);

  return (
    <main className="pt-[5.5rem] lg:pt-24">
      <section className="relative py-16 md:py-24">
        <h2 className="mb-10 text-center font-display text-2xl font-medium text-jacarta-700 dark:text-white">
          {t("community.community")}
        </h2>
        <p className="mx-auto mb-10 text-center text-lg dark:text-jacarta-300">
          {t("community.community_desc")}
        </p>
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src={gradient_light} alt="gradient" className="h-full w-full" />
        </picture>
        <div className="container">
          <div className="grid grid-cols-1 gap-[1.875rem] sm:grid-cols-2 md:grid-cols-3">
            {isPageLoading ? (
              <Loading loading background="#22000000" loaderColor="#3498db" />
            ) : null}
            {isCommunityList &&
              isCommunityList.map((item) => (
                <CommunityList
                  key={item.id}
                  data={item}
                  imageUrl={ImageUrl}
                  userdata={userdata}
                />
              ))}
          </div>

          {!PageEndFlag ? (
            <div className="pointer mt-10 text-center">
              <span
                className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                onClick={() => {
                  setPage(Page + 1);
                }}
              >
                Load More
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </main>
  );
};

export { CommunityPage };
