let AppState = {
    x : 1,
    nftListData: {},
    profile: {},
    isLogin: false,
    address: '',
    ethBalance: 0,
    nftRepresentatives: [],
}
export default AppState;
