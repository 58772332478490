import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { ActivitySearch } from '../ActivitySearch';
import avatar_2 from '../../img/avatars/avatar_2.jpg';
import item_21_sm from '../../img/products/item_21_sm.jpg';
import item_22_sm from '../../img/products/item_22_sm.jpg';
import item_23_sm from '../../img/products/item_23_sm.jpg';
import item_24_sm from '../../img/products/item_24_sm.jpg';
import moment from "moment";
import {useTranslation} from "react-i18next";

const Activity = ({activities}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const getItemByType = (item) => {
    const itemImageUrl = item.collectionAssetImageUrl;
    const collectionAssetId = item.collectionAssetId;

    switch (item.activityType) {
      case "START_SALE":
        return (
            <Link
                to={`/item/${collectionAssetId}`}
                className='mb-4 relative flex items-center rounded-2.5xl border border-jacarta-100 bg-white p-8 transition-shadow hover:shadow-lg
		dark:border-jacarta-700 dark:bg-jacarta-700'>
              <figure className='mr-5 self-start'>
                <img src={itemImageUrl ? itemImageUrl : item_21_sm} alt='item 3' className='rounded-2lg w-10' loading='lazy' />
              </figure>

              <div>
                <h3 className='mb-1 font-display text-base font-semibold text-jacarta-700 dark:text-white'>
                  {item.collectionAssetName}
                </h3>
                <span className='mb-3 block text-sm text-jacarta-500 dark:text-jacarta-200'>
                    {t('activity.list_by')} <a href={`/user/${item.fromAddress}`} >{item.fromAddress}</a> {item && item.price && Number(item.price).toLocaleString('en')} CORN
            </span>
                <span className='block text-xs text-jacarta-300'>{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
              </div>

              <div className='ml-auto rounded-full border border-jacarta-100 p-3 dark:border-jacarta-600'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='fill-jacarta-700 dark:fill-white'>
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path
                      d='M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm.707 2.122L3.828
				12l8.486 8.485 7.778-7.778-1.06-7.425-7.425-1.06zm2.12 6.364a2 2 0 1 1 2.83-2.829 2 2 0 0 1-2.83 2.829z'
                  />
                </svg>
              </div>
            </Link>
        )
      case "BUY":
        return (
            <Link
                to={`/item/${collectionAssetId}`}
                className='mb-4 relative flex items-center rounded-2.5xl border border-jacarta-100 bg-white p-8 transition-shadow hover:shadow-lg
		dark:border-jacarta-700 dark:bg-jacarta-700'>
              <figure className='mr-5 self-start'>
                <img src={itemImageUrl ? itemImageUrl : item_21_sm} alt='item 3' className='rounded-2lg w-10' loading='lazy' />
              </figure>

              <div>
                <h3 className='mb-1 font-display text-base font-semibold text-jacarta-700 dark:text-white'>
                  {item.collectionAssetName}
                </h3>
                <span className='mb-3 block text-sm text-jacarta-500 dark:text-jacarta-200'>
              {t('activity.transferred_from')}  <a href={`/user/${item.fromAddress}`} >{item.fromAddress}</a>
            </span>
                <span className='block text-xs text-jacarta-300'>{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
              </div>

              <div className='ml-auto rounded-full border border-jacarta-100 p-3 dark:border-jacarta-600'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='fill-jacarta-700 dark:fill-white'>
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path
                      d='M16.05 12.05L21 17l-4.95 4.95-1.414-1.414 2.536-2.537L4 18v-2h13.172l-2.536-2.536 1.414-1.414zm-8.1-10l1.414 1.414L6.828
				6 20 6v2H6.828l2.536 2.536L7.95 11.95 3 7l4.95-4.95z'
                  />
                </svg>
              </div>
            </Link>
        )

      case "MINTED":
        return (
            <Link
                to={`/item/${collectionAssetId}`}
                className='mb-4 relative flex items-center rounded-2.5xl border border-jacarta-100 bg-white p-8 transition-shadow hover:shadow-lg
		dark:border-jacarta-700 dark:bg-jacarta-700'>
              <figure className='mr-5 self-start'>
                <img src={itemImageUrl ? itemImageUrl : item_21_sm} alt='item 3' className='rounded-2lg w-10' loading='lazy' />
              </figure>

              <div>
                <h3 className='mb-1 font-display text-base font-semibold text-jacarta-700 dark:text-white'>
                  {item.collectionAssetName}
                </h3>
                <span className='mb-3 block text-sm text-jacarta-500 dark:text-jacarta-200'>
              {t('activity.minted_by')}  <a href={`/user/${item.toAddress}`} >{item.toAddress}</a> {item && item.price && Number(item.price).toLocaleString('en')} CORN
            </span>
                <span className='block text-xs text-jacarta-300'>{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
              </div>

              <div className='ml-auto rounded-full border border-jacarta-100 p-3 dark:border-jacarta-600'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='fill-jacarta-700 dark:fill-white'>
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path
                      d='M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm.707 2.122L3.828
				12l8.486 8.485 7.778-7.778-1.06-7.425-7.425-1.06zm2.12 6.364a2 2 0 1 1 2.83-2.829 2 2 0 0 1-2.83 2.829z'
                  />
                </svg>
              </div>
            </Link>
        )
      default:
        return null;
    }
  }

  return (
        (activities && activities.length > 0) &&
        activities.map(item => getItemByType(item))
  );
};

export {Activity};
