import {ethers} from "ethers";
import {currencyById} from "./currencies";
import {erc20Abi} from "./erc20Abi";

const getCornBalance = async (ethereum) => {
    const provider = ((window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum) : ethers.providers.getDefaultProvider());
    const signer = provider.getSigner();
    const signerAddress = await signer.getAddress();
    const currencyAddress = currencyById(2);
    const erc20Contract = new ethers.Contract(
        currencyAddress,
        erc20Abi,
        signer
    );
    const erc20Balance = await erc20Contract.balanceOf(signerAddress);
    const balanceBn = ethers.BigNumber.from(erc20Balance);
    console.log('getCornBalance', ethers.utils.formatUnits(balanceBn, 18));
    return Number(ethers.utils.formatUnits(balanceBn, 18)).toLocaleString('en');
}


export {getCornBalance};
