import React from "react";
import { Link } from "react-router-dom";
// import avatar_1 from '../../img/avatars/avatar_1.jpg';
// import item_1 from '../../img/products/item_1.jpg';
import { GridNFT } from "../GridNFT";
import apiClient from "../../apiClient";
import {useMediaQuery} from "react-responsive";

const TopCreatorMain = ({ data }) => {
  const isMobile = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  /*    async function doFavorites(flag, entityType, entityId) {
        const path = flag ? `social/createFavorites` : `social/destroyFavorites`;
        const {data: createFavoritesData} = await apiClient.post(path,{
            entityType,
            entityId,
        });
        console.log(`doFavorites = ${JSON.stringify(createFavoritesData)}`);
    }*/

  const classnamePc = "grid gap-3 md:gap-[1.875rem] grid-cols-4";
  const classnameMobile = "grid gap-3 md:gap-[1.875rem] grid-cols-2";

  return (
    <div>
      <section className="relative dark:bg-jacarta-800">
        <div className="container">
          <div className="mb-12 text-center font-display text-3xl text-jacarta-700 dark:text-white">
            <div className="dropdown inline cursor-pointer"></div>
          </div>
          <div className={!isMobile ? classnameMobile : classnamePc}>
            {
              (data && data.length) &&
                data.map((item, index) => {
                return (
                  <GridNFT
                    key={index}
                    item={item}
                    itemName={item.name}
                    price={item.price}
                    imageUrl={item.imageUrl}
                    id={item.id}
                    hash={item.hash}
                    favoriteFlag={item.isFavoriteYn === "Y"}
                    favoriteCount={item.favoriteCount}
                    saleStatus={item.saleStatus}
                  />
                );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export { TopCreatorMain };
