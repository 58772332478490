import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import a1a from '../../img/chains/a1a.png';
import CORN from '../../img/chains/CORN.png';
import {RESOURCE_BASE_URL} from "../../Constant";
import {Modal} from "../Modal";
import {ModalBodyTerms} from "../ModalBodyTerms";
import PriceLabel from "../PriceLabel/PriceLabel";
import {useTranslation} from "react-i18next";

const ModalBodyBuy = ({item, balance}) => {
    const { t, i18n } = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
      <div className="modal-body p-6">
          {modalIsOpen && (
              <Modal
                  setModalIsOpen={setModalIsOpen}
                  title=''
                  btnTitle=''
                  childrenBody={
                      <ModalBodyTerms
                          item={item}/>}
                  styledWrapper='modal-dialog max-w-2xl'
              />
          )}

          <div className="mb-2 flex items-center justify-between">
              <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">{t('common.item')}</span>
              <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">{t('common.subtotal')}</span>
          </div>

          <div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-t border-b py-4">
              <figure className="mr-5 self-start">
                  <img src={item && (item.imageUrl)} alt="" className="rounded-2lg w-16 aspect-square" loading="lazy"/>
              </figure>

              <div>
                  <a href="collection.html" className="text-accent text-sm">{(item && item.name)}</a>
                  <h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-white">
                      {(item && item.collectionTitle)}
                  </h3>
                  <div className="flex flex-wrap items-center">
                      <span
                          className="dark:text-jacarta-300 text-jacarta-500 mr-1 block text-sm">Creator Earnings: 5%</span>
                      <span
                          data-tippy-content="The creator of this collection will receive 5% of the sale total from future sales of this item."
                      >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="dark:fill-jacarta-300 fill-jacarta-700 h-4 w-4"
                    >
                      <path fill="none" d="M0 0h24v24H0z"/>
                      <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"
                      />
                    </svg>
                  </span>
                  </div>
              </div>

              <div className="ml-auto">
                <span className="mb-1 flex items-center whitespace-nowrap">
                  <span className="-ml-1 mr-1">
                      <img
                          src={CORN}
                          width="15"
                          height="15"
                      />
                  </span>
                  <span className="dark:text-jacarta-100 text-sm font-medium tracking-tight">{(item && Number(item.price).toLocaleString('en'))} CORN</span>
                </span>
                  <div className="dark:text-jacarta-300 text-right text-sm">
                      <PriceLabel
                          tokenPrice={item.price}
                          currency={'usd'}
                      />
                  </div>
              </div>
          </div>

          <div
              className="dark:border-jacarta-600 border-jacarta-100 mb-2 flex items-center justify-between border-b py-2.5"
          >
              <span className="font-display text-jacarta-700 font-semibold dark:text-white">Total</span>
              <div className="ml-auto">
                <span className="flex items-center whitespace-nowrap">
                  <span className="-ml-1 mr-1">
                      <img
                          src={CORN}
                          width="15"
                          height="15"
                      />
                  </span>
                  <span className="text-green font-medium tracking-tight">{(item && Number(item.price).toLocaleString('en'))} CORN</span>
                </span>
                  <div className="dark:text-jacarta-300 text-right">
                      <PriceLabel
                          tokenPrice={item.price}
                          currency={'usd'}
                      />
                  </div>
              </div>
          </div>

          <div className="mt-4 flex items-center space-x-2">
              <input
                  type="checkbox"
                  id="buyNowTerms"
                  className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
              />
              <label htmlFor="buyNowTerms" className="dark:text-jacarta-200 text-sm"
              >{t('phrase.check_agree_1')} <a onClick={() => {
                  setModalIsOpen(true);
              }} className="text-accent">{t('phrase.check_agree_2')}</a></label
              >
          </div>
      </div>
  );
};

export { ModalBodyBuy };
