import React, { useState, useEffect } from "react";
import Loading from "react-fullscreen-loading";
import owner_5 from "../../img/avatars/owner_5.png";
import apiClient from "../../apiClient";
import { CommunityModal } from "../CommunityModal";
import { RESOURCE_BASE_URL } from "../../Constant";

const CommunityComments = (props) => {
  const comments = props.comment;
  const setComments = props.setComment;
  const postId = props.postId;
  const userinfo = props.user;

  const inputRef = React.useRef({});
  const [inputs, setInput] = useState({});
  const [isReplayOpen, setReplayOpen] = useState({});
  const [isUpdateOpen, setUpdateOpen] = useState({});
  const [isPageBoardLoading, setPageBoardLoading] = useState(false);
  const [isCurrentCommentId, seCurrentCommentId] = useState();
  const [isUpdateComment, setUpdateComment] = useState();

  const [isModal, setModal] = useState({
    state: false,
    header: "",
    contents: "",
    modaltype: {},
  });

  const deleteComment = (currentId) => {
    const confirmDelete = async () => {
      setPageBoardLoading(true);
      const param = {
        id: currentId,
        flag: false,
      };

      const { data } = await apiClient.post("/community/updateComment/", param);
      if (data.result === "ok") {
        setComments(comments.filter((comments) => comments.id !== currentId));
      }
      setModal({ state: false });
      setPageBoardLoading(false);
    };

    comments.find((element) => {
      return element.parentid === currentId && element.depth === 1;
    })
      ? setModal({
          state: true,
          header: "Community",
          contents: "하위 댓글이 있는 경우 삭제가 불가능합니다.",
          modaltype: {
            gubun: "alert",
            btnoktxt: "확인",
          },
        })
      : setModal({
          state: true,
          header: "Community",
          contents: "완전히 삭제하시겠습니까?",
          modaltype: {
            gubun: "confirm",
            btnoktxt: "확인",
            btnOkEvent: confirmDelete,
            btncanceltxt: "취소",
          },
        });

    //alert(item.id);
  };

  async function saveCommunityCommentReplay(repidx, item) {
    if (inputs[repidx] === undefined || inputs[repidx].trim().length === 0) {
      inputRef.current[repidx].focus();
      return;
    }

    const param = {};
    param.postid = parseInt(postId);
    param.comment = inputs[repidx];
    param.parentid = item.id;
    param.depth = 1;

    if (isUpdateComment) {
      param.id = isUpdateComment;
      param.depth = item.depth;
      setUpdateComment("");
    }

    setPageBoardLoading(true);
    const { data } = await apiClient.post("/community/updateComment/", param);

    if (data.result === "no_user") {
      alert(data.result);
    }
    if (data.result === "ok") {
      setReplayOpen({
        ...isReplayOpen,
        [item.id]: false,
      });

      setUpdateOpen({
        ...isUpdateOpen,
        [item.id]: false,
      });

      const append_map = [];

      if (isUpdateComment) {
        comments.map((mapitem, idx) =>
          mapitem.id === data.comments.id
            ? append_map.push(data.comments)
            : append_map.push(mapitem)
        );
      } else {
        comments.map((mapitem, idx) => append_map.push(mapitem));
        append_map.splice(repidx + 1, 0, data.comments);
      }

      setComments(append_map);
    }
    setPageBoardLoading(false);
  }

  const toggleOpen = (id, idx) => {
    setReplayOpen({
      ...isReplayOpen,
      [id]: !isReplayOpen[id],
    });

    setUpdateOpen({
      ...isUpdateOpen,
      [id]: !isUpdateOpen[id],
    });

    inputRef.current[idx].value = "";
  };

  const setCommectInput = (e, idx) => {
    setInput({
      ...inputs,
      [idx]: e.target.value,
    });
  };

  const updateShowComment = (item, idx) => {
    toggleOpen(item.id, idx);
    inputRef.current[idx].value = item.comment;
    setUpdateComment(item.id);
  };

  useEffect(() => {}, []);

  return (
    <div>
      {isPageBoardLoading ? (
        <Loading loading background="#22000000" loaderColor="#3498db" />
      ) : null}

      {comments &&
        comments.map((item, idx) => (
          <li className="media comment-item" key={item.id}>
            <div className={`text-left ${item.depth > 0 ? "ml-12" : ""}`}>
              <div className="mb-2 flex flex-wrap items-center space-x-2 text-sm text-jacarta-400">
                <img
                  src={
                    item.profileImg
                      ? RESOURCE_BASE_URL + item.profileImg
                      : owner_5
                  }
                  alt="owner"
                  className="h-6 w-6 rounded-full"
                />
                <span className="mr-12 text-2xs font-medium tracking-tight text-jacarta-700">
                  {item.username ? item.username : "nouser-" + item.user_id}
                </span>
                <span className="flex-wrap space-x-2 text-right text-sm text-jacarta-400">
                  {item.updatedAt}
                </span>
              </div>

              <div className="ml-12 flex-wrap text-left text-base font-medium tracking-tight text-jacarta-400">
                <pre>
                  {item.isUsed
                    ? item.comment
                    : "관리자에 의하여 삭제되었습니다."}
                </pre>
              </div>

              <div className="mb-2 mr-8 flex-wrap text-right text-xs tracking-tight text-jacarta-400">
                <div
                  className={
                    isUpdateOpen[item.id] ? "comment-item-reply-hide" : ""
                  }
                >
                  {item.depth === 0 ? (
                    <>
                      <span
                        className="pointer"
                        onClick={() => {
                          toggleOpen(item.id, idx);
                        }}
                      >
                        Reply
                      </span>
                    </>
                  ) : (
                    ""
                  )}

                  {item.depth === 0 &&
                  userinfo.id === item.user_id &&
                  item.isUsed ? (
                    <span className="mr-1 ml-1">•</span>
                  ) : (
                    ""
                  )}

                  {userinfo.id === item.user_id && item.isUsed ? (
                    <>
                      <span
                        className="pointer"
                        onClick={() => {
                          updateShowComment(item, idx);
                        }}
                      >
                        Update
                      </span>
                      <span className="ml-1 mr-1">•</span>
                      <span
                        className="pointer"
                        onClick={() => {
                          deleteComment(item.id);
                        }}
                      >
                        Delete
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    isReplayOpen[item.id] ? "" : "comment-item-reply-hide"
                  }
                >
                  <div className="mt-3">
                    <textarea
                      id="item-description2"
                      className="comment-item-reply w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      rows="1"
                      required
                      placeholder=""
                      onChange={(e) => {
                        setCommectInput(e, idx);
                      }}
                      ref={(element) => (inputRef.current[idx] = element)}
                    ></textarea>
                  </div>
                  <div className="mt-3">
                    <span
                      className="pointer"
                      onClick={() => {
                        toggleOpen(item.id, idx);
                      }}
                    >
                      Cancel
                    </span>
                    <span> • </span>
                    <span
                      className="pointer"
                      onClick={() => {
                        saveCommunityCommentReplay(idx, item);
                      }}
                    >
                      Send Replay
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      {/* 
      <CommunityModal
        open={isConfirmModalOpen}
        close={closeModal}
        confirm={confirmModal}
        header="댓글 삭제"
      >
        완전히 삭제할까요?
      </CommunityModal>

      <CommunityModal
        open={isAlertModalOpen}
        close={closeModal}
        header="댓글 삭제"
      >
        하위 댓글이 있는 경우 삭제가 불가능합니다.
      </CommunityModal> */}
      <CommunityModal modal={isModal} modalState={setModal} />
    </div>
  );
};

export { CommunityComments };
