import React, { useState } from 'react';
import { Filter } from './Filter';
import { PriceRange } from './PriceRange';
import {useTranslation} from "react-i18next";

const UserFilters = ({onClick}) => {
  const { t, i18n } = useTranslation();
  const [priceRangeIsOpen, setPriceRangeIsOpen] = useState(false);
  const [recentlyIsOpen, setRecentlyIsOpen] = useState(false);
  const [sorting, setSorting] = useState('Recent');

  function range() {
    setPriceRangeIsOpen((prev) => !prev);
    setRecentlyIsOpen(false);
  }
  function recently() {
    setRecentlyIsOpen((prev) => !prev);
    // setPriceRangeIsOpen(false);
  }

  const handleParentClick = event => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
    } else {
      setRecentlyIsOpen((prev) => !prev);
    }
  };

  function displaySortingName() {
    switch (sorting) {
      case 'Recent':
        return t('common.recently_added');
      case 'PriceDesc':
        return t('common.price_high_to_low');
      case 'PriceAsc':
        return t('common.price_low_to_high');
      default:
        return '';
    }
  }

  return (
    <div className='mb-8 flex flex-wrap items-center justify-between'>
      <div className='flex flex-wrap items-center'>
      </div>

      <div className='dropdown relative my-1 cursor-pointer' onClick={() => recently()}>
        <div
          className='dropdown-toggle inline-flex w-48 items-center justify-between rounded-lg border border-jacarta-100
				bg-white py-2 px-3 text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'
          role='button'
          id='ownedSort'
          data-bs-toggle='dropdown'
          aria-expanded='false'>
          <span className='font-display'>{displaySortingName()}</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className='h-4 w-4 fill-jacarta-500 dark:fill-white'>
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
          </svg>
        </div>
        {recentlyIsOpen && <Filter
            setRecentlyIsOpen={setRecentlyIsOpen}
            onClick={(sorting) => {
              if(onClick) {
                setSorting(sorting);
                onClick(sorting);
                // setRecentlyIsOpen(false);
              }
            }}/>}
      </div>
    </div>
  );
};

export { UserFilters };
