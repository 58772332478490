import React, { useState } from "react";
import { Link } from "react-router-dom";
import a1a from "../../img/chains/a1a.png";
import CORN from "../../img/chains/CORN.png";
import PriceLabel from "../PriceLabel/PriceLabel";
import { useTranslation } from "react-i18next";
const ModalBodySell = ({ item, balance, onChangePrice }) => {
  const { t, i18n } = useTranslation();
  const [price, setPrice] = useState("0.00");

  return (
    <div className="modal-body p-6">
      <div className="mb-2 flex items-center justify-between">
        <span className="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
          Price
        </span>
      </div>

      <div className="relative mb-2 flex items-center overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600">
        <div className="flex flex-1 items-center self-stretch border-r border-jacarta-100 bg-jacarta-50 px-2">
          <span>
            <img src={CORN} width="20" height="20" />
          </span>
          <span className="font-display text-sm text-jacarta-700">CORN</span>
        </div>

        <input
          type="text"
          className="h-12 w-full flex-[3] border-0 focus:ring-inset focus:ring-accent"
          placeholder="Amount"
          value={price}
          onChange={(e) => {
            setPrice(e.target.value);
            if (onChangePrice) {
              onChangePrice(e);
            }
          }}
        />

        <div className="flex flex-1 justify-end self-stretch border-l border-jacarta-100 bg-jacarta-50">
          <span className="self-center px-2 text-sm">
            <PriceLabel tokenPrice={price} currency={"usd"} />
          </span>
        </div>
      </div>

      <div className="text-right">
        <span className="text-sm dark:text-jacarta-400">
          Balance: {balance} CORN
        </span>
      </div>

      {item && (item.actualOwnerAccountAddress !== item.creatorAccount) &&
        item.isPhysicallyBacked && (
          <div className="mt-4 flex items-center space-x-2">
            <input
              type="checkbox"
              id="escrowSellNowTerms"
              className="h-5 w-5 self-start rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600"
            />
            <label
              htmlFor="sellNowTerms"
              className="text-sm dark:text-jacarta-200"
            >
              {t("phrase.check_escrow_agree")}
            </label>
          </div>
        )}

      <div className="mt-4 flex items-center space-x-2">
        <input
          type="checkbox"
          id="sellNowTerms"
          className="h-5 w-5 self-start rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600"
        />
        <label htmlFor="sellNowTerms" className="text-sm dark:text-jacarta-200">
          {t("phrase.check_agree_1")}{" "}
          <a href="#" className="text-accent">
            {t("phrase.check_agree_2")}
          </a>
        </label>
      </div>
    </div>
  );
};

export { ModalBodySell };
