import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

const Filter = ({onClick, setRecentlyIsOpen}) => {
    const { t, i18n } = useTranslation();
    const [sorting, setSorting] = useState('Recent');
    const checkedView = () => {
        return (<svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-accent h-4 w-4"
        >
            <path fill="none" d="M0 0h24v24H0z"/>
            <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"/>
        </svg>)
    }

    const handleRecentClick = event => {
        setRecentlyIsOpen(false);

        event.stopPropagation();
        // setSorting('');
        setSorting('Recent');
        if(onClick) {
            onClick('Recent');
        }
    };

    const handlePriceDescClick = event => {
        setRecentlyIsOpen(false);

        event.stopPropagation();
        // setSorting('');
        setSorting('PriceDesc');
        if(onClick) {
            onClick('PriceDesc');
        }
    };

    const handlePriceAscClick = event => {
        setRecentlyIsOpen(false);
        event.stopPropagation();
        // setSorting('');
        setSorting('PriceAsc');
        if(onClick) {
            onClick('PriceAsc');
        }
    };

  return (
    <div
      className='dropdown-menu show z-10 hidden min-w-[220px] whitespace-nowrap rounded-xl
			bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800'
      aria-labelledby='onSaleSort'
      style={{
        position: 'absolute',
        inset: '0px auto auto 0px',
        margin: '0px',
        top: '-290px',
        left: 0,
        transform: 'translateY(100%)',
      }}>
      <span className='block px-5 py-2 font-display text-sm font-semibold text-jacarta-300'>
          {t('common.sort_by')}
      </span>
      <button
          onClick={handleRecentClick}
        className='dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display
			text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'>
          {t('common.recently_added')}
          {/*{(sorting === 'Recent') ? checkedView() : null}*/}
      </button>
      <button
          onClick={handlePriceAscClick}
          className='dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display
			text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'>
          {t('common.price_low_to_high')}
          {/*{(sorting === 'PriceAsc') ? checkedView() : null}*/}
      </button>

      <button
          onClick={handlePriceDescClick}
          className='dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display
			text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'>
          {t('common.price_high_to_low')}
          {/*{(sorting === 'PriceDesc') ? checkedView() : null}*/}
      </button>
    </div>
  );
};

export { Filter };
