import React from 'react';
import { Link } from 'react-router-dom';
import {trimString} from "../../../JsUtils";
import moment from "moment";
import CORN from "../../../img/chains/CORN.png";
import {useTranslation} from "react-i18next";

const Activity = ({activities}) => {
  const { t, i18n } = useTranslation();

  const convertEvent = (activity) => {
    switch (activity) {
      case 'START_SALE':
        return 'LIST';
      case 'BUY':
        return 'SALE';
      case 'MINTED':
        return 'MINTED';
      default:
        break;
    }
  }

  return (
    <div
      // className='tab-pane fade'
      id='activity'
      role='tabpanel'
      aria-labelledby='activity-tab'>
      {/* Filter */}
      <div
        role='table'
        className='scrollbar-custom max-h-72 w-full overflow-y-auto rounded-lg rounded-tl-none border border-jacarta-100 bg-white
				text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'>
        <div className='sticky top-0 flex bg-light-base dark:bg-jacarta-600' role='row'>
          <div className='w-[17%] py-2 px-4' role='columnheader'>
            <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
              {t('common.event')}
            </span>
          </div>
          <div className='w-[17%] py-2 px-4' role='columnheader'>
            <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
              {t('common.price')}
            </span>
          </div>
          <div className='w-[22%] py-2 px-4' role='columnheader'>
            <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
              {t('common.from')}
            </span>
          </div>
          <div className='w-[22%] py-2 px-4' role='columnheader'>
            <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
              {t('common.to')}
            </span>
          </div>
          <div className='w-[22%] py-2 px-4' role='columnheader'>
            <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
              {t('common.date')}
            </span>
          </div>
        </div>
        {
          (activities && activities.length > 0) &&
          activities.map(item =>  <div className='flex' role='row'>
            <div
                className='flex w-[17%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                role='cell'>
              <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  className='mr-2 h-4 w-4 fill-jacarta-700 group-hover:fill-white dark:fill-white'>
                <path fill='none' d='M0 0h24v24H0z'></path>
                <path
                    d='M14 20v2H2v-2h12zM14.586.686l7.778 7.778L20.95 9.88l-1.06-.354L17.413 12l5.657 5.657-1.414 1.414L16 13.414l-2.404
							2.404.283 1.132-1.415 1.414-7.778-7.778 1.415-1.414 1.13.282 6.294-6.293-.353-1.06L14.586.686zm.707 3.536l-7.071 7.07 3.535
							3.536 7.071-7.07-3.535-3.536z'></path>
              </svg>
              {
                convertEvent(item.activityType)
              }
            </div>
            <div
                className='flex w-[17%] items-center whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                role='cell'>
            <span className='-ml-1' data-tippy-content='CORN'>
              {/*<svg
                  version='1.1'
                  xmlns='http://www.w3.org/2000/svg'
                  x='0'
                  y='0'
                  viewBox='0 0 1920 1920'
                  // xml:space='preserve'
                  className='mr-1 h-4 w-4'>
                <path fill='#8A92B2' d='M959.8 80.7L420.1 976.3 959.8 731z'></path>
                <path
                    fill='#62688F'
                    d='M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z'></path>
                <path fill='#454A75' d='M959.8 1295.4l539.8-319.1L959.8 731z'></path>
                <path fill='#8A92B2' d='M420.1 1078.7l539.7 760.6v-441.7z'></path>
                <path fill='#62688F' d='M959.8 1397.6v441.7l540.1-760.6z'></path>
              </svg>*/}
            </span>
              <span className="mr-1">
                      <img
                          src={CORN}
                          width="15"
                          height="15"
                      />
                  </span>
              <span className='text-sm font-medium tracking-tight text-green'>{item && Number(item.price).toLocaleString('en')} CORN</span>
            </div>
            <div
                className='flex w-[22%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                role='cell'>
              <Link to={`/item/${item.fromAddress}`} className='text-accent'>
                {trimString(item.fromAddress, 10)}
              </Link>
            </div>
            <div
                className='flex w-[22%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                role='cell'>
              <Link to={`/item/${item.toAddress}`} className='text-accent'>
                {trimString(item.toAddress, 10)}
              </Link>
            </div>
            <div
                className='flex w-[22%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                role='cell'>
              <a
                  href={`https://${process.env.REACT_APP_ENVIRONMENT === 'prod' ? '' : 'baobab.'}scope.klaytn.com/tx/${item.eventLogsHash}`}
                  className='flex flex-wrap items-center text-accent'
                  target='_blank'
                  rel='nofollow noopener'
                  title='Opens in a new window'
                  data-tippy-content='March 13 2022, 2:32 pm'>
                <span className='mr-1'>{moment(item.createdAt).format('YYYY-MM-DD')}</span>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='h-4 w-4 fill-current'>
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path
                      d='M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793
								7.794-1.414-1.414L17.585 5H13V3h8z'
                  />
                </svg>
              </a>
            </div>
          </div>)
        }
      </div>
    </div>
  );
};

export { Activity };
