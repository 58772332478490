import { ethers } from "ethers";


const REACT_APP_CURRENCIES_CORN = process.env.REACT_APP_CURRENCIES_CORN;
console.log(`process.env.REACT_APP_CONTRACT_SALE = ${REACT_APP_CURRENCIES_CORN}`);

export const currencies = {
  KLAY: ethers.constants.AddressZero,
  CORN: REACT_APP_CURRENCIES_CORN,//"0x6a6184283af9f62da739f8b309c5fca61e2f1400",
};

export const currencyById = (currencyId) => {
  const currencyOrder = ["KLAY", "CORN"];
  return currencies[currencyOrder[currencyId - 1]];
};
