import React, { useRef } from "react";
import SwipeElement from "./SwipeElement";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/scss/navigation";

import collection_1_1 from "../../img/collections/collection_1_1.jpg";
import collection_1_2 from "../../img/collections/collection_1_2.jpg";
import collection_1_3 from "../../img/collections/collection_1_3.jpg";
import collection_1_4 from "../../img/collections/collection_1_4.jpg";
import collection_2_1 from "../../img/collections/collection_2_1.jpg";
import collection_2_2 from "../../img/collections/collection_2_2.jpg";
import collection_2_3 from "../../img/collections/collection_2_3.jpg";
import collection_2_4 from "../../img/collections/collection_2_4.jpg";
import collection_3_1 from "../../img/collections/collection_3_1.jpg";
import collection_3_2 from "../../img/collections/collection_3_2.jpg";
import collection_3_3 from "../../img/collections/collection_3_3.jpg";
import collection_3_4 from "../../img/collections/collection_3_4.jpg";
import collection_4_1 from "../../img/collections/collection_4_1.jpg";
import collection_4_2 from "../../img/collections/collection_4_2.jpg";
import collection_4_3 from "../../img/collections/collection_4_3.jpg";
import collection_4_4 from "../../img/collections/collection_4_4.jpg";
import collection_5_1 from "../../img/collections/collection_5_1.jpg";
import collection_5_2 from "../../img/collections/collection_5_2.jpg";
import collection_5_3 from "../../img/collections/collection_5_3.jpg";
import collection_5_4 from "../../img/collections/collection_5_4.jpg";

import owner_1 from "../../img/avatars/owner_1.png";
import owner_2 from "../../img/avatars/owner_2.png";
import owner_3 from "../../img/avatars/owner_3.png";
import owner_4 from "../../img/avatars/owner_4.png";
import owner_5 from "../../img/avatars/owner_5.png";
import { CollectionElement } from "../CollectionElement";
import {useMediaQuery} from "react-responsive";

const SmSwiper = ({ data }) => {
  const swiperRef = useRef();
  const isPc = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const isLargerThanDisplay = () => {
    return data && data.length > 3;
  };

  return (
    <div className="relative">
      {/* Slider  */}
      {isLargerThanDisplay() && (
        <div
          onClick={() => {
            swiperRef.current?.slidePrev();
            // alert(``)
          }}
          className="swiper-button-prev-3 group absolute top-1/2 -left-4 z-10 -mt-6 flex h-12 w-12
						cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base shadow-white-volume sm:-left-6"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
          </svg>
        </div>
      )}

      <div className="swiper card-slider-4-columns !py-5">
        {/*swiper-wrapper*/}
        <div className="">
          <Swiper
/*            breakpoints={{
              1024: {
                slidesPerView: 2,
              },
            }}*/
            modules={[FreeMode, Pagination]}
            spaceBetween={20}
            freeMode={true}
            slidesPerView={isPc ? 4 : 2}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            // navigation={true}
          >
            {data.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="w-26">
                    {/*{item.userAddress}*/}
                    <CollectionElement item={item} />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      {isLargerThanDisplay() && (
        <div
          onClick={() => {
            swiperRef.current?.slideNext();
            // alert(``)
          }}
          className="swiper-button-next-3 group absolute top-1/2 -right-4 z-10 -mt-6 flex h-12 w-12
						cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base shadow-white-volume sm:-right-6"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
          </svg>
        </div>
      )}
    </div>
  );
};

export { SmSwiper };
