import React from 'react';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {RESOURCE_BASE_URL} from "../../Constant";
import collection_01 from '../../img/collections/collection_01.jpg';
import collection_02 from '../../img/collections/collection_02.jpg';
import collection_03 from '../../img/collections/collection_03.jpg';
import collection_04 from '../../img/collections/collection_04.jpg';
import {trimString} from "../../JsUtils";
import {useTranslation} from "react-i18next";

const CollectionElement = ({ item }) => {
    const { t, i18n } = useTranslation();
  return (
      <article>
          <div
              className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg aspect-[270/327]">
              <a className="flex space-x-[0.625rem]" href={item && `/collection/${item.collectionId}`}>
                            <span className="w-[74.5%]">
                              <img src={(item && item.collectionAssets && item.collectionAssets[0]) ? (item.collectionAssets[0].imageUrl) : collection_01} alt="item1"
                                   className="h-full w-full rounded-[0.625rem] object-cover" loading="lazy"/>
                            </span>
                  <span className="flex w-1/3 flex-col space-y-[0.625rem]">
                              <img src={(item && item.collectionAssets && item.collectionAssets[1]) ? (item.collectionAssets[1].imageUrl): collection_02} alt="item2"
                                   className="h-full rounded-[0.625rem] object-cover aspect-square" loading="lazy"/>
                              <img src={(item && item.collectionAssets && item.collectionAssets[2]) ? (item.collectionAssets[2].imageUrl): collection_03} alt="item3"
                                   className="h-full rounded-[0.625rem] object-cover aspect-square" loading="lazy"/>
                              <img src={(item && item.collectionAssets && item.collectionAssets[3]) ?  (item.collectionAssets[3].imageUrl): collection_04} alt="item4"
                                   className="h-full rounded-[0.625rem] object-cover aspect-square" loading="lazy"/>
                            </span>
              </a>

              <a href={item && `/collection/${item.collectionId}`}
                 className="font-display hover:text-accent dark:hover:text-accent text-jacarta-700 mt-4 block text-base dark:text-white">
                  {item && item.collectionTitle}
              </a>

              <div
                  className="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                  <div className="flex flex-wrap items-center">
                      <a href={item && `/user/${item.userAddress}`} className="mr-2 shrink-0">
                          <img src={item && (RESOURCE_BASE_URL + item.userImageUrl)} alt="owner"
                               className="h-5 w-5 rounded-full"/>
                      </a>
                      <span className="dark:text-jacarta-400 mr-1">by</span>
                      <a href={item && `/user/${item.userAddress}`} className="text-accent ">
                          <span className="">{item && trimString(item.username, 6)}</span>
                      </a>
                  </div>
                  <span className="dark:text-jacarta-300 text-sm">{item && item.collectionAssetsCount} {t('common.items_ordinal')}</span>
              </div>
          </div>
      </article>
  );
};

export { CollectionElement };
