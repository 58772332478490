import React, { useEffect, useState, useCallback } from "react";
import apiClient from "../../apiClient";
import Loading from "react-fullscreen-loading";
import banner from "../../img/collections/collection_banner.jpg";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CommunityPostList } from "../../components/CommunityPostList";
import { RESOURCE_BASE_URL } from "../../Constant";

const CommunityBoard = () => {
  const navigate = useNavigate();
  const { communityId } = useParams();
  const [ImageUrl, setImageUrl] = useState();
  const [isSortOpen, setSortOpen] = useState(false);
  const [isCommunityInfo, setCommunity] = useState([]);
  const [isPostList, setCommunityBoard] = useState([]);
  const [isPageLoading, setPageLoading] = useState(false);
  const [isPageBoardLoading, setPageBoardLoading] = useState(false);
  const [PageEndFlag, setPageEndFlag] = useState(false);
  const [pageStatus, setPageStatus] = useState({
    page: 1,
    filter: "New",
  });
  const [scrollInfos, setScrollInfos] = useState(() =>
    sessionStorage.getItem(`isCommunityBackScrollPos`)
  );

  const scrollRemove = useCallback(() => {
    setScrollInfos(0);
    setPageStatus(
      JSON.parse(sessionStorage.getItem(`isCommunityBackPageInfo`))
    );
    sessionStorage.removeItem(`isCommunityBackScrollPos`);
    sessionStorage.removeItem("isCommunityBack");
    sessionStorage.removeItem("isCommunityBackPageInfo");
    sessionStorage.removeItem("isCommunityBackPageEndInfo");
    sessionStorage.removeItem("isCommunityEdit");
  }, []);

  function postList(obj) {
    setPageStatus(obj);
    communityPostList(obj);
  }

  function setSortStatus(val) {
    setSortOpen((prev) => !prev);
    const pageParam = {
      page: 1,
      filter: val,
    };
    setPageStatus(pageParam);
    communityPostList(pageParam);
  }

  async function communityInfo() {
    setPageLoading(true);

    let pageinfo = [];
    let visitflag = true;
    if (localStorage.getItem("view_community") !== null) {
      pageinfo = JSON.parse(localStorage.getItem("view_community"));
      visitflag = pageinfo.indexOf(communityId) !== -1 ? false : true;
    }

    const { data } = await apiClient.post("/community/info/", {
      id: communityId,
      visit: visitflag,
    });
    if (
      data.statusCode === 401 ||
      data.result === "no_user" ||
      data.result === "error"
    )
      navigate("/community");

    if (data && data.result === "ok") {
      setImageUrl(data.image_url + data.communityInfo.thumbnail);
      setCommunity(data.communityInfo);

      if (visitflag) {
        pageinfo.push(communityId);
        localStorage.setItem("view_community", JSON.stringify(pageinfo));
      }
      communityPostList(pageStatus);
    }
    setPageLoading(false);
  }
  async function communityPostList(pageParam) {
    if (
      sessionStorage.getItem("isCommunityBack") === "true" &&
      sessionStorage.getItem("isCommunityPostList") !== null &&
      sessionStorage.getItem("isCommunityEdit") !== "true"
    ) {
      if (sessionStorage.getItem("isCommunityBack")) {
        setCommunityBoard(
          JSON.parse(sessionStorage.getItem("isCommunityPostList"))
        );
        setPageEndFlag(
          JSON.parse(sessionStorage.getItem("isCommunityBackPageEndInfo"))
        );
      }
    } else {
      setPageBoardLoading(true);

      try {
        const url = `/community/board/${communityId}/${pageParam.page}/${pageParam.filter}`;
        const { data } = await apiClient.get(url);
        if (
          data.statusCode === 401 ||
          data.result === "no_user" ||
          data.result === "error"
        )
          navigate("/community");

        if (data && data.result === "ok") {
          pageParam.page === 1
            ? setCommunityBoard([...data.communityBoardList.items])
            : setCommunityBoard([
                ...isPostList,
                ...data.communityBoardList.items,
              ]);
          setPageEndFlag(pageParam.page >= data.endPage ? true : false);
        }
      } catch (e) {}

      setPageBoardLoading(false);
    }
    sessionStorage.removeItem(`isCommunityPostList`);
    //sessionStorage.removeItem("isCommunityEdit");
    //sessionStorage.removeItem("isCommunityBack");
  }

  useEffect(() => {
    if (sessionStorage.getItem("isCommunityBack")) {
      window.scrollTo(0, scrollInfos);
      const scrollTop = window.scrollY;
      if (scrollTop === parseInt(scrollInfos) && isPostList.length !== 0) {
        scrollRemove();
      }
    }
  }, [scrollInfos, scrollRemove, isPostList, isCommunityInfo]);

  useEffect(() => {
    communityInfo();
  }, []);

  return (
    <main className="pt-[5.5rem] lg:pt-24">
      <div className="relative">
        <img
          src={
            isCommunityInfo && isCommunityInfo.bannerImageURI
              ? RESOURCE_BASE_URL + isCommunityInfo.bannerImageURI
              : banner
          }
          alt="banner"
          className="cover-full-width h-[18.75rem] object-cover"
        />
      </div>

      <section
        id="HeaderInfoWrap"
        className="relative bg-light-base pb-12 pt-28 dark:bg-jacarta-800"
      >
        <div className="absolute left-1/2 top-0 z-10 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
          <figure className="relative">
            {ImageUrl ? (
              <img
                src={ImageUrl}
                alt="collection avatar"
                className="rounded-xl border-[5px] border-white dark:border-jacarta-600"
                width="148"
              />
            ) : null}
          </figure>
        </div>

        <div className="container">
          <div className="text-center">
            <h2 className="mb-2 font-display text-4xl font-medium text-jacarta-700 dark:text-white">
              {isCommunityInfo.title}
            </h2>
            <div className="mb-8">
              <span className="text-sm font-bold text-jacarta-400">
                Created by&nbsp;
              </span>
              <Link
                to={isCommunityInfo && `/user/${isCommunityInfo.userAddress}`}
                className="text-sm font-bold text-accent"
              >
                {isCommunityInfo.username}
              </Link>
            </div>
            <p className="mx-auto max-w-xl text-md dark:text-jacarta-300">
              {isCommunityInfo.description}
            </p>
          </div>

          {isPageLoading ? (
            <Loading loading background="#22000000" loaderColor="#3498db" />
          ) : null}
        </div>
      </section>

      <section className="relative py-16 md:py-24">
        <div className="container">
          <div className="mx-auto max-w-[48.125rem]">
            <div className="mb-8 flex flex-wrap items-center justify-between text-center">
              <div className="dropdown relative my-1 cursor-pointer">
                <div
                  className="dropdown-toggle inline-flex w-48 items-center justify-between rounded-lg border border-jacarta-100 bg-white py-2 px-3 text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white"
                  role="button"
                  id="categoriesSort"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                  onClick={() => setSortOpen((prev) => !prev)}
                >
                  <span className="font-display">{pageStatus.filter}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="h-4 w-4 fill-jacarta-500 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </div>

                {isSortOpen && (
                  <div
                    className="dropdown-menu show z-10 hidden w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800"
                    aria-labelledby="categoriesSort"
                    style={{
                      position: "absolute",
                      inset: "0px auto auto 0px",
                      margin: "0px",
                      transform: "translate(0px, 37px)",
                    }}
                    data-popper-placement="bottom-start"
                  >
                    <button
                      className={
                        "dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display text-sm  hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600 " +
                        (pageStatus.filter === "New"
                          ? "text-jacarta-700 transition-colors"
                          : "")
                      }
                      onClick={() => setSortStatus("New")}
                    >
                      New
                      {pageStatus.filter === "New" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className="h-4 w-4 fill-accent"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                        </svg>
                      ) : (
                        ""
                      )}
                    </button>
                    <button
                      className={
                        "dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display text-sm  hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600 " +
                        (pageStatus.filter === "Popularity"
                          ? "text-jacarta-700 transition-colors"
                          : "")
                      }
                      onClick={() => setSortStatus("Popularity")}
                    >
                      Popularity
                      {pageStatus.filter === "Popularity" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className="h-4 w-4 fill-accent"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                        </svg>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                )}
              </div>

              <Link
                to="/CommunityPostWrite"
                state={{ communityId: communityId }}
                className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
              >
                Write
              </Link>
            </div>

            {isPostList &&
              isPostList.map((item, idx) => (
                <CommunityPostList
                  key={idx}
                  data={item}
                  communityId={communityId}
                  pageStatus={pageStatus}
                  PageEndFlag={PageEndFlag}
                  isPostList={isPostList}
                />
              ))}
          </div>
          {isPageBoardLoading ? (
            <Loading loading background="#22000000" loaderColor="#3498db" />
          ) : null}

          {!PageEndFlag ? (
            <div className="pointer mt-10 text-center">
              <span
                className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                onClick={() => {
                  postList({
                    page: pageStatus.page + 1,
                    filter: pageStatus.filter,
                  });
                }}
              >
                Load More
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </main>
  );
};

export { CommunityBoard };
