import React, { useEffect, useState } from "react";
// import MetaTags from 'react-meta-tags';

import collection_banner from "../img/collections/collection_banner.jpg";
import collection_avatar from "../img/collections/collection_avatar.jpg";
import gradient_light from "../img/gradient_light.jpg";
import { UserNavigation } from "../components/UserNavigation";
import { UserFilters } from "../components/UserFilters";
import { GridNFT } from "../components/GridNFT";
import { Activity } from "../components/Activity";
import { useParams } from "react-router-dom";
import apiClient from "../apiClient";
import { FavoriteBox } from "../components/FavoriteBox";
import { RESOURCE_BASE_URL } from "../Constant";
import { UserMenu } from "../components/UserMenu";
import {Helmet} from "react-helmet-async";
import CORN from './../img/chains/CORN.png';
import {useTranslation} from "react-i18next";

const CollectionPage = () => {
  const { t, i18n } = useTranslation();
  const { collectionId } = useParams();
  const [activeWindow, setActiveWindow] = useState("owned");
  const [collection, setCollection] = useState(null);
  const [collectionAssets, setCollectionAssets] = useState([]);
  const [sorting, setSorting] = useState('Recent');

  useEffect(() => {
    (async () => {
      console.log(`collectionId = ${collectionId}`);
      const { data: collectionData } = await apiClient.get(
        `blockchain/collection/${collectionId}`, { params: {
              sorting
            }}
      );
      console.log(`collectionData = ${JSON.stringify(collectionData)}`);
      const { collection, collectionAssets } = collectionData;
      setCollection(collection);
      setCollectionAssets(collectionAssets);
    })();
  }, [sorting]);

  return (
      <div className="wrapper">
        <Helmet>
          <title>{collection && collection.collectionTitle}</title>
          <meta name="description" content={collection && collection.collectionDesc} />
          <meta property="og:title" content={collection && collection.collectionTitle} />
          <meta property="og:image" content={collection && collection.collectionLogoURL} />
        </Helmet>

        <main className="pt-[5.5rem] lg:pt-24">
          <div className="relative ">
            <img
              src={
                collection ? `${collection.collectionBannerURL}` : collection_banner
              }
              alt="banner"
              className="cover-full-width aspect-[1920/320] h-[18.75rem] object-cover"
            />
          </div>

          <section className="relative bg-light-base pb-12 pt-28 dark:bg-jacarta-800">
            <div className="absolute left-1/2 top-0 z-10 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
              <figure className="relative">
                <img
                  src={
                    collection
                      ? `${collection.collectionLogoURL}`
                      : collection_avatar
                  }
                  alt=""
                  className="aspect-square w-36 rounded-xl border-[5px] border-white dark:border-jacarta-600"
                />
              </figure>
            </div>

            <div className="container">
              <div className="text-center">
                <h2 className="mb-2 font-display text-4xl font-medium text-jacarta-700 dark:text-white">
                  {collection && collection.collectionTitle}
                </h2>
                <div className="mb-8">
                  <span className="text-sm font-bold text-jacarta-400">
                    {t('common.created_by')}{" "}
                  </span>
                  <a
                    href={collection && `/user/${collection.userAddress}`}
                    className="text-sm font-bold text-accent"
                  >
                    {collection && collection.username}
                  </a>
                </div>

                <div className="mb-8 inline-flex flex-wrap items-center justify-center rounded-xl border border-jacarta-100 bg-white dark:border-jacarta-600 dark:bg-jacarta-800">
                  <a className="w-1/2 rounded-l-xl border-r border-jacarta-100 py-4 hover:shadow-md dark:border-jacarta-600 sm:w-32">
                    <div className="mb-1 text-base font-bold text-jacarta-700 dark:text-white">
                      {collection && collection.collectionAssetsCount}
                    </div>
                    <div className="text-2xs font-medium tracking-tight dark:text-jacarta-400">
                      {t('common.items_total')}
                    </div>
                  </a>
                  <a className="w-1/2 border-jacarta-100 py-4 hover:shadow-md dark:border-jacarta-600 sm:w-32 sm:border-r">
                    <div className="mb-1 text-base font-bold text-jacarta-700 dark:text-white">
                      {collection && collection.actualOwnerCount}
                    </div>
                    <div className="text-2xs font-medium tracking-tight dark:text-jacarta-400">
                      {t('common.owners')}
                    </div>
                  </a>
                  <a className="w-1/2 border-r border-jacarta-100 py-4 hover:shadow-md dark:border-jacarta-600 sm:w-32">
                    <div className="mb-1 flex items-center justify-center text-base font-medium text-jacarta-700 dark:text-white">
                      <span className="-mt-px inline-block">
                        <img src={CORN} width="15" height="15" />
                      </span>
                      <span className="font-bold">{(collection && collection.floorPrice) ? collection.floorPrice : '0'}</span>
                    </div>
                    <div className="text-2xs font-medium tracking-tight dark:text-jacarta-400">
                      {t('common.floor_price')}
                    </div>
                  </a>
                  <a className="w-1/2 rounded-r-xl border-jacarta-100 py-4 hover:shadow-md sm:w-32">
                    <div className="mb-1 flex items-center justify-center text-base font-medium text-jacarta-700 dark:text-white">
                      <span className="-mt-px inline-block">
                        <img src={CORN} width="15" height="15" />
                      </span>
                      <span className="font-bold">{collection && Number(collection.volumeTraded).toLocaleString('en')}</span>
                    </div>
                    <div className="text-2xs font-medium tracking-tight dark:text-jacarta-400">
                      {t('common.volume_traded')}
                    </div>
                  </a>
                </div>

                <p className="mx-auto max-w-xl text-lg dark:text-jacarta-300">
                  {collection && collection.collectionDesc}
                </p>

                <div className="mt-6 flex items-center justify-center space-x-2.5">
                  {/*<FavoriteBox/>*/}
                </div>

                <UserMenu
                  entityType={"collections"}
                  entityId={collection && collection.collectionId}
                  initialFlag={collection && collection.isFavoriteYn === "Y"}
                  favoriteCount={collection && collection.favoriteCount}
                  linkUrl={window.location.href}
                  snsUrls={collection && collection.links}
                />
              </div>
            </div>
          </section>

          {
            <section className="relative py-24 pt-20">
              <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                <img
                  src={gradient_light}
                  alt="gradient"
                  className="h-full w-full"
                />
              </picture>

              <div className="container">
                <UserNavigation setActiveWindow={setActiveWindow} />
                {activeWindow === "onSale" && <UserFilters />}
                {activeWindow === "onSale" && (
                  <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
                    {collectionAssets &&
                      collectionAssets.map((item) => (
                        <GridNFT
                          item={item}
                          itemName={item.name}
                          price={item.price}
                          imageUrl={item.imageUrl}
                          id={item.id}
                          hash={item.hash}
                          favoriteFlag={item.isFavoriteYn === "Y"}
                          favoriteCount={item.favoriteCount}
                          saleStatus={item.saleStatus}
                        />
                      ))}
                  </div>
                )}
                {activeWindow === "owned" && <UserFilters
                  onClick={(sorting) => {
                    setSorting(sorting);
                  }}/>}
                {activeWindow === "owned" && (
                  <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
                    {collectionAssets &&
                      collectionAssets.map((item) => (
                        <GridNFT
                          item={item}
                          itemName={item.name}
                          price={item.price}
                          imageUrl={item.imageUrl}
                          id={item.id}
                          hash={item.hash}
                          favoriteFlag={item.isFavoriteYn === "Y"}
                          favoriteCount={item.favoriteCount}
                          saleStatus={item.saleStatus}
                        />
                      ))}
                  </div>
                )}
                {activeWindow === "activity" && <Activity />}
              </div>
            </section>
          }
        </main>
      </div>
  );
};

export { CollectionPage };
