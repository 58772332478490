import React, { useEffect, useState } from "react";
import apiClient from "../../apiClient";
import gradient_light from "../../img/gradient_light.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ExpandableItem } from "../../faq/ExpandableItem";
import { StakingItem } from "./StakingItem";
import { useUserStore } from "../../useUserStore";
import { ethers } from "ethers";
import { creatorErc721Abi } from "../../blockchain/creatorErc721Abi";
import { stakingAbi } from "../../blockchain/stakingAbi";
import { constants } from "../../blockchain/constants";
import moment from "moment";
import "moment/locale/ko";

let itemData = {
  clickName: "취소",
  collectionAssetId: "1",
  collectionAssetImageUrl: "",
  collectionAssetName: "NJBadge(WHITE) #NJW0405",
  stackingDt: "2023년 3월 6일",
  cumRewardPoint: 1000,
};

const StakingMain = () => {
  const { t, i18n } = useTranslation();

  const questionData = [
    {
      title: t('staking.qna1.title'),
      description:t('staking.qna1.description'),
    },
    {
      title: t('staking.qna2.title'),
      description:t('staking.qna2.description'),
    },
    {
      title: t('staking.qna3.title'),
      description:t('staking.qna3.description'),
    },
    {
      title: t('staking.qna4.title'),
      description:t('staking.qna4.description'),
    },
    {
      title: t('staking.qna5.title'),
      description:t('staking.qna5.description'),
    },
  ];

  const userdata = useUserStore((state) => state.userdata);

  const [stakingItems, setStakingItems] = useState([
    // itemData,
    // itemData,
    // itemData,
    // itemData,
    // itemData,
    // itemData,
  ]);
  const [stakeableItems, setStakeableItems] = useState([
    // itemData,
    // itemData,
    // itemData,
    // itemData,
  ]);

  const [questionItems, setQuestionItems] = useState(questionData);

  useEffect(() => {
    (async () => {
      const { data: responseData } = await apiClient.get(`/staking/nft`);
      console.log(`responseData = ${JSON.stringify(responseData)}`);
      const { result, collectionAssets } = responseData;
      if (result === "ok" && collectionAssets) {
        const stakingItems = collectionAssets.filter(
          (item) => item.assetStatus === "ON_STAKING"
        );
        const stakeableItems = collectionAssets.filter(
          (item) => item.assetStatus === "ORDINARY"
        );
        setStakingItems(stakingItems);
        setStakeableItems(stakeableItems);
      }
    })();
  }, []);

  return (
    <main>
      <section className="relative py-24">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src={gradient_light} alt="gradient" className="h-full w-full" />
        </picture>

        {/* TITLE */}
        <div className="pt-10 pb-10">
          <h2 className="mb-2 text-center font-display text-xl font-medium text-jacarta-700 dark:text-white">
            {t("staking.staking_title")}
          </h2>
          <p className="mx-auto mb-10 max-w-md text-center text-lg dark:text-jacarta-300">
            {t("staking.staking_subtitle")}
          </p>
        </div>

        <div className="accordion mx-auto max-w-[35rem]">
          {questionItems &&
            questionItems.length > 0 &&
            questionItems.map((item, index) => (
              <ExpandableItem key={index} title={item.title}>
                {item.description}
              </ExpandableItem>
            ))}
        </div>

        {/* STAKING ITEMS */}
        <div className="container py-8">
          {stakingItems && stakingItems.length > 0 && (
            <div>
              <ul
                className="nav nav-tabs mb-2 flex items-center justify-center dark:border-jacarta-600"
                role="tablist"
              >
                <span className="font-display text-base text-2xl font-bold">
                  {t("staking.staked_items")}
                </span>
              </ul>
              <p className="mx-auto mb-8 max-w-md text-center text-sm dark:text-jacarta-300">
                {t("staking.if_stake_reward_given")}
              </p>
            </div>
          )}
          <div className="tab-content">
            <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
              {stakingItems &&
                stakingItems.length > 0 &&
                stakingItems.map((item, index) => (
                  <StakingItem
                    key={index}
                    clickName={t("common.cancel")}
                    collectionAssetId={item.id}
                    collectionAssetImageUrl={item.imageUrl}
                    collectionAssetName={item.name}
                    stackingDt={item.stakedAt && moment(item.stakedAt).lang(i18n.resolvedLanguage === "kr" ? "ko" : "en")
                        .format("YYYY-MM-DD")}
                    cumRewardPoint={item.cumRewardPoint}
                    onClick={async (e) => {
                      e.preventDefault();
                      // start staking
                      //alert("click.staking");
                      if (!window.confirm("Do you want to stop staking?")) {
                        return;
                      }

                      const ethereum = window["ethereum"];
                      if (ethereum) {
                        const provider = new ethers.providers.Web3Provider(
                          ethereum
                        );
                        const signer = provider.getSigner();

                        const nftStakingContract = new ethers.Contract(
                          constants.NFT_STAKING,
                          stakingAbi,
                          signer
                        );

                        const tx = await nftStakingContract.unstakingNftByUser(
                          item.nftAddress,
                          item.tokenId
                        );
                        const receipt = await tx.wait();
                        console.log(receipt);

                        const response = await apiClient.post(
                          "blockchain/updateNftStaking"
                        );
                        if (response.data.result === "ok") {
                          setTimeout(() => {
                            //   refreshPage();
                            //   setForceRefresh((prev) => !prev);
                            //   setPageLoading(false);
                            //   setModalIsOpen(false);
                            alert("스테이킹이 취소되었습니다.");
                          }, 10000);
                          // we need to refresh buy list
                        }
                      }
                    }}
                  />
                ))}
            </div>
          </div>
          <div className="mt-10 mb-10 text-center">
            <a
              href="https://play.google.com/store/apps/details?id=kr.co.otosoft.popcorn" target="_blank" rel="noopener noreferrer"
              className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
            >
              {t("staking.request_for_reward")}
            </a>
          </div>
        </div>

        {/*<div className="h-10 bg-blue-500"></div>*/}

        {/* STAKING AVAILABLE ITEMS */}
        <div className="container py-8">
          <div>
            <ul
              className="nav nav-tabs mb-2 flex items-center justify-center dark:border-jacarta-600"
              role="tablist"
            >
              <span className="font-display text-base text-2xl font-bold">
                {t("staking.stakable_items")}
              </span>
            </ul>
            <p className="mx-auto mb-8 max-w-md text-center text-sm dark:text-jacarta-300">
              {t("staking.staking_conditions")}
            </p>
          </div>
          <div className="tab-content">
            <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
              {stakeableItems &&
                stakeableItems.length > 0 &&
                stakeableItems.map((item, index) => (
                  <StakingItem
                    key={index}
                    clickName={t("staking.staking_participate")}
                    collectionAssetId={item.id}
                    collectionAssetImageUrl={item.imageUrl}
                    collectionAssetName={item.name}
                    onClick={async (e) => {
                      e.preventDefault();
                      // start staking
                      //alert("click.staking");
                      if (!window.confirm("Do you want to stake this NFT?")) {
                        return;
                      }

                      const ethereum = window["ethereum"];
                      if (ethereum) {
                        const provider = new ethers.providers.Web3Provider(
                          ethereum
                        );
                        const signer = provider.getSigner();
                        const signerAddress = await signer.getAddress();

                        const nftContract = new ethers.Contract(
                          item.nftAddress,
                          creatorErc721Abi,
                          signer
                        );

                        // we need to check allowance of user
                        const isApproved = await nftContract.isApprovedForAll(
                          signerAddress,
                          constants.NFT_STAKING
                        );
                        if (!isApproved) {
                          const tx = await nftContract.setApprovalForAll(
                            constants.NFT_STAKING,
                            true
                          );
                          const receipt = tx.wait();
                          console.log(receipt);
                          const event = receipt.events?.find(
                            (e) => e.event === "ApprovalForAll"
                          );
                          console.log(event);
                        }

                        const nftStakingContract = new ethers.Contract(
                          constants.NFT_STAKING,
                          stakingAbi,
                          signer
                        );

                        const tx = await nftStakingContract.stakingNft(
                          item.nftAddress,
                          item.tokenId
                        );
                        const receipt = await tx.wait();
                        console.log(receipt);

                        const response = await apiClient.post(
                          "blockchain/updateNftStaking"
                        );
                        if (response.data.result === "ok") {
                          setTimeout(() => {
                            //   refreshPage();
                            //   setForceRefresh((prev) => !prev);
                            //   setPageLoading(false);
                            //   setModalIsOpen(false);
                            alert("스테이킹에 성공했습니다.");
                          }, 10000);
                          // we need to refresh buy list
                        }
                      }
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export { StakingMain };
