import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Menu} from './Menu';
import {Share} from './Share';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import apiClient from "../../apiClient";

const UserMenu = ({entityType, entityId, initialFlag, linkUrl, favoriteCount, snsUrls}) => {
    const [shareIsOpen, setShareIsOpen] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [favoriteIsMark, setFavoriteIsMark] = useState(false);
    const [favoriteCounting, setFavoriteCounting] = useState(0);
    const [sns, setSns] = useState({});

    useEffect(() => {
        console.log(`initialFlag = ${initialFlag}`);
        setFavoriteIsMark(initialFlag);

        const fc = parseInt(favoriteCount);
        if (!isNaN(fc)) {
            setFavoriteCounting(fc);
        }
        if(snsUrls) {
            setSns(JSON.parse(snsUrls));
        }
        // alert(JSON.parse(snsUrls));
    }, [initialFlag]);

    async function doFavorites(flag, entityType, entityId) {
        const path = flag ? `social/createFavorites` : `social/destroyFavorites`;
        const {data: createFavoritesData} = await apiClient.post(path, {
            entityType,
            entityId,
        });
        console.log(`doFavorites = ${JSON.stringify(createFavoritesData)}`);
        return (createFavoritesData.status === 'ok')
    }

    function share() {
        setShareIsOpen((prev) => !prev);
        setMenuIsOpen(false);
    }

    function menu() {
        setMenuIsOpen((prev) => !prev);
        setShareIsOpen(false);
    }

    async function heart() {
        if (!entityType || !entityId) {
            return;
        }
        const newFlag = !favoriteIsMark;
        const flag = await doFavorites(newFlag, entityType, entityId);
        if (!flag) {
            return;
        }

        setFavoriteIsMark(prevState => !prevState);
        // alert(favoriteCounting);
        setFavoriteCounting(prevState => prevState + (newFlag ? 1 : -1));
        // setFavoriteCounting(favoriteCounting+(newFlag ? 1 : -1))
    }

    function openUrl(url) {
      if(!url) {
        alert(`등록된 링크가 없습니다.`);
        return;
      }
      window.open(url, '_blank', 'noopener,noreferrer');
    }

    return (
        <div className='mt-6 flex items-center justify-center space-x-2.5'>
            <div
                className='rounded-xl border border-jacarta-100 bg-white hover:bg-jacarta-100 dark:border-jacarta-600
			dark:bg-jacarta-700
			dark:hover:bg-jacarta-600'>
                <Tippy content='Favorite'>
                    <div
                        onClick={() => heart()}
                        className='p-1 js-likes relative inline-flex h-10 cursor-pointer items-center justify-center text-sm before:absolute
					before:h-4 before:w-4 before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0'
                        role='button'>
                        {
                            !favoriteIsMark
                                ? <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
                                    <path fill="none" d="M0 0H24V24H0z"/>
                                    <path
                                        d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z"/>
                                </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                                       fill="#EF4444"
                                       className="h-4 w-4">
                                    <path fill="none" d="M0 0H24V24H0z"/>
                                    <path
                                        d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z"/>
                                </svg>
                        }
                        <span className="text-sm dark:text-jacarta-200 m-1">
                        {(favoriteCounting == null || favoriteCounting === undefined) ? 0 : favoriteCounting.toString()}
                    </span>
                    </div>
                </Tippy>
            </div>


            {
                snsUrls &&
                <div
                    className="dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white"
                >
                    <div
                        onClick={() => openUrl(sns.twitter)}
                        className="relative inline-flex h-10 w-10 cursor-pointer items-center justify-center text-sm"
                        data-tippy-content="Twitter"
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="twitter"
                            className="group-hover:fill-accent fill-jacarta-500 h-4 w-4 dark:group-hover:fill-white"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                            ></path>
                        </svg>
                    </div>
                </div>
            }

            {
                snsUrls &&
                <div
                    className="dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white"
                >
                    <div
                        onClick={() => openUrl(sns.instagram)}
                        className="relative inline-flex h-10 w-10 cursor-pointer items-center justify-center text-sm"
                        data-tippy-content="Instagram"
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="instagram"
                            className="group-hover:fill-accent fill-jacarta-500 h-4 w-4 dark:group-hover:fill-white"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path
                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                            ></path>
                        </svg>
                    </div>
                </div>
            }

            {
                snsUrls &&
                <div
                    className="dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white"
                >
                    <div
                        onClick={() => openUrl(sns.homepage)}
                        className="relative inline-flex h-10 w-10 cursor-pointer items-center justify-center text-sm"
                        data-tippy-content="Sit"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="group-hover:fill-accent fill-jacarta-500 h-4 w-4 dark:group-hover:fill-white"
                        >
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path
                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"
                            />
                        </svg>
                    </div>
                </div>
            }

          <Tippy content='Favorite'>
            <div
                className='dropdown relative rounded-xl border border-jacarta-100 bg-white hover:bg-jacarta-100
			dark:border-jacarta-600 dark:bg-jacarta-700 dark:hover:bg-jacarta-600'>
              <Link
                  onClick={() => share()}
                  to='#'
                  className='dropdown-toggle  inline-flex h-10 w-10 items-center justify-center text-sm'
                  role='button'
                  id='collectionShare'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  data-tippy-content='Share'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='h-4 w-4 fill-jacarta-500 dark:fill-jacarta-200'>
                  <path fill='none' d='M0 0h24v24H0z'/>
                  <path
                      d='M13.576 17.271l-5.11-2.787a3.5 3.5 0 1 1 0-4.968l5.11-2.787a3.5 3.5 0 1 1 .958 1.755l-5.11
						2.787a3.514 3.514 0 0 1 0 1.458l5.11 2.787a3.5 3.5 0 1 1-.958 1.755z'
                  />
                </svg>
              </Link>
              {shareIsOpen && <Share linkUrl={linkUrl}/>}
            </div>
          </Tippy>

        </div>
    );
};

export {UserMenu};
