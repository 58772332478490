import React, {useEffect} from 'react';

const PrivacyPage = () => {
  return (
      <main className="pt-[5.5rem] lg:pt-24">
          <section className="dark:bg-jacarta-800 relative py-16 md:py-24">
{/*
              <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                  <img src="img/gradient_light.jpg" alt="gradient" className="h-full w-full"/>
              </picture>
*/}
              <div className="container">
                  <h1 className="font-display text-jacarta-700 text-center text-4xl font-medium dark:text-white">
                      Privacy policy
                  </h1>
                  <div className="article-content mx-auto max-w-[48.125rem]">
                      <p>Eggpay Co., Ltd. (hereinafter referred to as the "Company") complies with the personal information protection regulations of the relevant laws and regulations that information communication service providers must comply with, such as the Act on Promotion of Information and Communications Network Utilization and Information Protection, Personal Information Protection Act, Protection of Communications Secrets Act, etc. We are doing our best to protect the rights and interests of users by establishing a personal information processing policy in accordance with relevant laws and regulations.
                         This Privacy Policy applies to the use of services provided by the company (excluding services with separate privacy policies, such as Popcorn NFT Market affiliates) and contains the following contents.</p>
                      <p>
                          <p>1. Items of personal information to be collected and method of collection</p>
                          <p>2. Purpose of collection and use of personal information</p>
                          <p>3. Sharing and Provision of Personal Information</p>
                          <p>4. Consignment of processing of personal information</p>
                          <p>5. Retention and use period of personal information</p>
                          <p>6. Personal information destruction procedure and method</p>
                          <p>7. Rights of users and legal representatives and how to exercise them</p>
                          <p>8. Matters concerning the installation/operation and rejection of automatic personal information collection devices</p>
                          <p>9. Technical/administrative protection measures for personal information</p>
                          <p>10. Contact information of the person in charge of personal information protection and the person in charge</p>
                          <p>11. Other</p>
                          <p>12. Duty of notice</p>
                        <br></br>
                        <h2 className="text-base">1. Items of personal information to be collected and method of collection</h2>
                        <br></br>
                        <p> 1) Items of personal information collected</p>
                        <br></br>

                          <p>We collect the following personal information for member registration, smooth customer consultation, and provision of various services.</p>
                          <br></br>
                        <p>[Individual member]</p>
                        <br></br>
                        <p>- Required: Name, e-mail address, mobile phone number, Klaytn wallet address, affiliated company (organization) name, recommender</p>
                        <p>- Optional: gender, date of birth, mobile phone number, address
                        In the process of using the service, the following information may be generated and collected.</p>
                        <p>- IP address, cookie, date and time of visit, service use record, bad use record, mobile service use terminal information (device model, carrier information, hardware ID, advertising ID, basic statistics on service use), applications installed in the user terminal Information (including app installation/execution time), payment/purchase record, consent to location-based service terms of use, user location information</p>
                        <p>- Shipping address information for easy purchase (recipient name, contact information, shipping address)</p>
                        <br></br>

                        <p>To prevent fraudulent use and unauthorized use, the following information may be additionally collected when using Popcorn NFT Market payment.</p>
                        <br></br>

                        <p>- Phone number, terminal information (connected terminal type, OS type, UUID, IMEI)</p>
                        <p>Depending on the service, the following information may be collected with the consent of the user.</p>

                        <p>- When consent is obtained for additional collection of personal information</p>

                        <p>If identity verification is required for age verification or compliance with relevant laws, the following information may be collected.</p>

                        <p>- Name, date of birth, gender, overlapping registration confirmation information (DI), encrypted identification information (CI), mobile phone number, i-PIN number (when using i-PIN), domestic/foreigner information, legal representative information in case of under 14 years of age
                        me. How personal information is collected</p>

                        <p>The company collects personal information in the following ways.</p>
                        <p>- Homepage, mobile device, written form, fax, phone, consultation board, e-mail, event application, delivery request</p>
                        <p>- Provision from partner companies</p>
                        <p>- Collection through generated information collection tool</p>
                        <br></br>
                        <p>2. Purpose of collection and use of personal information</p>

                        <p>The company uses the collected personal information for the following purposes.</p>
                        <br></br>

                        <p>- Personal information is used for the provision of services promised to users, identity authentication according to service provision, purchase and payment, provision of Popcorn NFT Market usage details, and delivery of products and services.</p>
                        <p>- Personal information is used for membership management, such as confirmation of membership subscription, age confirmation and legal representative consent, identification of users and legal representatives, user identification, confirmation of intention to withdraw from membership, and handling of inquiries or complaints.</p>
                        <p>- Restrictions on use of members who violate laws and terms of use, prevention and sanctions against acts that interfere with the smooth operation of the service, including fraudulent use, and unauthorized acts, prevention of account theft and fraudulent transactions, delivery of notices, Personal information is used for user protection and service operation, such as record preservation for dispute mediation.</p>
                        <p>- Provision of services according to demographic characteristics, analysis of access frequency, improvement of functions, statistics on service use, provision of new services that reflect users' product purchase and service use propensity, interest, analysis of usage records, etc. based on service analysis and statistics (Including personalized product recommendation service, etc.), personal information is used.</p>
                        <p>- We use personal information for the purpose of events and promotions, such as providing event information and advertising information.</p>
                        <br></br>
                        <p>3. Sharing and Provision of Personal Information</p>

                        <p>The company uses users' personal information within the scope notified in "2. Purpose of collection and use of personal information", and uses it beyond the scope without the user's prior consent or, in principle, discloses the user's personal information to the outside world. Do not. However, the following cases are exceptions.</p>
                        <br></br>

                        <p>- When the user agrees in advance</p>
                        <p>- In accordance with the provisions of the law or in accordance with the procedures and methods set forth in the law for the purpose of investigation, if there is a request from the investigative agency</p>
                        <p>- If you agree to provide a third party when purchasing a product, the following personal information will be provided to the service provider</p>
                        <p>- In the case of those who receive personal information from the Popcorn NFT Market service, it is difficult to revise the personal information processing policy due to partnerships with service providers that occur from time to time, so the service provider is specified as a page link as shown below. service provider</p>
                        <br></br>
                        <p>4. Consignment of processing of personal information</p>

                        <p>The company consigns personal information as follows to improve the service, and stipulates necessary matters so that personal information can be safely managed during consignment contracts in accordance with relevant laws and regulations. The company's personal information consignment processing agency and consignment business contents are as follows.</p>
                        <br></br>
                        <p>5. Retention and use period of personal information</p>

                        <p>In principle, the company keeps the user's personal information until the member withdraws. However, the company retains personal information for 7 days after request for membership withdrawal in order to prepare for unwanted membership withdrawal due to theft of personal information. In addition, the following information is kept for the specified period for the reasons listed below even after membership withdrawal.</p>

                        <p>go. Reasons for information retention according to company internal policy
                        me. Reasons for retaining information according to relevant laws and regulations</p>
                        <p>If it is necessary to preserve it in accordance with the provisions of related laws, such as the Commercial Act and the Consumer Protection Act in Electronic Commerce, etc., the company keeps member information for a certain period of time as determined by the relevant laws and regulations. In this case, the company uses the stored information only for the purpose of storage, and the retention period is as follows.</p>

                        <p>- Website visit history</p>
                        <p>Reason for retention: Protection of Communications Secrets Act</p>
                        <p>Retention period: 3 months</p>

                        <p>- Records on consumer complaints or dispute handling</p>
                        <p>Reason for preservation: Act on Consumer Protection in Electronic Commerce, etc.</p>
                        <p>Retention period: 3 years</p>

                        <p>- Records on contract or subscription withdrawal, etc.</p>
                        <p>Reason for preservation: Act on Consumer Protection in Electronic Commerce, etc.</p>

                        <p>Retention period: 5 years</p>
                        <p>- Records on payment and supply of goods, etc.</p>

                        <p>Reason for preservation: Act on Consumer Protection in Electronic Commerce, etc.</p>
                        <p>Retention period: 5 years</p>
                        <br></br>
                        <p>6. Personal information destruction procedure and method</p>

                        <p>In principle, personal information of users is destroyed without delay when the purpose of collection and use of personal information is achieved. The company's personal information destruction procedures and methods are as follows.</p>

                        <p>1)	Destruction procedure</p>
                        <p>The information entered by the user for membership registration, etc., is transferred to a separate DB after the purpose is achieved (separate filing cabinet in the case of paper) and is subject to information protection according to internal policies and other related laws (see retention and use period). It is destroyed after being stored for a period of time.</p>
                        <p>This personal information will not be used for any purpose other than being retained unless otherwise required by law.</p>

                        <p>2) Destruction method</p>
                        <p>Personal information printed on paper is shredded with a shredder or destroyed by incineration.</p>
                        <p>Personal information stored in the form of electronic files is deleted using a technical method that cannot reproduce the record.</p>
                        <br></br>
                        <p>7. Rights of users and legal representatives and how to exercise them</p>

                        <p>Users and legal representatives may view or modify their registered personal information or the personal information of children under the age of 14 at any time, and if they do not agree to the company's processing of personal information, they may reject the consent or request termination of membership (withdrawal of membership). You can. However, in such cases, some or all of the services may be difficult to use.</p>

                        <p>In order to view and modify personal information of users or children under the age of 14, 'change personal information' (or 'modify member information', etc.), and to terminate membership (withdraw consent), go through the identification process After viewing, you can directly view, correct or withdraw.</p>

                        <p>Or, if you contact the person in charge of personal information protection in writing, by phone or e-mail, we will take action without delay.</p>

                        <p>If a user requests correction of an error in personal information, the personal information will not be used or provided until the correction is completed. In addition, if wrong personal information has already been provided to a third party, the result of the correction will be notified to the third party without delay so that the correction can be made.</p>

                        <p>Personal information that has been terminated or deleted at the request of a user or a legal representative is subject to “5. Retention and use period of personal information” and process it so that it cannot be viewed or used for any other purpose.</p>
                        <br></br>
                        <p>8. Matters concerning the installation/operation and rejection of automatic personal information collection devices</p>

                         <p>1) What is a cookie</p>
                        <p>The company uses 'cookies' that store and retrieve user information from time to time in order to provide personalized and customized services. Cookies are very small text files that the server used to run the website sends to the user's browser and are stored on the hard disk of the user's computer.</p>

                         <p>2) Purpose of use of cookies</p>
                        <p>Cookies are used to provide optimized and customized information to users by analyzing each service and usage type of the company used by users.</p>

                         <p>3) Installation/Operation and Rejection of Cookies</p>
                         <br></br>
                        <p>Users have the option to install cookies. Therefore, the user may allow all cookies by setting options in the web browser, go through confirmation whenever a cookie is saved, or refuse to save all cookies.
                        However, if you refuse to save cookies, you may have difficulty using some of the company's services that require login.</p>
                        <br></br>

                        <p>How to Specify Whether to Allow Cookies to Be Installed (For Internet Explorer)</p>
                       <p> ① Select [Internet Options] from the [Tools] menu.</p>
                        <p>② Click [Personal information tab].</p>
                        <p>③ Set [Personal Information Handling Level].</p>
                        <br></br>

                         <p>4) Matters concerning cookie installation/operation in mobile services</p>
                        <p>The company provides the same/similar Internet environment as the PC environment as a mobile server.</p>
                      </p>
                  </div>
              </div>
          </section>
      </main>
  );
};

export { PrivacyPage };
