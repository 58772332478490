import React, { useEffect, useState } from "react";
import apiClient from "../../apiClient";
import gradient_light from "../../img/gradient_light.jpg";
import Loading from "react-fullscreen-loading";
import owner_5 from "../../img/avatars/owner_5.png";
import { CommunityComments } from "../../components/CommunityComments";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommunityModal } from "../../components/CommunityModal";
import { RESOURCE_BASE_URL } from "../../Constant";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

const CommunityPost = () => {
  const { communityId, postId } = useParams();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const [ITEMsPost, setCommunityPost] = useState([]);
  const [ITEMsComments, setCommunityComments] = useState([]);
  const [ITEMsCommentsCnt, setCommunityCommentsCnt] = useState(0);
  const [isPageBoardLoading, setPageBoardLoading] = useState(false);
  const [isCommentsLoading, setCommentsLoading] = useState(false);
  const [isUserInfo, setUserInfo] = useState(false);
  const [isModal, setModal] = useState({
    state: false,
    header: "",
    contents: "",
    modaltype: "",
  });

  useEffect(() => {
    const listenBackEvent = () => {
      sessionStorage.setItem("isCommunityBack", JSON.stringify(true));
    };

    const unlistenHistoryEvent = history.listen(({ action }) => {
      if (action === "POP") {
        listenBackEvent();
      }
    });

    return unlistenHistoryEvent;
  }, []);

  const confirmModal = async () => {
    setPageBoardLoading(true);
    const param = {
      communityId: parseInt(communityId),
      id: parseInt(postId),
      flag: false,
    };

    const { data } = await apiClient.post("/community/updateBoardPost/", param);
    if (data.result === "ok") {
      sessionStorage.setItem("isCommunityEdit", "true");

      navigate(`/CommunityBoard/${communityId}`);
    }
    setModal({
      state: false,
    });
    setPageBoardLoading(false);
  };

  const [input, setInput] = useState();

  const onChange = (e) => {
    setInput(e.target.value);
  };

  async function communityPostInfo() {
    let viewarr = [];
    let viewflag = true;
    if (localStorage.getItem("view_post") !== null) {
      viewarr = JSON.parse(localStorage.getItem("view_post"));
      viewflag = viewarr.indexOf(postId) !== -1 ? false : true;
    }

    setPageBoardLoading(true);
    const { data } = await apiClient.post("/community/post/", {
      communityId: parseInt(communityId),
      id: parseInt(postId),
      visit: viewflag,
    });
    if (
      data.statusCode === 401 ||
      data.result === "no_user" ||
      data.result === "error"
    )
      navigate("/community");

    if (data && data.result === "ok") {
      setCommunityPost(data.communityPostInfo);
      setPageBoardLoading(false);
      setUserInfo(data.user);

      if (viewflag) {
        viewarr.push(postId);
        localStorage.setItem("view_post", JSON.stringify(viewarr));
      }
    }
  }

  async function communityCommentList() {
    setCommentsLoading(true);
    const { data } = await apiClient.get("/community/comments/" + postId);
    if (data && data.result === "ok") {
      setCommunityComments(data.communityCommentList.items);
      setCommentsLoading(false);
    }
  }

  async function saveCommunityComment() {
    if (input.trim().length === 0) {
      inputRef.current.focus();
      return;
    }

    setPageBoardLoading(true);
    const param = {
      postid: parseInt(postId),
      comment: input,
      depth: 0,
    };
    const { data } = await apiClient.post("/community/updateComment/", param);
    if (data.result === "ok") {
      data.parentid = data.comments.id;
      const comment_array = [...[data.comments], ...ITEMsComments];
      setCommunityComments(comment_array);
      setInput("");
    }
    setPageBoardLoading(false);
  }

  useEffect(() => {
    setCommunityCommentsCnt(ITEMsComments.length);
  }, [ITEMsComments]);

  useEffect(() => {
    communityPostInfo();
    communityCommentList();
    setInput("");
  }, []);

  return (
    <main className="pt-[5.5rem] lg:pt-24">
      <section className="relative py-16 md:py-24">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src={gradient_light} alt="gradient" className="h-full w-full" />
        </picture>
        <div className="container">
          <header className="mx-auto mb-16 max-w-lg text-center">
            <h1 className="mb-4 font-display text-xl text-jacarta-700 sm:text-xl">
              {ITEMsPost.title}
            </h1>

            <div className="inline-flex items-center">
              <img
                src={
                  ITEMsPost.profileImg
                    ? RESOURCE_BASE_URL + ITEMsPost.profileImg
                    : owner_5
                }
                alt="author"
                className="mr-4 h-6 w-6 shrink-0 rounded-full"
              />

              <div className="text-left">
                <span className="text-2xs font-medium tracking-tight text-jacarta-700 dark:text-jacarta-200">
                  {ITEMsPost.username
                    ? ITEMsPost.username
                    : "nouser-" + ITEMsPost.user_id}
                </span>

                <div className="flex flex-wrap items-center space-x-2 text-sm text-jacarta-400">
                  <span>{ITEMsPost.createdAt}</span>
                  <span>•</span>
                  <span>View: {ITEMsPost.view}</span>
                </div>
              </div>
            </div>
          </header>

          <div className="mx-auto max-w-[48.125rem] text-right">
            {isUserInfo.id === ITEMsPost.user_id ? (
              <div className="mb-2 mr-8 flex-wrap text-right text-2xs tracking-tight text-jacarta-400">
                <span
                  className="pointer"
                  onClick={() => {
                    setModal({
                      state: true,
                      header: "Community",
                      contents: "완전히 삭제하시겠습니까?",
                      modaltype: {
                        gubun: "confirm",
                        btnoktxt: "확인",
                        btnOkEvent: confirmModal,
                        btncanceltxt: "취소",
                      },
                    });
                  }}
                >
                  Delete
                </span>
                <span className="mr-2 ml-2">•</span>
                <Link
                  to={`/CommunityPostWrite`}
                  state={{ communityId: communityId, postId: postId }}
                >
                  Edit
                </Link>
              </div>
            ) : null}
          </div>

          {isPageBoardLoading ? (
            <Loading loading background="#22000000" loaderColor="#3498db" />
          ) : null}

          <article className="mb-12">
            <div className="article-content">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: ITEMsPost.contents }}
              />
            </div>
          </article>

          <div className="mx-auto max-w-[48.125rem]">
            <div className="mx-auto max-w-[48.125rem] text-right">
              <div className="mb-2 flex-wrap text-right text-2xs tracking-tight text-jacarta-400">
                <button
                  type="submit"
                  className="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  id="contact-form-submit"
                  onClick={() => {
                    sessionStorage.setItem(
                      "isCommunityBack",
                      JSON.stringify(true)
                    );
                    navigate(`/CommunityBoard/${communityId}`);
                  }}
                >
                  List
                </button>
              </div>
            </div>

            <div className="mb-xs-40 mb-80">
              <div className="mb-xs-40">
                <h4 className="mb-2 font-display text-lg text-jacarta-700">
                  Leave a comment
                </h4>

                <div className="mb-4">
                  <label
                    for="message"
                    className="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                  >
                    <textarea
                      id="message"
                      className="contact-form-input w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      required
                      name="message"
                      rows="5"
                      onChange={onChange}
                      value={input}
                      ref={inputRef}
                    ></textarea>
                  </label>
                </div>

                <button
                  type="submit"
                  className="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  id="contact-form-submit"
                  onClick={() => {
                    saveCommunityComment(input);
                    setInput("");
                  }}
                >
                  Send Comment
                </button>
              </div>

              <h4 className="mt-10 mb-5 font-display text-lg text-jacarta-700">
                Comments <small className="number">({ITEMsCommentsCnt})</small>
              </h4>

              {isCommentsLoading ? (
                <Loading loading background="#22000000" loaderColor="#3498db" />
              ) : null}

              <ul className="media-list comment-list clearlist">
                <CommunityComments
                  comment={ITEMsComments}
                  user={isUserInfo}
                  setComment={setCommunityComments}
                  postId={postId}
                ></CommunityComments>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <CommunityModal modal={isModal} modalState={setModal} />
    </main>
  );
};

export { CommunityPost };
