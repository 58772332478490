import React, { useEffect, useState } from "react";
import gradient_light from "../../img/gradient_light.jpg";
// import a1a from '../../img/chains/a1a.png';
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Modal } from "../../components/Modal";
import { AddProperties } from "../../components/CreateModal/AddProperties";
import { AddLevels } from "../../components/CreateModal/AddLevels";
import { CreateBlockchainMenu } from "../../components/CreateMenuLists/CreateBlockchainMenu";
import { CreateCollectionMenu } from "../../components/CreateMenuLists/CreateCollectionMenu/CreateCollectionMenu";
import { useMutation } from "react-query";
import axios from "axios";
import { useMetaMask } from "metamask-react";
import { ethers } from "ethers";
import Loading from "react-fullscreen-loading";
import { creatorAbi } from "../../blockchain/creatorAbi";
import { constants } from "../../blockchain/constants";
import { currencies } from "../../blockchain/currencies";

import { useNavigate } from "react-router-dom";
import apiClient from "../../apiClient";

import CORN from "../../img/chains/CORN.png";
import { FileInput } from "../../components/FileInput";
import user_avatar from "../../img/user/user_avatar.gif";

import collection_ava_1 from "../../img/avatars/collection_ava_1.png";
import collection_ava_2 from "../../img/avatars/collection_ava_2.jpg";

import KLAYTN from "../../img/chains/KLAYTN.png";
import CORN2 from "../../img/chains/CORN.jpg";
import { SquareFileInput } from "../../components/SquareFileInput";
import { CreateCollectionCategoryMenu } from "../../components/CreateMenuLists/CreateCollectionCategoryMenu";
import { useTranslation } from "react-i18next";

const CollectionCreatePage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const {
    status: metaMaskStatus,
    connect,
    account,
    chainId,
    ethereum,
    switchChain,
  } = useMetaMask();

  const [properties, setProperties] = useState(false);
  const [levels, setLevels] = useState(false);
  const [stats, setStats] = useState(false);
  const [blockchainIsOpen, setBlockchainIsOpen] = useState(false);
  const [categoryIsOpen, setCategoryIsOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState();

  const [isPageLoading, setPageLoading] = useState(false);

  const [mintCurrency, setMintCurrency] = useState("KLAY");

  const [selectedOption, setSelectedOption] = useState("");
  const [logoUrlUploadId, setLogoUrlUploadId] = useState(null);
  const [bannerUrlUploadId, setBannerUrlUploadId] = useState(null);
  const [category, setCategory] = useState(null);
  const [name, setName] = useState(null);
  const [registrationIsEnabled, setRegistrationIsEnabled] = useState(false);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    updateRegistrationEnableStatus();
  }, [logoUrlUploadId, bannerUrlUploadId, category, name, url]);

  const onValueChange = (event) => {
    console.log(event.target.value);
    setSelectedOption(event.target.value);
  };

  // const {
  //   isLoading: isLoadingCreate,
  //   isError: isErrorCreate,
  //   status: statusCreate,
  //   mutate: mutateUpload,
  // } = useMutation((data) => {
  //   return apiClient.post("/upload/collectionInfo", data, {});
  // });

  // const { mutate: mutateDeployed } = useMutation((data) => {
  //   return apiClient.post("/upload/collectionDeployed", data, {});
  // });

  const updateRegistrationEnableStatus = () => {
    console.log(`logoUrlUploadId = ${logoUrlUploadId}`);
    console.log(`bannerUrlUploadId = ${bannerUrlUploadId}`);
    console.log(`category = ${category}`);
    console.log(`url = ${url}`);

    setRegistrationIsEnabled(
      logoUrlUploadId && bannerUrlUploadId && category && url && url !== ""
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setPageLoading(true);

    if (!logoUrlUploadId) {
      alert(`Logo image is required`);
      return;
    }

    // alert(bannerUrlUploadId);
    if (!bannerUrlUploadId) {
      alert(`Banner image is required`);
      return;
    }

    if (!category) {
      alert(`category is required`);
      return;
    }

    const {
      collectionName,
      collectionSlug,
      description,
      twitterLink,
      instagramLink,
      homepageLink,
      creatorFee,
    } = e.target;
    // alert(collectionName.value);

    const royaltyFraction = Math.floor(
      parseFloat(`${creatorFee.value ? creatorFee.value : 0}`) * 100
    );
    if (royaltyFraction < 0 || royaltyFraction > 1000) {
      alert("입력가능한 범위의 creator fee를 설정해주세요.");
    }

    if (!collectionName.value) {
      alert(`collectionName image is required`);
    }

    const reqPayload = {
      bannerFileId: bannerUrlUploadId,
      profileFileId: logoUrlUploadId,
      collectionTitle: collectionName.value,
      collectionSlug: collectionSlug.value,
      collectionDesc: description.value,
      collectionCategory: category.toLowerCase(),
      links: {
        twitter: twitterLink.value,
        instagram: instagramLink.value,
        homepage: homepageLink.value,
      },
      royaltyFraction,
    };

    try {
      setPageLoading(true);
      const response = await apiClient.post(
        "/upload/collectionInfo",
        reqPayload
      );

      if (response.data.result !== "ok") {
        setPageLoading(false);
        alert("컬렉션 업로드에 실패했습니다.");
        return;
      }
      const uploadCollectionId = response.data.uploadCollectionId;
      const uploadCollectionPath = response.data.uploadCollectionPath;
      const uploadCollectionName = response.data.uploadCollectionName;
      const uploadCollectionSymbol = response.data.uploadCollectionSymbol;
      //alert(uploadCollectionId);

      // use creator to create collection send it to upload collection

      const ethereum = window["ethereum"];
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();

        const creatorContract = new ethers.Contract(
          constants.CREATOR,
          creatorAbi,
          signer
        );

        setPageLoading(true);

        const tx = await creatorContract.createERC721Collection(
          `${constants.METADATA_URL}${uploadCollectionPath}/`,
          uploadCollectionName,
          uploadCollectionSymbol,
          royaltyFraction
        );
        const receipt = await tx.wait();

        const response2 = await apiClient.post("/upload/collectionDeployed", {
          hash: receipt.transactionHash,
          uploadCollectionId,
        });

        if (response2.data.result !== "ok") {
          setPageLoading(false);
          alert("컬렉션 싱크에 실패헀습니다.");
        } else {
          setPageLoading(false);
          alert("컬렉션이 성공적으로 생성되었습니다.");
          navigate(`/collection/${reqPayload.collectionSlug}`); // reqPayload.collectionSlug (slug 추후 수정예정)
        }
      }
    } catch (e) {
      alert(e);
      alert("컬렉션 생성에 실패했습니다.");
    } finally {
      setPageLoading(false);
    }

    // upload collection
    // mutateUpload(reqPayload, {
    //   onSuccess: async (response) => {
    //     console.log(`mutateUpload = ${JSON.stringify(response)}`);
    //     const { data } = response;
    //     if (data.result !== "ok") {
    //       alert("컬렉션 업로드에 실패했습니다.");
    //     }

    //     //const { collectionURI } = data;
    //     // alert("UploadCollection created!");
    //     //navigate(`/collection/${collectionURI}`);
    //   },
    //   onFail: async (error) => {
    //     console.log(`mutateUpload onFail = ${JSON.stringify(error)}`);
    //     alert("컬렉션 생성에 실패했습니다.");
    //   },
    // });

    // create collection using creator contract

    // refresh event
  };

  return (
    <main>
      <section className="relative py-24">
        {/*
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img src="img/gradient_light.jpg" alt="gradient" className="h-full w-full"/>
          </picture>
*/}
        <form action="api" onSubmit={handleSubmit}>
          <div className="container">
            <h1 className="py-16 text-center font-display text-4xl font-medium text-jacarta-700 dark:text-white">
              {t("create_collection.create_collection")}
            </h1>

            <div className="mx-auto max-w-[48.125rem]">
              <div className="mb-6">
                <label className="mb-2 block font-display text-jacarta-700 dark:text-white">
                  {t("create_collection.logo_image")}
                  <span className="text-red">*</span>
                </label>
                <p className="mb-3 text-2xs dark:text-jacarta-300">
                  {t("create_collection.drag")}
                </p>
                <p className="mb-3 text-sm leading-normal dark:text-jacarta-300">
                  {t("create_collection.recommend_image")}
                </p>

                <SquareFileInput
                  onImageUploaded={(uploadFileId, filePath) => {
                    setLogoUrlUploadId(uploadFileId);
                    updateRegistrationEnableStatus();
                  }}
                />
              </div>

              {/*Banner image*/}
              <div className="mb-6">
                <label className="mb-2 block font-display text-jacarta-700 dark:text-white">
                  {t("create_collection.banner")}
                  <span className="text-red">*</span>
                </label>
                <p className="mb-3 text-2xs dark:text-jacarta-300">
                  {t("create_collection.drag_choose")}
                </p>
                <p className="mb-3 text-sm leading-normal dark:text-jacarta-300">
                  {t("create_collection.banner_desc")}
                </p>

                <FileInput
                  onImageUploaded={(uploadFileId, filePath) => {
                    console.log(`uploadFileId = ${uploadFileId}`);
                    setBannerUrlUploadId(uploadFileId);
                    updateRegistrationEnableStatus();
                  }}
                />
              </div>

              {/*Name*/}
              <div className="mb-6">
                <label
                  htmlFor="item-name"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_collection.name")}
                  <span className="text-red">*</span>
                </label>
                <input
                  onChange={(e) => {
                    const name = e.target.value;
                    console.log(`name = ${name}`);
                    setName(e.target.value);
                  }}
                  type="text"
                  id="item-name"
                  name="collectionName"
                  className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                  placeholder={t("create_collection.collection_name")}
                  required
                />
              </div>

              {/*URL*/}
              <div className="mb-6">
                <label
                  htmlFor="item-external-link"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_collection.url")}
                  <span className="text-red">*</span>
                </label>
                <p className="mb-3 text-2xs dark:text-jacarta-300">
                  {t("create_collection.url_desc")}
                </p>
                <div className="flex">
                  <p className="w-half mr-1 py-3">
                    https://popcorn-nft/collection/
                  </p>
                  <input
                    type="text"
                    name="collectionSlug"
                    id="item-external-link"
                    className="w-half rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="your-collection"
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                  />
                </div>
              </div>

              {/*Description*/}
              <div className="mb-6">
                <label
                  htmlFor="item-description"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_collection.description")}
                </label>
                <p className="mb-3 text-2xs dark:text-jacarta-300">
                  {t("create_collection.royalty")}
                </p>
                <textarea
                  name="description"
                  id="item-description"
                  className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                  rows="4"
                  placeholder={t("create_collection.description_desc")}
                ></textarea>
              </div>

              {/*Creator fees*/}
              <div className="mb-6">
                <label
                  htmlFor="Creator-fees-wallet"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_collection.creator_fee")}
                </label>
                <p className="mb-3 text-2xs dark:text-jacarta-300">
                  {t("create_collection.creator_fee_desc")}
                </p>
              </div>
              <div className="mb-6 lg:flex">
                {/*<div className="mb-10 shrink-0 basis-8/12 space-y-5 lg:mb-0 lg:pr-10">
                  <input
                    type="url"
                    id="Creator-fees-wallet"
                    name="walletCreator"
                    className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="Please enter an address, e.g. 0xfj56......."
                  />
                </div>*/}

                <div className="flex basis-4/12 lg:pl-0">
                  <input
                    type="text"
                    id="Creator-fees-wallet"
                    name="creatorFee"
                    className="w-20 rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="e.g. 6.5"
                  />
                  <p className="ml-1 py-3">%</p>
                </div>
              </div>

              {/*Category*/}
              <div className="relative">
                <div>
                  <label className="mb-2 block font-display text-jacarta-700 dark:text-white">
                    {t("create_collection.category")}
                    <span className="text-red">*</span>
                  </label>
                  <div className="mb-3 flex items-center space-x-2">
                    <p className="text-2xs dark:text-jacarta-300">
                      {t("create_collection.category_desc")}
                    </p>
                  </div>
                </div>

                <div
                  className="dropdown my-1 cursor-pointer"
                  onClick={() => setCategoryIsOpen((prev) => !prev)}
                >
                  <div
                    className="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300"
                    role="button"
                    id="item-collection"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="">
                      {category
                        ? category
                        : t("create_collection.select_category")}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-4 w-4 fill-jacarta-500 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                    </svg>
                    {categoryIsOpen && (
                      <CreateCollectionCategoryMenu
                        collectionItems={[1, 2]}
                        onClick={(id) => {
                          setCategory(id);
                          updateRegistrationEnableStatus();
                          // setCollectionName(collectionName);
                        }}
                      />
                    )}
                  </div>

                  <div
                    className="dropdown-menu z-10 hidden w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800"
                    aria-labelledby="item-collection"
                  >
                    <ul className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                      <li>
                        <a
                          href="#"
                          className="dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600"
                        >
                          <span className="flex items-center space-x-3">
                            <img
                              src={collection_ava_1}
                              className="h-8 w-8 rounded-full"
                              loading="lazy"
                              alt="avatar"
                            />
                            <span className="text-jacarta-700 dark:text-white">
                              IFFA
                            </span>
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="mb-[3px] h-4 w-4 fill-accent"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          className="dropdown-item flex w-full items-center rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600"
                        >
                          <span className="flex items-center space-x-3">
                            <img
                              src={collection_ava_2}
                              className="h-8 w-8 rounded-full"
                              loading="lazy"
                              alt="avatar"
                            />
                            <span className="text-jacarta-700 dark:text-white">
                              Vieagem
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="mt-6 mb-6">
                <label
                  htmlFor="profile-twitter"
                  className="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                >
                  {t("create_collection.links")}
                </label>
                <div className="relative">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="twitter"
                    className="pointer-events-none absolute top-1/2 left-4 h-4 w-4 -translate-y-1/2 fill-jacarta-300 dark:fill-jacarta-400"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                  </svg>
                  <input
                    type="text"
                    id="profile-twitter"
                    name="twitterLink"
                    className="w-full rounded-t-lg border-jacarta-100 py-3 pl-10 hover:ring-2 hover:ring-accent/10 focus:ring-inset focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="@twittername"
                  />
                </div>
                <div className="relative">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    className="pointer-events-none absolute top-1/2 left-4 h-4 w-4 -translate-y-1/2 fill-jacarta-300 dark:fill-jacarta-400"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                  </svg>
                  <input
                    type="text"
                    id="profile-instagram"
                    name="instagramLink"
                    className="-mt-px w-full border-jacarta-100 py-3 pl-10 hover:ring-2 hover:ring-accent/10 focus:ring-inset focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="instagramname"
                  />
                </div>
                <div className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="pointer-events-none absolute top-1/2 left-4 h-4 w-4 -translate-y-1/2 fill-jacarta-300 dark:fill-jacarta-400"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z" />
                  </svg>
                  <input
                    type="text"
                    name="homepageLink"
                    id="profile-website"
                    className="-mt-px w-full rounded-b-lg border-jacarta-100 py-3 pl-10 hover:ring-2 hover:ring-accent/10 focus:ring-inset focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="yoursitename.com"
                  />
                </div>
              </div>

              {/*Blockchain*/}
              <div className="mb-6">
                <label
                  htmlFor="item-blockchain"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_collection.blockchain")}
                </label>

                <div className="dropdown relative mb-4 cursor-pointer">
                  <div
                    className="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3.5 px-3 text-base dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white"
                    role="button"
                    id="item-blockchain"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="flex items-center">
                      <img
                        src={KLAYTN}
                        alt="eth"
                        className="mr-2 h-5 w-5 rounded-full"
                      />
                      Klaytn
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-4 w-4 fill-jacarta-500 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                    </svg>
                  </div>
                </div>
              </div>

              {/*Payment tokens*/}
              <div className="mb-6">
                <label
                  htmlFor="Payment-tokens"
                  className="mb-2 block font-display text-jacarta-700 dark:text-white"
                >
                  {t("create_collection.payment_token")}
                </label>

                <div className="dropdown relative mb-4 cursor-pointer">
                  <div
                    className="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3.5 px-3 text-base dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white"
                    role="button"
                    id="Payment-tokens"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="flex items-center">
                      <img
                        src={CORN}
                        alt="eth"
                        className="mr-2 h-5 w-5 rounded-full"
                      />
                      CORN
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-4 w-4 fill-jacarta-500 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                    </svg>
                  </div>
                </div>
              </div>

              <button
                disabled={!registrationIsEnabled}
                className="cursor-default rounded-full bg-accent py-3 px-8 text-center font-semibold text-white transition-all disabled:bg-[#B9A0FF]"
              >
                {t("create_collection.create")}
              </button>
            </div>
          </div>
        </form>
      </section>
      {isPageLoading ? (
        <Loading loading background="#DFDFDF80" loaderColor="#9E7CFF" />
      ) : null}
    </main>
  );
};

export { CollectionCreatePage };
