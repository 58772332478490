import React, { useEffect, useState } from "react";
import gradient from "../../img/gradient.jpg";
import gradient_dark from "../../img/gradient_dark.jpg";
import { TopCreatorMain } from "../../components/TopCreatorMain";
import { CreateAndSell } from "../../components/CreateAndSellMain";
import { Partners } from "../../components/Partners";
import { FeaturedCollection } from "../../components/FeaturedCollection/FeaturedCollection";
import { BgSwiper } from "../../components/BgSwiper";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DemoState from "../../stores/DemoState";
import { proxy, useSnapshot } from "valtio";
import axios from "axios";
import { GridNFT } from "../../components/GridNFT";
import gradient_light from "../../img/gradient_light.jpg";
import AppState from "../../AppState";
import apiClient from "../../apiClient";
import item_1 from "../../img/products/item_1.jpg";
import collection_2_1 from "../../img/collections/collection_2_1.jpg";
import collection_2_2 from "../../img/collections/collection_2_2.jpg";
import collection_2_3 from "../../img/collections/collection_2_3.jpg";
import collection_2_4 from "../../img/collections/collection_2_4.jpg";
import owner_9 from "../../img/avatars/owner_9.png";
import { CreateCollectionSortingMenu } from "../../components/CreateMenuLists/CreateCollectionSortingMenu";
import { RESOURCE_BASE_URL } from "../../Constant";
import { CollectionElement } from "../../components/CollectionElement";

const SearchPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let keyword = searchParams.get("keyword");
  const [collections, setCollections] = useState([]);
  const [collectionAssets, setCollectionAssets] = useState([]);

  const state = proxy(AppState);
  const snap = useSnapshot(state);

  useEffect(() => {
    (async () => {
      if(keyword) {
        keyword = decodeURIComponent(keyword)
      }
      const { data: exploreCollections } = await apiClient.get(
        `blockchain/search`,
        {
          params: { search: keyword },
        }
      );
      const { collections, collectionAssets } = exploreCollections;
      console.log(`collections = ${JSON.stringify(collections)}`);
      console.log(`collectionAssets = ${JSON.stringify(collectionAssets)}`);
      setCollections(collections);
      setCollectionAssets(collectionAssets);
    })();
  }, [keyword]);

  return (
    <main>
      <section className="relative py-12">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img src={gradient_light} alt="gradient" className="h-full w-full" />
        </picture>
        <div className="container">
          {/*          <h1 className="font-display text-jacarta-700 py-16 text-center text-4xl font-medium dark:text-white">
            Search
          </h1>*/}

          {/*<div className="mb-8 flex flex-wrap justify-between">
            <ul className="flex flex-wrap ">
              <li className="my-1 mr-2.5">
                <a
                    className="dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent bg-jacarta-100 font-display text-jacarta-700 flex h-9 items-center justify-center rounded-lg px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white"
                >Collection</a
                >
              </li>
              <li className="my-1 mr-2.5">
                <a
                    className="dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white"
                >
                  NFT
                </a>
              </li>
            </ul>
          </div>*/}

          <div className="container py-16">
            <h2 className="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white">
              Collections
            </h2>
          </div>

          <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-4">
            {collections &&
              collections.length > 0 &&
              collections.map((item) => {
                return (
                  <div className="w-26">
                    <CollectionElement item={item} />
                  </div>
                );
              })}
          </div>

          <div className="container py-16">
            <h2 className="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white">
              NFTs
            </h2>
          </div>

          <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
            {collectionAssets &&
              collectionAssets.map((item, index) => (
                <GridNFT
                  key={index}
                  item={item}
                  itemName={item.name}
                  price={item.price}
                  imageUrl={item.imageUrl}
                  id={item.id}
                  hash={item.hash}
                  favoriteFlag={item.isFavoriteYn === "Y"}
                  favoriteCount={item.favoriteCount}
                  saleStatus={item.saleStatus}
                />
              ))}
          </div>
        </div>
      </section>

      <section className="relative dark:bg-jacarta-800"></section>
    </main>
  );
};

export { SearchPage };
