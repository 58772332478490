import { persist } from "zustand/middleware";

const initialState = {};
let PersistentAppState = persist(
  (set, get) => ({
    userdata: {},
    balance: "0",
    setUserdata: (data) => {
      set({ userdata: { ...data } });
      // setTimeout(function () {
      //   set({ userdata: { ...data } });
      // }, 500);
    },
    reset: () => {
      set((state) => ({ userdata: {} }));
    },
    setBalance: (tokenBalance) => {
      set({ balance: tokenBalance });
    },
  }),
  {
    name: "persist-storage",
    getStorage: () => localStorage,
  }
);
export default PersistentAppState;
