import React, {useEffect, useState} from 'react';
import gradient from '../../img/gradient.jpg';
import gradient_dark from '../../img/gradient_dark.jpg';
import { TopCreatorMain } from '../../components/TopCreatorMain';
import { CreateAndSell } from '../../components/CreateAndSellMain';
import { Partners } from '../../components/Partners';
import { FeaturedCollection } from '../../components/FeaturedCollection/FeaturedCollection';
import { BgSwiper } from '../../components/BgSwiper';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import DemoState from "../../stores/DemoState";
import {proxy, useSnapshot} from "valtio";
import axios from 'axios';
import {GridNFT} from "../../components/GridNFT";
import item_5 from "../../img/products/item_5.jpg";
import item_4 from "../../img/products/item_4.jpg";
import item_7 from "../../img/products/item_7.jpg";
import item_6 from "../../img/products/item_6.jpg";
import item_8 from "../../img/products/item_8.jpg";
import item_9 from "../../img/products/item_9.jpg";
import item_10 from "../../img/products/item_10.jpg";
import item_11 from "../../img/products/item_11.gif";
import gradient_light from "../../img/gradient_light.jpg";
import {SwiperSlide} from "swiper/react";
import {SlideElement} from "../../components/BgSwiper/SlideElement";
import {useQuery} from "react-query";
import {UserNavigation} from "../../components/UserNavigation";
import {UserFilters} from "../../components/UserFilters";
import {Activity} from "../../components/Activity";
//import useMarketSalesListQuery from "../../hooks/query/useMarketSalesListQuery";
import AppState from "../../AppState";
import apiClient from "../../apiClient";
import item_1 from "../../img/products/item_1.jpg";
import collection_2_1 from "../../img/collections/collection_2_1.jpg";
import collection_2_2 from "../../img/collections/collection_2_2.jpg";
import collection_2_3 from "../../img/collections/collection_2_3.jpg";
import collection_2_4 from "../../img/collections/collection_2_4.jpg";
import owner_9 from "../../img/avatars/owner_9.png";
import {CreateCollectionSortingMenu} from "../../components/CreateMenuLists/CreateCollectionSortingMenu";
import {COLLECTION_CATEGORIES, getCategoriesIcon, RESOURCE_BASE_URL} from "../../Constant";
import {CollectionElement} from "../../components/CollectionElement";
import {useTranslation} from "react-i18next";

const CollectionListPage = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get("category");

  const [collectionSortingIsOpen, setCollectionSortingIsOpen] = useState(false);
  const [collectionSorting, setCollectionSorting] = useState('Recent');
  const [collections, setCollections] = useState([]);
  const [category, setCategory] = useState('');
  // const [sorting, setSorting] = useState('Recent');

  const state = proxy(AppState);
  const snap = useSnapshot(state);

  useEffect(() => {
    (async () => {
      // /market/sale/list
      console.log(`collections =`);
      if(categoryParam) {
        setCategory(categoryParam);
      }
      const {data: exploreCollections} = await apiClient.get(`blockchain/collectionList`, {
        params: {
          category: categoryParam ? (categoryParam === 'all' ? '' : categoryParam) : '',
          // ...(categoryParam ? {category: categoryParam} : category),
          sorting: collectionSorting
        }
      }, );
      const {collections} = exploreCollections;
      console.log(`collections = ${JSON.stringify(collections)}`);
      setCollections(collections);
    })();
  }, [category, collectionSorting, categoryParam]); // category, sorting

  const handleParentClick = event => {
    event.preventDefault();
        if (event.target === event.currentTarget) {
          console.log('parent clicked');
        } else {
          setCollectionSortingIsOpen(true)
       }
  };

  const activeClassname = "bg-accent font-display flex h-9 items-center justify-center rounded-lg px-4 text-sm font-semibold border-transparent text-white ";
  const inactiveClassname = "dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white" ;

  return (
    <main>
      <section className="relative py-12">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
              src={gradient_light}
              alt="gradient"
              className="h-full w-full"
          />
        </picture>
        <div className="container">
          <h1 className="font-display text-jacarta-700 py-16 text-center text-4xl font-medium dark:text-white">
            {t('explore.explore_collections')}
          </h1>

          <div className="mb-8 flex flex-wrap justify-between">
            <ul className="flex flex-wrap ">

              {
                COLLECTION_CATEGORIES.map((item, i) => {
                  return <li className="my-1 mr-2.5">

                    <Link
                        to={`/collections?category=${item}`}
                        // onClick={event => {
                        //   setCategory((item === 'all') ? '' : item);
                        // }}
                        className={(category === item || (category === '' && item === 'all')) ? activeClassname : inactiveClassname}
                    >
                      {getCategoriesIcon(i)}
                      {t(`category.${item}`)}</Link>
                  </li>
                })
              }
            </ul>
            <div className="dropdown relative my-1 cursor-pointer"
              onClick={handleParentClick}>
              <div
                  className="dark:bg-jacarta-700 dropdown-toggle border-jacarta-100 dark:border-jacarta-600 inline-flex w-48 items-center justify-between rounded-lg border bg-white py-2 px-3 text-sm dark:text-white"
                  role="button"
                  id="categoriesSort"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
              >
                <span className="font-display">{collectionSorting ? (collectionSorting === 'Recent' ? t('explore.sorting_recent') : t('explore.sorting_volume')) : t('explore.sorting_recent')}</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-500 h-4 w-4 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"/>
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"/>
                </svg>
              </div>
              {
                collectionSortingIsOpen && <CreateCollectionSortingMenu
                    setCollectionSortingIsOpen={setCollectionSortingIsOpen}
                    onClick={(id) => {
                      setCollectionSortingIsOpen(false);
                      setCollectionSorting(id);
                    }}
                />
              }

            </div>
          </div>

          <div className='text-center font-display text-3xl text-jacarta-700 dark:text-white'>
            {/*<h2 className='inline'>Top Creator over </h2>*/}
            <div className='dropdown inline cursor-pointer'>
            </div>
          </div>

          <div className='grid grid-cols-1 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-4'>
            {
              collections && collections.length > 0 &&
              collections.map((item) => {
              return (
                  <div className='w-26'>
                    <CollectionElement
                      item={item}/>
                  </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className='relative dark:bg-jacarta-800'>

      </section>
    </main>
  );
};

export { CollectionListPage };
