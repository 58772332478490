import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import owner_5 from "../../img/avatars/owner_5.png";
import { RESOURCE_BASE_URL } from "../../Constant";

const CommunityPostList = ({
  data,
  communityId,
  pageStatus,
  PageEndFlag,
  isPostList,
}) => {
  const navigate = useNavigate();

  return (
    <div className="mb-4 rounded-2.5xl border border-jacarta-100 bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-700">
      <div
        className="pointer"
        onClick={() => {
          const scrollTop = window.scrollY;

          //scroll position
          sessionStorage.setItem(
            "isCommunityBackScrollPos",
            JSON.stringify(scrollTop)
          );

          //page, filter info
          sessionStorage.setItem(
            "isCommunityBackPageInfo",
            JSON.stringify(pageStatus)
          );

          //page, end info
          sessionStorage.setItem(
            "isCommunityBackPageEndInfo",
            JSON.stringify(PageEndFlag)
          );

          //post list
          sessionStorage.setItem(
            "isCommunityPostList",
            JSON.stringify(isPostList)
          );
          navigate(`/CommunityPost/${communityId}/${data.id}`);
        }}
      >
        <div className="text-left">
          <div className="mb-2 flex flex-wrap items-center space-x-2 text-sm text-jacarta-400">
            <img
              src={
                data.profileImg ? RESOURCE_BASE_URL + data.profileImg : owner_5
              }
              alt="owner"
              className="h-6 w-6 rounded-full"
            />
            <span className="mr-12 text-2xs font-medium tracking-tight text-jacarta-700">
              {data.username ? data.username : "nouser-" + data.userid}
            </span>
            <span className="flex-wrap space-x-2 text-right text-sm text-jacarta-400">
              {data.createdAt}
            </span>
          </div>
          <div className="ml-12 flex-wrap text-left text-base font-medium tracking-tight text-jacarta-400">
            {data.title}
          </div>
          <div className="mr-8 flex-wrap text-right text-xs tracking-tight text-jacarta-400">
            <span>View: {data.view}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CommunityPostList };
