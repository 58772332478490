import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import a1a from '../../img/chains/a1a.png';
import CORN from '../../img/chains/CORN.png';
import {useTranslation} from "react-i18next";
const ModalBodyModify = ({balance, onChangePrice, item}) => {
    const { t, i18n } = useTranslation();
    const [price, setPrice] = useState('0.00');

    useEffect(() => {
        setPrice(item.price);
    },[]);

  return (
      <div className="modal-body p-6">
          <div className="mb-2 flex items-center justify-between">
              <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">Price</span>
          </div>

          <div
              className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border"
          >
              <div className="border-jacarta-100 bg-jacarta-50 flex flex-1 items-center self-stretch border-r px-2">
                <span>
                    <img
                        src={CORN}
                        width="20"
                        height="20"
                    />
                </span>
                  <span className="font-display text-jacarta-700 text-sm">CORN</span>
              </div>

              <input
                  type="text"
                  className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inset"
                  placeholder="Amount"
                  value={price}
                  onChange={ e => {
                      setPrice(e.target.value);
                      if(onChangePrice) {
                          onChangePrice(e);
                      }
                      }
                  }
              />

              <div className="bg-jacarta-50 border-jacarta-100 flex flex-1 justify-end self-stretch border-l">
                  <span className="self-center px-2 text-sm">$130.82</span>
              </div>
          </div>

          <div className="text-right">
              <span className="dark:text-jacarta-400 text-sm">Balance: {balance} CORN</span>
          </div>

          <div className="mt-4 flex items-center space-x-2">
              <input
                  type="checkbox"
                  id="sellNowTerms"
                  className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
              />
              <label htmlFor="modifyNowTerms" className="dark:text-jacarta-200 text-sm"
              >{t('phrase.check_agree_1')} <a href="#" className="text-accent">{t('phrase.check_agree_2')}</a></label
              >
          </div>
      </div>
  );
};

export { ModalBodyModify };
