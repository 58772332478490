import React from "react";
import { Modal } from "./Modal";
import { ModalBodyBuy } from "../ModalBodyBuy";
import { ethers } from "ethers";
import { saleAbi } from "../../blockchain/saleAbi";
import { erc20Abi } from "../../blockchain/erc20Abi";
import { cornAbi } from "../../blockchain/cornAbi";
import { constants } from "../../blockchain/constants";
import { currencyById } from "../../blockchain/currencies";
import apiClient from "../../apiClient";
const EscrowBuyModal = ({
  setModalIsOpen,
  item,
  styledWrapper,
  setPageLoading,
  setForceRefresh,
  refreshPage,
  balance,
}) => {
  return (
    <Modal
      setModalIsOpen={setModalIsOpen}
      title="Escrow Buy"
      btnTitle="Escrow buy now"
      childrenBody={<ModalBodyBuy item={item} balance={balance} />}
      styledWrapper={styledWrapper}
      onClick={async function () {
        try {
          const checkbox = document.getElementById("buyNowTerms");
          if (checkbox && !checkbox["checked"]) {
            alert("서비스 약관에 동의해주세요.");
            return;
          }
          // we must not render on sale
          if (item.saleStatus !== "ON_SALE") {
            alert("판매중이 아닙니다.");
            return;
          }

          console.log("item");

          const ethereum = window["ethereum"];
          if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const signerAddress = await signer.getAddress();

            const priceBn = ethers.utils.parseEther(item.price);
            const currencyAddress = currencyById(item.currencyId);
            const erc20Contract = new ethers.Contract(
              currencyAddress,
              erc20Abi,
              signer
            );

            // transfer to admin

            const erc20Balance = await erc20Contract.balanceOf(signerAddress);
            const balanceBn = ethers.BigNumber.from(erc20Balance);
            console.log("test2", balanceBn.toString(), priceBn.toString());
            if (balanceBn.lt(priceBn)) {
              alert("insufficient balance");
              return;
            }
            setPageLoading(true);
            const tx = await erc20Contract.transfer(
              constants.ADMIN_ADDRESS,
              priceBn
            );
            const receipt = await tx.wait();
            const hash = receipt.transactionHash;

            const response = await apiClient.post("market/sale/escrowStart", {
              saleInfoId: item.saleInfoId,
              hash,
            });
            if (response.data.result === "ok") {
              alert(
                "에스크로 구매에 성공했습니다. 구매확인이 끝날때까지 기다려주세요."
              );
            } else {
              alert("실패했습니다.");
            }
            refreshPage();
            setForceRefresh((prev) => !prev);
            setModalIsOpen(false);
            setPageLoading(false);
          }
        } catch (e) {
          alert(`mobile env debugging, error = ${e}`);
        }
      }}
      onClickClose={function () {}}
    />
  );
};

export { EscrowBuyModal };
