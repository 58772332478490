import axios from "axios";

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log(`process.env.REACT_APP_ENVIRONMENT = ${process.env.REACT_APP_ENVIRONMENT}`);
console.log(`process.env.REACT_APP_API_BASE_URL = ${REACT_APP_API_BASE_URL}`);

const apiClient = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },
});

// Add a request interceptor
apiClient.interceptors.request.use(
  async function (config) {
    const accessToken = localStorage.getItem(`accessToken`);
    config.headers = { Authorization: `Bearer ${accessToken}` };
    console.log(`accessToken = ${accessToken}`);
    // headers: { Authorization: `Bearer ${await AsyncStorage.getItem('ACCESS_TOKEN')}` }
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const { statusCode } = response;
    if (statusCode === 401) {
      localStorage.setItem(`accessToken`, "");
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default apiClient;
