import React from 'react';
import { Link } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useTranslation} from "react-i18next";
const Details = ({contractAddress, tokenId}) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      // className='tab-pane fade'
      id='details'
      role='tabpanel'
      aria-labelledby='details-tab'>
      <div
        className='rounded-t-2lg rounded-b-2lg rounded-tl-none border border-jacarta-100 bg-white p-6 dark:border-jacarta-600
			dark:bg-jacarta-700 md:p-10'>
        <div className='mb-2 flex items-center'>
          <span className='mr-2 min-w-[9rem] dark:text-jacarta-300'>{t('common.contract_address')}:</span>
            <CopyToClipboard
                text={contractAddress}
                onCopy={() => alert(`Copied!`)}>
              <div className='text-accent'>
                {contractAddress}
              </div>
            </CopyToClipboard>
        </div>
        <div className='mb-2 flex items-center'>
          <span className='mr-2 min-w-[9rem] dark:text-jacarta-300'>{t('common.token_id')}:</span>
          <span
            className='js-copy-clipboard cursor-pointer select-none text-jacarta-700 dark:text-white'
            data-tippy-content='Copy'>
            {tokenId}
          </span>
        </div>
        <div className='mb-2 flex items-center'>
          <span className='mr-2 min-w-[9rem] dark:text-jacarta-300'>{t('common.token_standard')}:</span>
          <span className='text-jacarta-700 dark:text-white'>ERC-721</span>
        </div>
        <div className='flex items-center'>
          <span className='mr-2 min-w-[9rem] dark:text-jacarta-300'>{t('common.blockchain')}:</span>
          <span className='text-jacarta-700 dark:text-white'>KLAYTN</span>
        </div>
      </div>
    </div>
  );
};

export { Details };
